<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div class="">
      <div 
        style="z-index: 99999; left: 45%;"
        class="ez-modal-height ez-modal-top inl-55 transition duration-150 ease-in-out absolute right-0 left-0 rounded-lg">
        <form
          class="h-100-p flex"
          action="">
          <div class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            <div class="text-gray-800 f-20 font-bold tracking-normal leading-tight mb-4">
              <span v-if="!currentProductId">{{ t('actions.createProduct') }}</span>
              <span v-else>{{ t('actions.updateProduct') }}</span>
            </div>
            <button
              @click.prevent="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <div class="pr-3 justify-center h-100-p inl-100 flex flex-col text-left relative">

              <div
                style="height: 70%; overflow-y: scroll;" 
                class="scroll-styling margin-bt-15">

                <div class="flex flex-row flex-wrap justify-between">
                  
                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.productName') }} <b>*</b></label>
                    </div>
                    <input
                      v-model="formData.name"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.enterProductName')"
                    />
                  </div>
                  
                </div>

                <div class="font-semibold f-17 margin-bt-15">
                  {{ t('navigation.factoringConditions') }}:
                </div>

                <div class="flex flex-row flex-wrap justify-between">
              
                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.maxDays') }} <b>*</b></label>
                    </div>
                    <input
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      v-model="formData.max_day_amount"
                      @input="() => {
                        handlePositiveInteger('max_day_amount');
                      }"
                      name="max_day_amount"
                      :placeholder="t('form.maxDaysAmount')"
                    />
                  </div>

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.firstTranchePercent') }} <b>*</b></label>
                    </div>
                    <input
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      v-model="formData.first_tranche"
                      name="first_tranche"
                      @input="() => {
                        handlePercentValue('first_tranche');
                        handleTranches();
                      }"
                      :placeholder="t('form.tranchePercent')"
                    />
                  </div>
                
                  <div 
                    v-if="showTranches" class="inl-100 margin-bt-25">
                    <div class="font-semibold f-17 margin-bt-15">
                      {{ t('form.stagesOfTranches') }}:
                    </div>
                    <TranchesList 
                      updateKey="tranchess"
                      :prevData="prevData.tranchess"
                      :updateData="updateDataComission"
                      :transcheVal="formData.first_tranche" />
                  </div>
                </div>

                <div class="font-semibold f-17 margin-bt-15">
                  {{ t('form.comissionFeeTaxFinance') }}:
                </div>

                <div class="flex flex-row flex-wrap justify-between">
                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.supplierRate') }} <b>*</b></label>
                    </div>
                    
                    <div class="inl-100 flex flex-col">
                      <ComissioFields
                        updateKey="сomissoin_supplier"
                        :updateData="updateDataComission"
                        :prevData="prevData.сomissoin_supplier"
                      />
                    </div>

                    <div class="margin-t-20 inl-80">
                      <div class="flex flex-col inl-100">
                        <label
                          class="text-gray-800 text-sm font-bold leading-tight margin-bt-5 tracking-normal"
                          >{{ t('form.supplierComissionType') }} <b>*</b></label>
                      </div>
                      <select
                        v-model="formData.supplier_comission_rule"
                        class="rounded bg-gray-50 border pr-5 bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                      >
                        <option disabled :value="-1">{{ t('form.selectComissionType') }}</option>
                        <option selected :value="0">{{ t('form.holdOutside') }}</option>
                        <option :value="1">{{ t('form.holdInside') }}</option>
                      </select>
                    </div>

                  </div>

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight margin-bt-5 tracking-normal"
                        >{{ t('form.buyersRate') }} <b>*</b></label>
                    </div>

                    <div class="inl-100 flex flex-col">
                      <ComissioFields
                        updateKey="сomissoin_buyer"
                        :updateData="updateDataComission"
                        :prevData="prevData.сomissoin_buyer"
                        />
                    </div>

                    <div class="margin-t-20 inl-80">
                      <div class="flex flex-col inl-100 margin-bt-5">
                        <label
                          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                          >{{ t('form.buyerComissionType') }} <b>*</b></label>
                      </div>
                      <select
                        v-model="formData.buyer_comission_rule"
                        class="rounded bg-gray-50 border pr-5 bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                      >
                        <option disabled :value="-1">{{ t('form.selectComissionType') }}</option>
                        <option :value="0">{{ t('form.holdOutside') }}</option>
                        <option selected :value="1">{{ t('form.holdInside') }}</option>
                      </select>
                    </div>

                  </div>

                </div>

                <div class="font-semibold f-17 margin-t-15 margin-bt-15">
                  {{ t('form.amountOfDailyFeeSurchage') }}:
                </div>

                <div class="flex flex-row flex-wrap justify-between">
                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.supplierRate') }}</label>
                    </div>
                    
                    <div class="inl-100 flex flex-col margin-t-10">
                      <input
                        style="width: 50px"
                        v-model="formData.day_comission_supplier"
                        @input="() => {
                          handlePercentValue('day_comission_supplier');
                        }"
                        class="text-center text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-8 flex items-center text-sm border-gray-300 rounded border"
                      />
                    </div>
                  </div>

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.buyersRate') }}</label>
                    </div>

                    <div class="inl-100 flex flex-col margin-t-10">
                      <input
                        style="width: 50px"
                        v-model="formData.day_comission_buyer"
                        @input="() => {
                          handlePercentValue('day_comission_buyer');
                        }"
                        class="text-center text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-8 flex items-center text-sm border-gray-300 rounded border"
                      />
                    </div>
                  </div>
                </div>

                <div class="font-semibold f-17 margin-t-10 margin-bt-15">
                  {{ t('form.contractTemplates') }}:
                </div>

                <div class="flex flex-row flex-wrap justify-between inl-100 mb-5">
                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100 margin-bt-10">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.debtorAgreement') }}:</label>
                    </div>
                    <FileInput
                      :prevFile="formData.supplier_agreement"
                      nameKey="supplier_agreement"
                      :setData="onFileChanged"
                      addedCls="px-2 py-1 block w-full margin-bt-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      :name="'supplier_agreement'"
                    />
                  </div>

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100 margin-bt-10">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.creditorAgreement') }}:</label>
                    </div>
                    <FileInput
                      :prevFile="formData.buyer_agreement"
                      nameKey="buyer_agreement"
                      :setData="onFileChanged" 
                      addedCls="px-2 py-1 block w-full margin-bt-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      :name="'buyer_agreement'"
                    />
                  </div>

                </div>
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  v-if="!isDataUpdate"
                  @click.prevent="saveData"
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                >
                  {{ t('actions.save') }}
                </button>
                <button
                  v-else
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                >
                  <svg aria-hidden="true" role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                  </svg>
                  {{ t('actions.updating') }}
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { API_FACTOR, HEADERS_TOKEN_JSON, HEADERS_TOKEN_JSON_FILE } from '@/const/uri';
import { fromErrorToHtml } from "@/utils/erros.js";
import { getObjDataNotNull } from '@/utils/base';
import ComissioFields from '@/components/Forms/ComissioFields.vue';
import { checkValue0100, isPositiveInteger } from '@/utils/base';
import FileInput from '@/components/Forms/FileInput.vue';
import TranchesList from '@/components/Forms/TranchesList.vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
  ],
  components: {
    ComissioFields, FileInput,
    TranchesList,
  },
  data() {
    return {
      uuid: null,
      isDataUpdate: false,
      showTranches: false,
      prevData: {
        сomissoin_supplier: [],
        сomissoin_buyer: [],
        tranchess: [],
      },
      formData: {
        supplier_comission_rule: 0,
        buyer_comission_rule: 1,
        name: null,
        pay_rate: null,
        max_day_amount: null,
        pay_rate_start: false,
        first_tranche: null,
        day_comission_supplier: null,
        day_comission_buyer: null,
        supplier_agreement: null,
        buyer_agreement: null,
        сomissoin_supplier: [],
        сomissoin_buyer: [],
        tranchess: [],
      },
      modalData: {
        name: null,
      }, 
    };
  },
  beforeMount() {
    if(this.$props.currentProductId) {
      this.getData();
    }
  },
  methods: {
    updateDataComission(key, data,) {
      this.formData[key] = [...data];
    },  
    fromErrorToHtml, getObjDataNotNull,
    checkValue0100, isPositiveInteger,
    onFileChanged(key, file, isDelete=false,){
      if(!isDelete)
        this.formData[key] = file;
      else
        this.formData[key] = null;
    },
    handleTranches(){
      this.showTranches = this.formData.first_tranche < 100;
    }, 
    handlePositiveInteger(key) {
      const value = this.formData[key];
      const isSuccess= this.isPositiveInteger(value);
      if(isSuccess) return value;
      this.formData[key] = null;
      return null;
    },
    handlePercentValue(key) {
      const value  = this.formData[key];
      const isSuccess= this.checkValue0100(value);
      if(isSuccess) return value;
      this.formData[key] = null;
      return null;
    },
    setData(data) {
      const comissions = data.comissions;
      const tranchess = data.tranchess;
      delete data['comissions'];
      delete data['tranchess'];
      this.formData.сomissoin_supplier = [];
      this.formData.сomissoin_buyer = [];
      comissions.forEach((el) => {
        if(el.commission_type.key == 0) {
          this.formData.сomissoin_supplier.push({ 
            ...el,
            comission: el.comission_rate,
          });
        }
        else {
          this.formData.сomissoin_buyer.push({ 
            ...el,
            comission: el.comission_rate,
          });
        }
      });
      
      this.prevData.сomissoin_supplier = [...this.formData.сomissoin_supplier];
      this.prevData.сomissoin_buyer = [...this.formData.сomissoin_buyer];
      
      this.formData.tranchess = [...tranchess];
      if(tranchess.length > 0) {
        this.showTranches = true; 
        this.prevData.tranchess = [...tranchess];
      }
      this.formData = {
        ...this.formData,
        ...data,
        supplier_comission_rule: data.supplier_comission_rule.key,
        buyer_comission_rule: data.buyer_comission_rule.key,
      };
    },
    async getData() {
      const url = API_FACTOR.products.list + this.$props.currentProductId + '/';
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        url, params: {
          segmentUUID: this.$props.segmentUUID,
        },
      }).then((e) => {
        const data = e.data.data;
        this.setData(data);
      }).catch((e) => {
        console.log(e);
        this.$props.toggleModal();
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [],
            ), 
          });
        }
      });
    }, 
    async saveData() {
      const method = this.$props.currentProductId ? "PUT" : "POST";
      const url = this.$props.currentProductId ? 
        API_FACTOR.products.list + this.$props.currentProductId + '/'  
        : API_FACTOR.products.list;
      await axios({
        method, url,
        headers: HEADERS_TOKEN_JSON_FILE(),
        data: {
          ...this.formData,
          сomissoin_supplier: JSON.stringify(this.formData.сomissoin_supplier),
          сomissoin_buyer: JSON.stringify(this.formData.сomissoin_buyer),
          tranchess: JSON.stringify(this.formData.tranchess),
        },
        params: {
          segmentUUID: this.$props.segmentUUID,
        },
      }).then((e) => {
        this.isDataUpdate = false;
        if(!this.$props.currentProductId) {
          this.$props.updateData(e.data.data);
        } else {
          this.$props.changeData(e.data.data,);
        }
        this.toggleModal();
      }).catch((e) => {
        console.log(e);
        const errorData = e.response.data;
        this.isDataUpdate = false;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [],
            ), 
          });
        }
      });
    }
  },
  props: {
    currentProductId: {
      default: null,
    },
    segmentUUID: {
      default: null,
    },
    toggleModal: {},
    changeData: {
      default: () => {},
    },
    updateData: {
      default: () => {},
    },
  },
};
</script>
