<template>
  <div class="cl-wrapper overflow-hidden">
    
    <div class="cl-header px-2">
      <div 
        ref="headerWrapper"
        class="wrapper flex justify-between items-center">
        <div class="flex justify-start item s-center">
        
          <div class="flex justify-start items-center margin-r-45" >
            <div class="logo-main flex items-center justfiy-center ">
              <a href="/"
                ><img
                  style="height: 35px;"
                  class="logo-standard"
                  src="/assets/images/logo_white.png"
                  alt=""
              /></a>
            </div>
            <div class="margin-l-15 logo-text f-25 font-bold margin-r-15">
              EZ Factor
            </div>
          </div>
        </div>

        <div class="flex items-center justify-center">
          <select
            @change="setLng"
            style="width: 65px; padding-left: 8px !important"
            class="f-14 rounded-lg bg-gray-50 border bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 pl-2"
          >
            <option :selected="acceptLanguage == 'ru'" value="ru">RU</option>
            <option :selected="acceptLanguage == 'en'" value="en">EN</option>
          </select>
        </div>
      </div>
   
    </div>

    <div class="cl-content flex items-center justify-center relative">
      
      <div v-if="!isSigned">
        <div 
          :style="{
            opacity: isLoaded ? '1' : '0',
          }"
          :class="[
            isLoaded ? 'relative z-10' : '',
          ]"
          class="qr-block transition-all rounded">
          
          <div class="title f-23 font-bold text-center margin-bt-35">
            {{ t('sign.signContractWith') }} {{ company }} <br>
            <span v-if="clinentType == 1">{{ t('sign.buyerContract')}}</span>
            <span v-else>{{ t('sign.supplierContract')}}</span>
          </div>
          
          <div 
            class="pointer"
            @click="openLink"
          >
            <div 
              ref="qrCode"
              :style="{
                width: 400,
                height: 400,
              }"
              id="canvas"></div>
          </div>

          <div class="margin-t-15 font-bold italic f-18 text-center">
            {{ t('actions.dontClosePage') }}
          </div>

        </div>
        <div 
        :style="{
          opacity: isLoaded ? '0' : '1',
        }"
        :class="[
          !isLoaded ? 'relative z-10' : '',
        ]"
        class="qr-modal  transition-all">
          <img 
            class="center-block"
            src="/assets/images/loading/loader-1.svg" alt="">
        </div>
      </div>
      <div v-else class="qr-block transition-all rounded">
        <div 
          class="font-bold f-23 text-center">{{ t('docx.wasSigned')}} </div>
        <div 
          @click="() => {
            downloadFile();
          }"
          class="pointer margin-t-15 font-bold f-23 text-blue-600 text-center">{{ t('actions.download')}}</div>
      </div>

    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapActions, mapState } from "vuex";
import QRCodeStyling from 'qr-code-styling';
import { FRONTEND_HOST } from '@/const/uri.dev';
import { getCurrentLng } from '@/utils/base';
import { API_FACTOR, HEADERS_JSON, HEADERS_TOKEN_JSON_FILE } from '@/const/uri';
import axios from 'axios';
import { formShowError } from '@/utils/erros';

export default {
  inject: [
    'openErrorModal',
    'openSubmitModal',
    'closeSubmitModal',
  ],
  setup() {
    const { t } = useI18n();
    return { t };
  },
  computed: {
    ...mapState(["acceptLanguage"]),
  },
  beforeMount() {
    this.setMaxWidth();
    this.signUUID = this.$route.params.uuid;
  },
  mounted() {
    this.getQrData();
    this.$refs.headerWrapper.style.width = this.maxWidth; 
  },
  data() {
    return {
      dFileName: null,
      isSigned: false,
      blobData: null,
      fileName: null,
      signUUID: '',
      isLoaded: false,
      maxWidth: 0,
      isMobile: false,
      company: 'Ez Factor',
      clinentType: null,
      isEror: false,
      isLoading: true,
      link: null,
      qrcodeTemplate: {
        width: 400,
        height: 400,
        type: "svg",
        data: "https://egovbusiness.page.link/?link=https://sigex.kz/api/egovQr/egov/iosgeGmKDyYKwYSg?mgovSign&apn=kz.mobile.mgov.business&isi=1597880144&ibi=kz.egov.business",
        image: `${FRONTEND_HOST}/assets/images/logo_white.png`,
        dotsOptions: {
            color: "#03AF94",
            type: "rounded"
        },
        "cornersDotOptions": {
            "type": "",
            "color": "#000000"
        },
        "cornersSquareOptions": {
            "type": "",
            "color": "#000000"
        },
        backgroundOptions: {
            color: "transparent",
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 20
        },
      },
    };
  },
  methods: {
    ...mapActions(["changeLanguage"]),
    getCurrentLng,
    setMaxWidth() {
      this.isMobile = window.innerWidth < 950;
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.maxWidth = width < height ? width : height; 
      if(this.isMobile) {
        const width = this.maxWidth - 40;
        this.maxWidth = width;
        this.qrcodeTemplate.width = width; 
        this.qrcodeTemplate.height = width;
        this.$refs.qrCode.style.width = width
        this.$refs.qrCode.style.height = width
      }
      if(this.$refs.headerWrapper) {
        this.$refs.headerWrapper.style.width = this.maxWidth; 
      }
    },
    setLng(e) {
      const value = e.target.value;
      this.changeLanguage(value);
      this.$i18n.locale = value;
    },
    openLink() {
      window.open(this.link, '_blank');
    },
    async generateQr(link) {
      const qrcodeTemplate = {
        ...this.qrcodeTemplate,
        data: link,
      };
      const qrCode = new QRCodeStyling(qrcodeTemplate);
      qrCode.append(this.$refs.qrCode);
      await this.runSigex();
    },
    async getQrData() {
      await axios({
        method: "GET",
        headers: HEADERS_JSON(),
        url: API_FACTOR.deals.clientSign + this.signUUID + "/",
      }).then((e) => {
        this.isLoaded = true;
        const data = e.data.data;
        this.link = data.link;
        this.clinentType = data.client_type.key;
        this.generateQr(this.link);
      }).catch((e) => {
        console.log(this.openErrorModal);
        formShowError.bind(this)(e);
      });
    },
    async getBlobFileName(response) {
      let fileName = "";
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) {
              fileName = fileNameMatch[1];
          }
      }
      return fileName;
    },
    downloadFile(filename) {
      const name = filename || this.dFileName;
      try {
        const blob = this.blobData;
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', `${name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading the file', error);
      }
    },
    async runSigex() {

      try {
        const response = await axios({
          url: API_FACTOR.deals.clientSign + this.signUUID + "/run-sigex/",
          method: "POST",
          headers: HEADERS_TOKEN_JSON_FILE(),
          responseType: 'blob',
        });
        const blobData = response.data;
        const fileName = await this.getBlobFileName(response);
        this.blobData = blobData;
        this.dFileName = fileName;
        this.isSigned= true;
        const blobUrl = URL.createObjectURL(blobData);
        window.open(blobUrl, '_blank');
      } catch (e) {
        console.log(e);
      }
    },   

  },
  props: {},
};
</script>