<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div class="">
      <div 
        style="z-index: 99999; left: 60%;"
        class="ez-modal-height ez-modal-top inl-40 transition duration-150 ease-in-out absolute right-0 left-0 rounded-lg">
        <form
          class="h-100-p flex"
          @submit.prevent="saveData"
          action="">
          <div 
            style="overflow-y: scroll;"
            class="h-100-p inl-100 scroll-styling flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            <div class="text-gray-800 f-20 font-bold tracking-normal leading-tight mb-4">
              {{ t('actions.createOrder') }}
            </div>
            <button
              @click="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <div class="pr-3 justify-center h-100-p inl-100 flex flex-col text-left relative">
              <div class="margin-bt-15">
                <div class="flex flex-row flex-wrap justify-between">
                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.client') }} <b>*</b></label>
                    </div>
                    <model-select 
                        class="mt-1"
                        v-if="companies && companies.length > 0"
                        :options="getSelectOptionsBase(
                          companies,
                          'uuid',
                          'name_short',
                          true,
                        )"
                        v-model="formData.company"
                        :placeholder="t('form.selectCompany')">
                    </model-select>
                  </div>

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.limitDateStart') }} <b>*</b></label>
                    </div>
                    <Datepicker
                      class="mt-1"
                      v-model="formData.limit_date_start_at" 
                      :month-change-on-scroll="false"
                      locale='ru'
                      :time-picker="false"
                      :enable-time-picker="false"
                      @cleared="clearedDates"
                      :format='dateFormatPicker'
                      :select-text="t('actions.choose')"
                      :cancel-text="t('actions.close')"
                    >
                    </Datepicker>
                  </div>

                </div>

                <div class="flex flex-row flex-wrap justify-between">
                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('company.totalLimit') }} <b>*</b></label>
                    </div>
                    <input
                      v-model="formData.total_limit"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      @input="() => {
                        handlePositiveInteger('total_limit');
                        beautifyIntegerData('total_limit');
                      }"
                      :placeholder="t('form.enterTotalLimit')"
                    />
                  </div>

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.dateOfOrderCreated') }} <b>*</b></label>
                    </div>
                    <Datepicker
                      class="mt-1"
                      v-model="formData.date_created" 
                      :month-change-on-scroll="false"
                      locale='ru' 
                      @cleared="clearedDates"
                      :time-picker="false"
                      :enable-time-picker="false"
                      :format='dateFormatPicker'
                      :select-text="t('form.choose')"
                      :cancel-text="t('form.close')"
                    >
                    </Datepicker>
                  </div>
                  
                </div>

                <div class="flex flex-row flex-wrap justify-between">

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('company.dealLimit') }} <b>*</b></label>
                    </div>
                    <input
                      v-model="formData.transaction_limit"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      @input="() => {
                        handlePositiveInteger('transaction_limit');
                        beautifyIntegerData('transaction_limit');
                      }"
                      :placeholder="t('form.enterDealLimit')"
                    />
                  </div>

                  <div class="inl-45 margin-bt-15">
                    <div class="flex flex-col inl-100">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.clarification') }} <b>*</b></label>
                    </div>
                    <input
                      v-model="formData.justification"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.enterClarification')"
                    />
                  </div>

                </div>
                
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">
                  {{ t('actions.save') }}
                </button>
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
import { fromErrorToHtml } from "@/utils/erros.js";
import { ModelSelect } from 'vue-search-select';
import { getSelectOptionsBase, formatDateTime, dateFormatPicker, isPositiveInteger, beautifyInteger } from '@/utils/base.js';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
    'toggleSmModal',
  ],
  components: {
    Datepicker: VueDatePicker,
    ModelSelect,
  }, 
  data() {
    return {
      companies: [],
      // formData: { 
      //   company: "66448879-f735-11ee-a575-f5580d690023",
      //   justification: "Qqweqwe",
      //   total_limit: null,
      //   transaction_limit: null,
      //   date_created: "2024-04-22T10:01:00.000Z",
      //   limit_date_start_at: "2024-04-22T10:01:00.000Z"
      // },
      formData: {
        company: null,
        justification: null,
        total_limit: null,
        transaction_limit: null,
        date_created: null,
        limit_date_start_at: null,
      }, 
    };
  },
  beforeMount() {
    this.getCompanies();
  },
  methods: {
    isPositiveInteger, beautifyInteger,
    dateFormatPicker, formatDateTime,
    getSelectOptionsBase, fromErrorToHtml,
    clearedDates() {},
    beautifyIntegerData(key) {
      if(typeof this.formData[key] != 'undefined' && this.formData[key] != null )
      this.formData[key] = beautifyInteger((this.formData[key]+'').replace(/\s/g, ''));
    },
    handlePositiveInteger(key) {
      const totalLimit = parseFloat((this.formData.total_limit+'').replace(/\s/g, ''));
      const transactionLimit = parseFloat((this.formData.transaction_limit+'').replace(/\s/g, ''));
      const value = parseFloat((this.formData[key]+'').replace(/\s/g, ''));
      const isSuccess= this.isPositiveInteger(value);
      if(isSuccess) {
        if(totalLimit < transactionLimit) {
          this.formData[key] = null;
          this.toggleSmModal({
            description: this.t('modalMessages.limitTrasancion'),
            isAlert: true,
          });
        }
        return value;
      }
      this.formData[key] = null;
      return null;
    },
    async getData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.segment.base + this.limitUUID + '/',
      }).then((e) => {
        this.modalData.name = e.data.data.name;
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                {
                  field_name: 'name', 
                  label: this.t('form.title'),
                },
              ],
            ), 
          });
        }
      });
    }, 
    async getCompanies() {
      await axios({
        method: 'GET',
        url: API_FACTOR.limit.order + 'companies/',
        headers: HEADERS_TOKEN_JSON(),
      }).then((e) => {
        this.companies = e.data.data;
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                {
                  field_name: 'name', 
                  label: this.t('form.title'),
                },
              ],
            ), 
          });
        }
      });
    },
    async saveData() {
      await axios({
        method: "POST",
        url: API_FACTOR.limit.order,
        headers: HEADERS_TOKEN_JSON(),
        data: {
          ...this.formData,
          total_limit: (this.formData.total_limit+'').replace(/\s/g, ''),
          transaction_limit: (this.formData.transaction_limit+'').replace(/\s/g, ''),
        },
      }).then((e) => {
        const data = e.data.data;
        this.$props.updateData(data);
        this.$props.toggleModal();
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                {
                  field_name: 'name', 
                  label: this.t('form.title'),
                },
              ],
            ), 
          });
        }
      });
    }
  },
  props: {
    limitUUID: {
      default: null,
    }, 
    toggleModal: {},
    changeData: {
      default: () => {},
    },
    updateData: {
      default: () => {},
    }
  },
};
</script>