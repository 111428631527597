<template>
  <div 
    :class="[
      !isNavOpen ? 'ez-modal-width-full' : 'ez-modal-width', 
    ]"
    class="ez-modal-height ez-modal-top limit-order-list shadow-sm px-2 py-3">
    <button
      @click.prevent="toggleModal"
      class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-x"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>

    <div v-if="isLoad" class="inl-100 h-100-p flex justify-center items-center">
      <img
        class="loading-icon"
        src="/assets/images/loading/loader-1.svg"
        alt=""
      />
    </div>
    <div class="sm-limit-table" v-else>
      <h2 class="mb-2 text-lg font-semibold text-gray-900">
        {{ t('company.title') }}
        <router-link
          :to="{
            name: 'ClientCompanyId',
            params: {
              companyUUID: company.uuid,
            },
          }"
          v-slot="{ href, navigate }"
        >
          <span :href="href" @click="navigate" class="font-bold text-blue-600">
            {{ company.name_short.ru }}
          </span> </router-link
        >:
      </h2>

      <div class="inl-100 relative overflow-x-auto shadow-md sm:rounded-lg">
        <table
          class="mb-0 table table-striped rounded-xl"
        >
          <thead
            class="text-xs text-white uppercase">
            <tr>
              <th class="inl-20 px-6 py-3">{{ t('actions.actions') }}</th>
              <th class="inl-20 px-6 py-3">{{ t('actions.updated') }}</th>
              <th class="inl-10 px-6 py-3">{{ t('company.totalLimit') }}</th>
              <th class="inl-10 px-6 py-3">{{ t('company.dealLimit') }}</th>
              <th class="inl-10 px-6 py-3">{{ t('form.status') }}</th>
              <th class="inl-15 px-6 py-3">{{ t('form.changedDate') }}</th>
            </tr>
          </thead>
        </table>
        
        <table
          class="mb-0 table table-striped rounded-xl modal-table scroll-styling"
        >
          <tbody>
            <tr 
              v-for="(el, ind) in limits" :key="ind"
              class="border-b ">
              <td class="inl-20 px-6 py-4">{{ el.message }}</td>
              <td class="inl-20 px-6 py-4">
                <span v-if="el.changed_by">{{ el.changed_by.fio }}</span>
                <span v-else>{{ t('actions.systemChanged') }}</span>
              </td>
              <td class="inl-10 px-6 py-4">
                <span v-if="!el.total_limit">{{  0}}</span>
                <span v-else>{{ beautifyInteger(el.total_limit) }}</span>
              </td>
              <td class="inl-10 px-6 py-4">{{ beautifyInteger(el.transaction_limit) }}</td>
              <td class="inl-10 px-6 py-4">{{ el.status.val }}</td>
              <td class="inl-15 px-6 py-4">{{ formatDateTime(el.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  
    <TableNav
      v-if="false"
      :currentPage="currentPage"
      :navItems="navItems"
      :allCount="allCount"
      style="width: 330px;"
    />

  </div>
</template>

<script>
import axios from "axios";
import { formShowError } from "@/utils/erros";
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import { formatDateTime, beautifyInteger } from "@/utils/base";
import TableNav from '@/components/Admin/TableNav.vue';
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  components: {
    TableNav,
  },
  computed: {
    ...mapState(['isNavOpen',]),
  },
  data() {
    return {
      isLoad: true,
      limits: [],
      company: {
        bin: null,
        uuid: null,
        name_short: {
          en: "",
          kz: "",
          ru: "",
        },
      },
      allCount: 1,
      currentPage: 1,
      navItems: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDateTime, beautifyInteger,
    async loadData() {
      this.isLoad = false;
      await axios({
        method: "GET",
        params: {
          is_small: "1",
        },
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.limit.base + this.$props.limitUUID + "/history/",
      })
        .then((e) => {
          const data = e.data.data;
          this.company = data.company;
          this.limits = data.results;
        })
        .catch(formShowError.bind(this));
    },
  },
  props: {
    limitUUID: {
      default: null,
    },
    toggleModal: {
      default: null,
    },
  },
};
</script>