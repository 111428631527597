<template>
  <div class="relative">
    <ErrorModalCenter
      v-if="errorModalCenterActive"
      :errorModalData="errorModalData"
      :closeErrorModal="closeErrorModal"
    />
    <SubmitModalCenter
      v-if="submitModalCenterActive"
      :submitModalData="submitModalData"
      :closeSubmitModal="closeSubmitModal"
      :modalSubmiCallback="modalSubmiCallback"
    />
      <!-- :modalData="" -->
    <SmallModal 
      v-if="showSmModal"
      :modalData="smModalData"
      :closeModal="closeSmModal"
      :isAlert="smModalData.isAlert"
    />
    <div 
    :class="[
      !isNavOpen ? 'closed-sidebar' : '',
    ]"
    class="">
      <div class="">
        <div class="">
          <router-view></router-view> 
        </div>
      </div>
    </div>    
  </div>
</template>

<script>

import { API_AUTH, HEADERS_TOKEN_JSON } from "@/const/uri";
import axios from "axios";
import { mapMutations, mapState } from 'vuex';
import ErrorModalCenter from "@/components/Modals/ErrorModalCenter.vue";
import SubmitModalCenter from "@/components/Modals/SubmitModalCenter.vue";
import SmallModal from "@/components/Modals/SmallModal.vue";

export default {
  components: {
    ErrorModalCenter, SubmitModalCenter,
    SmallModal,
  },
  data() {
    return {
      showSmModal: false,
      smModalData: {}, 
      errorModalCenterActive: false,
      submitModalCenterActive: false,
      isDataLoad: false,
      modalSubmiCallback: null,
      submitModalData: {
        title: null,
        description: null,
      }, 
      errorModalData: {
        title: null,
        description: null,
      }
    };
  },
  computed: {
    ...mapState(['isTokenChecked', 'isNavOpen',]),
  }, 
  mounted() {
    this.checkUserToken();
  }, 
  methods: {
    ...mapMutations(['tokenChecked', 'setUserData', 'setFactorUserData']),
    closeSmModal() {
      this.showSmModal = false;
      this.smModalData = null;
    },
    openSmModal(obj) {
      this.smModalData = {...obj};
      this.showSmModal = true;
    },
    toggleSmModal(obj) {
      this.openSmModal(obj);
      setTimeout(() => {
        this.closeSmModal();
      }, 2700);
    },
    openErrorModal(obj){
      this.errorModalCenterActive = true;
      this.errorModalData = obj;
    }, 
    openSubmitModal(obj, callback) {
      this.submitModalCenterActive = true;
      this.submitModalData = obj;
      this.modalSubmiCallback = callback;
    },
    closeSubmitModal(){ 
      this.submitModalCenterActive = false;
      this.submitModalData = {
        title: null,
        description: null,
      };
    },
    closeErrorModal() {
      this.errorModalCenterActive = false; 
      this.errorModalData = {
        title: null,
        description: null,
      };
    },
    toggleErrorModal(obj) {
      this.errorModalCenterActive = true;
      this.errorModalData = obj;
      setTimeout(() => {
        this.closeErrorModal();  
      }, 2100);
    },
    async checkUserToken() {
      if(this.isTokenChecked) { 
        this.isDataLoad = true;
        return;
      }
      await axios({
        method: "GET",
        data: {},
        headers: HEADERS_TOKEN_JSON(),
        url: API_AUTH.checkToken,
      }).then((e) => {
        const data = e.data.data
        this.setUserData(data.user);
        this.setFactorUserData(data.factor_user);
        this.tokenChecked(); 
      }).catch((e) => {
        this.$router.push({
          name: 'AuthLogin',
        });
        console.log(e);
      });
    },
  },
  provide() {
    return {
      toggleErrorModal: this.toggleErrorModal,
      closeErrorModal: this.closeErrorModal,
      openErrorModal: this.openErrorModal,
      openSubmitModal: this.openSubmitModal,
      closeSubmitModal: this.closeSubmitModal,
      closeSmModal: this.closeSmModal,
      openSmModal: this.openSmModal,
      toggleSmModal: this.toggleSmModal,
    };
  },
};
</script>

<style></style>