<template>
  <div 
    style="z-index: 99999;"
    class="blur-parent-bg rounded-lg">
      <div 
        style="z-index: 999999; "
        class="h-100-p center-block inl-40 transition duration-150 ease-in-out absolute rounded-lg">
        <form
          @submit.prevent="saveData"
          class="h-100-p flex"
          action="">
          <div class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            
            <div class="text-gray-800 f-20 text-center font-bold tracking-normal leading-tight mb-4">
              <span v-if="userUUID">{{ t('actions.addUser') }}</span>
              <span v-else>{{ t('actions.addUser') }}</span>
            </div>
            
            <button
              @click.prevent="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            
            <div
              style="height: 90%; overflow-y: scroll;" 
              class="scroll-styling inl-100 padding-r-15">

              <div class="inl-100 justify-center h-100-p inl-100 relative">
                <div class="flex flex-col text-left">
                  <div>
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.fio') }} <b>*</b></label>
                    </div>
                    <input
                      name="fio"
                      v-model="modalData.fio"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.fioHelp')"
                    />
                  </div>

                  <div>
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.phone') }} <b>*</b></label>
                    </div>
                    <input
                      name="phone"
                      v-model="modalData.phone"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.phoneHelp')"
                    />
                  </div>
                  
                  <div class="margin-bt-10">
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.gender') }} <b>*</b></label>
                    </div>
                    <select
                      v-model="modalData.gender"
                      class="rounded bg-gray-50 border pr-5 bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                    >
                      <option disabled :value="null">{{ t('form.genderHelp') }}</option>
                      <option
                        v-for="(el, key) in genders" :key="key" 
                        :value="key">{{ el }}</option>
                    </select>
                  </div>

                  <div>
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.email') }}</label>
                    </div>
                    <input
                      name="email"
                      v-model="modalData.email"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.emailHelp')"
                    />
                  </div>

                  <div>
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.iin') }} <b>*</b></label>
                    </div>
                    <input
                      name="iin"
                      v-model="modalData.iin"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.iinHelp')"
                    />
                  </div>

                  <div>
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.position') }}</label>
                    </div>
                    <input
                      v-model="modalData.job_title"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.positionHelp')"
                    />
                  </div>

                  <div class="margin-bt-10">
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.signer') }} <b>*</b></label>
                    </div>
                    <input 
                      name="is_signer" 
                      v-model="modalData.is_signer"
                      type="checkbox" 
                      class="pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                    >
                  </div>

                  <div>
                    <div class="flex flex-col">
                      <label
                        class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                        >{{ t('form.password') }} <b>*</b></label>
                    </div>
                    <input
                      name="password"
                      v-model="modalData.password"
                      type="password"
                      class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                      :placeholder="t('form.passwordHelp')"
                    />
                  </div>

                  <div v-if="userUUID">
                    <div>
                      <div class="flex flex-col">
                        <label
                          class="text-gray-800 text-sm font-bold leading-tight tracking-normal margin-bt-5"
                          >{{ t('form.identityDocument') }}</label>
                      </div>
                      
                      <FileInput
                        :prevFile="prevData.identification_pdf"
                        nameKey="identification_pdf"
                        :setData="onFileChanged"
                        addedCls="px-2 py-1 block w-full margin-bt-5 text-xs text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                        :name="'identification_pdf'"
                      />
                    </div>
                    <div class="margin-t-10">
                      <div class="flex flex-col">
                        <label
                          class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                          >{{ t('form.address') }} <b>*</b></label>
                      </div>
                      <input
                        v-model="modalData.address"
                        class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                        :placeholder="t('form.addressHelp')"
                      />
                    </div>
                  </div>

                </div>

              </div>
              </div>
              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">
                  {{ t('actions.save') }}
                </button>
            </div>

          </div>
        </form>
      </div>
  </div>
</template>

<script>
import FileInput from '@/components/Forms/FileInput.vue';
import { API_FACTOR, HEADERS_TOKEN_JSON, HEADERS_TOKEN_JSON_FILE } from '@/const/uri';
import axios from 'axios';
import { formShowError, fromErrorToHtml } from "@/utils/erros.js";
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: ["openErrorModal",],
  components: {
    FileInput,
  },
  data() {
    return {
      prevData: {
        identification_pdf: null,
      },
      genders: [],
      modalData: {
        gender: null, 
        fio: null,
        phone: null,
        email: null,
        iin: null,
        password: null,
        job_title: null,
        identification_pdf: null,
        address: null,
        is_signer: false,
      },
    };
  },
  mounted() {
    if(this.$props.userUUID) {
      this.getData();
    } else {
      this.getFormData();
    }
  },
  methods: {
    fromErrorToHtml,
    onFileChanged(key, file, isDelete=false,){
      if(!isDelete)
        this.modalData[key] = file;
      else
        this.modalData[key] = '';
    },
    async getFormData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        data: this.modalData,
        params: {
          user_uuid: this.$props.userUUID,
        },
        url: API_FACTOR.consts.gender,
      })
        .then((e) => {
          this.genders = e.data.data.vals;
        })
        .catch((e) => {
          console.log(e);
          formShowError.bind(this)(e);
        });
    },
    async getData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        data: this.modalData,
        params: {
          user_uuid: this.$props.userUUID,
        },
        url: API_FACTOR.factor.company + this.$props.companyUUID + "/employee_user/",
      })
        .then((e) => {
          const data = e.data.data;
          const user = data.user;
          delete data.user;
          this.genders = data.genders;
          delete data.gender;
          this.prevData.identification_pdf = data.identification_pdf;
          this.modalData = {
            ...this.modalData,
            ...data,
            ...user,
          };
        })
        .catch((e) => {
          console.log(e);
          const errorData = e.response.data;
          if ([400, 404].indexOf(e.response.status) != -1) {
            this.openErrorModal({
              title: "400",
              description: this.fromErrorToHtml(errorData, [],),
            });
          }
        });
    }, 
    async saveData() {
      const method = !this.$props.userUUID ? "POST" : "PUT";
      await axios({
        method,
        headers: HEADERS_TOKEN_JSON_FILE(),
        data: this.modalData,
        params: {
          user_uuid: this.$props.userUUID,
        },
        url: API_FACTOR.factor.company + this.$props.companyUUID + "/employee_user/",
      })
        .then((e) => {
          if(!this.$props.userUUID) {
            this.$props.addUserData(e.data.data);
          } else {
            this.$props.changeUserData(e.data.data, this.$props.userIndex,);
          }
          this.toggleModal();
        })
        .catch((e) => {
          console.log(e);
          const errorData = e.response.data;
          if ([400, 404].indexOf(e.response.status) != -1) {
            this.openErrorModal({
              title: "400",
              description: this.fromErrorToHtml(errorData, [
                {
                  field_name: 'fio', 
                  label: this.t('form.fio'),
                },
                {
                  field_name: 'phone', 
                  label: this.t('form.phone'),
                },
                {
                  field_name: 'email', 
                  label: this.t('form.email'),
                },
                {
                  field_name: 'iin', 
                  label: this.t('form.iin'),
                },
                {
                  field_name: 'password', 
                  label: this.t('form.password'),
                },
              ],),
            });
          }
        });
    },
  }, 
  props: {
    addUserData: {},
    changeUserData: {},
    toggleModal: {},
    companyUUID: {},
    userUUID: {},
    userIndex: {},
  },
}
</script>