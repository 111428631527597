<template>
  <div
    :class="[!isNavOpen ? 'ez-modal-width-full' : 'ez-modal-width']"
    class="ez-modal-height-70 ez-modal-top-40 limit-order-list shadow-sm px-2 py-3"
  >

    <button
      @click.prevent="toggleModal"
      class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="icon icon-tabler icon-tabler-x"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        stroke-width="2.5"
        stroke="currentColor"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
      </svg>
    </button>

    <div v-if="!isLoad" class="inl-100 h-100-p flex justify-center items-center">
      <img
        class="loading-icon"
        src="/assets/images/loading/loader-1.svg"
        alt=""
      />
    </div>

    <div class="sm-limit-table" v-else>
      <div class="flex flex-row justify-between mb-2 padding-t-40">
        <h2 class="text-lg font-semibold text-gray-900">
          {{ t("deal.title") }}: {{ deal.contract_number }}
        </h2>
        <div class="margin-r-30">
          <div 
            v-if="[0, 1, 2].includes(deal.deal_status.key)"
            class="flex flex-row justify-center">
            <button
              v-if="deal.deal_status.key == 0"
              @click="() => {
                submitRegisterModalData(this.$props.currentModalIndex, deal, 0,);
              }"
              type="button"
              class="margin-r-15 btn btn-success"
              >{{ t('actions.register')}}</button>
            <button
              v-if="deal.deal_status.key == 1"
              @click="() => {
                submitRegisterModalData(this.$props.currentModalIndex, deal, 1,);
              }"
              type="button"
              class="margin-r-15 btn btn-success"
              >{{ t('actions.submit')}}</button>
            <button
              v-if="deal.deal_status.key == 2"
              @click="() => {
                submitRegisterModalData(this.$props.currentModalIndex, deal, 1,);
              }"
              type="button"
              class="margin-r-15 btn btn-success"
              >{{ t('actions.sign')}}</button>
            <button
              v-if="deal.deal_status.key != 6"
              @click="() => {
                submitRegisterModalData(this.$props.currentModalIndex, deal, -1,);
              }"
              type="button"
              class="btn btn-danger"
              >{{ t('actions.cancel')}}</button>
          </div>
        </div>

      </div>

      <div
        class="grid grid-cols-3 gap-4 deal-wrapper-inner px-2 py-3 relative overflow-x-auto shadow-md sm:rounded-lg scroll-styling"
      >
      
        <div
          class="h-100-p py-3 px-4 margin-b-15 bg-white border border-gray-200 rounded-lg shadow"
        >

          <div class="shadow sm:rounded-lg p-2 py-2">
            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
              {{ t('deal.info') }}
            </h5>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('deal.status') }}:</div>
              <div><b>{{ deal.deal_status.val }}</b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('deal.contractPeriod') }}:</div>
              <div><b>{{ deal.contract_period }}</b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('deal.monetaryClaim') }} <br> {{ t('deal.factoringAmount') }}:</div>
              <div><b>{{ beautifyInteger(deal.factor_sum) }} {{ currencyCode}} </b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('deal.factoringFeeForTheBuyer') }}:</div>
              <div><b>{{ beautifyInteger(deal.buyer_comission_sum) }} {{ currencyCode}} </b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('deal.factoringFeeForTheSupplier') }}:</div>
              <div><b>{{ beautifyInteger(deal.supplier_comission_sum) }} {{ currencyCode}} </b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('deal.guarantee') }}:</div>
              <div><b>{{ deal.guarantor_text }}</b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('form.createdDate') }}:</div>
              <div><b>{{ deal.created_at }}</b></div>
            </div>
          </div>


          <div class="shadow sm:rounded-lg p-2 py-2 margin-t-25">
            <div class=" f-16 font-semibold tracking-tight text-gray-900">
              {{ t('limit.company') }}
            </div>

            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('company.totalLimit') }}:</div>
              <div><b>{{ beautifyInteger(companyLimit.total_limit) }}</b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('company.dealLimit') }}:</div>
              <div><b>{{ beautifyInteger(companyLimit.transaction_limit) }}</b></div>
            </div>
            <div class="f-15 flex flex-row items-center margin-t-3">
              <div class="margin-r-10">{{ t('limit.status') }}:</div>
              <div><b>{{ companyLimit.status.val }}</b></div>
            </div>
          </div>

        </div>



        <div
          class="h-100-p py-3 px-4 bg-white border border-gray-200 rounded-lg shadow"
        >
        <div class="shadow sm:rounded-lg p-2 py-2">
          <div class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            {{ t('form.client') }}
          </div>

          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('deal.actsAs') }}:</div>
            <div><b>{{ clientCompanyType }}</b></div>
          </div>

          <div class="margin-t-10 f-16 font-semibold tracking-tight text-gray-900">
            {{ t('form.signer') }}
          </div>

          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.fio') }}:</div>
            <div><b>{{ deal.client_signer.user.fio }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.phone') }}:</div>
            <div><b>{{ deal.client_signer.user.phone }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.email') }}:</div>
            <div v-if="deal.client_signer.user.email"><b>{{ deal.client_signer.user.email }}</b></div>
            <div v-else><b>-</b></div>
          </div>
        </div>

        <div class="shadow sm:rounded-lg p-2 py-2 margin-t-25">
          <div class="margin-t-10 f-16 font-semibold tracking-tight text-gray-900">
            {{ t('company.title') }}
          </div>

          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.name') }}:</div>
            <div><b>{{ deal.client_company.name.ru }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.shortName') }}:</div>
            <div><b>{{ deal.client_company.name_short.ru }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.phone') }}:</div>
            <div><b>{{ deal.client_company.phone }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.email') }}:</div>
            <div><b>{{ deal.client_company.email }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.legalAddress') }}:</div>
            <div><b>{{ deal.client_company.address_legal }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.formOfActivity') }}:</div>
            <div><b>{{ deal.client_company.company_type.val }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.binIIN') }}:</div>
            <div><b>{{ deal.client_company.bin }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.iik') }}:</div>
            <div><b>{{ deal.client_company.iik }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.bik') }}:</div>
            <div><b>{{ deal.client_company.bik }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.bank') }}:</div>
            <div><b>{{ deal.client_company.requisites_bank.name }}</b></div>
          </div>
        </div>

        </div>

        <div
          class="h-100-p py-3 px-4 bg-white border border-gray-200 rounded-lg shadow"
        >

        <div class="shadow sm:rounded-lg p-2 py-2">

          <div class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            {{ t('navigation.counterparty') }}
          </div>

          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('deal.actsAs') }}:</div>
            <div><b>{{ counterpartyType }}</b></div>
          </div>

          <div class="margin-t-10 f-16 font-semibold tracking-tight text-gray-900">
            {{ t('form.signer') }}
          </div>

          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.fio') }}:</div>
            <div><b>{{ deal.client_counterparty.fio }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.phone') }}:</div>
            <div><b>{{ deal.client_counterparty.phone }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.email') }}:</div>
            <div v-if="deal.client_counterparty.email"><b>{{ deal.client_counterparty.email }}</b></div>
            <div v-else><b>-</b></div>
          </div>
        </div>

        <div class="shadow sm:rounded-lg p-2 py-2 margin-t-25">
          <div class="margin-t-10 f-16 font-semibold tracking-tight text-gray-900">
            {{ t('company.title') }}
          </div>

          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.name') }}:</div>
            <div><b>{{ deal.client_counterparty.company_name.ru }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.legalAddress') }}:</div>
            <div><b>{{ deal.client_counterparty.address_actual }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.formOfActivity') }}:</div>
            <div><b>{{ deal.client_counterparty.company_type.val }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.binIIN') }}:</div>
            <div><b>{{ deal.client_counterparty.bin }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.iik') }}:</div>
            <div><b>{{ deal.client_counterparty.iik }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.bik') }}:</div>
            <div><b>{{ deal.client_counterparty.bik }}</b></div>
          </div>
          <div class="f-15 flex flex-row items-center margin-t-3">
            <div class="margin-r-10">{{ t('form.bank') }}:</div>
            <div><b>{{ deal.client_counterparty.requisites_bank.name }}</b></div>
          </div>
        </div>
      </div>

        <div
          class="h-100-p py-3 px-4 margin-t-15 bg-white border border-gray-200 rounded-lg shadow"
        >
          <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            {{ t('navigation.product') }}
          </h5>
          
          <DealProduct v-if="product" :product="product" />

        </div>


      </div>

    </div>

  </div>
</template>

<script>
import axios from "axios";
import { formShowError } from "@/utils/erros";
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import { formatDateTime, beautifyInteger, numberWithSpaces } from "@/utils/base";
import { mapState } from "vuex";
import { useI18n } from "vue-i18n";
import DealProduct from '@/components/Elemets/DealProduct.vue';

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  inject: ["openErrorModal"],
  components: {
    DealProduct,
  },
  computed: {
    ...mapState(["isNavOpen"]),
    clientCompanyType() {
      const type = this.deal.client_type.key;
      return this.companyTypes[type+''];
    },
    counterpartyType() {
      const clientType = !this.deal.client_type.key ? 1 : 0;
      return this.companyTypes[clientType+''];
    },
  },
  data() {
    return {
      isLoad: false,
      currencyCode: null,
      product: null,
      companyTypes: {
        "0": {},
        "1": {},
      },
      clientCompanyTypeNum: 0,
      companyLimit: {
        status: {},
      },
      deal: {
        client_type: {},
        deal_status: {},
        client_signer: {
          user: {},
        },
        client_company: {
          company_type: {},
          requisites_bank: {},
        },
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDateTime,
    beautifyInteger, numberWithSpaces,
    async loadData() {
      this.isLoad = false;
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.deals.base + this.$props.currentDealUUID + "/",
      })
        .then((e) => {
          this.isLoad = true;
          const data = e.data.data;
          this.deal = data.deal;
          this.currencyCode = data.currency;
          this.companyTypes = data.company_types.vals;
          this.companyLimit = data.compamy_limit;
          this.product = data.product;
        })
        .catch(formShowError.bind(this));
    },
  },
  props: {
    submitRegisterModalData: {
      default: () => {},
    },
    currentModalIndex: {
      default: null,
    },
    currentDealUUID: {
      default: {
        contract_number: null,
      },
    },
    limitUUID: {
      default: null,
    },
    toggleModal: {
      default: null,
    },
  },
};
</script>