<template>
  <div>
    <div class="page-header min-vh-75">
      <div class="app-page-title flex items-center">
        <div class="flex items-center justify-between inl-100">
          <div class="flex items-center">
            
            <div class="page-title-wrapper margin-r-30">
              <div class="page-title-heading">
                <div class="page-title-icon">
                  <img
                    src="/assets/images/icons/business_building.svg"
                  />
                </div>
                <div>
                  {{ t('navigation.clients' )}}
                  <div class="page-title-subheading">{{ t('navigation.clientsAdd') }}</div>
                </div>
              </div>
            </div>

            <div class="nav-buttons">
              <ul
                class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav"
              >
                <li class="nav-item">
                  <router-link
                    :to="{
                      name: !companyId ? 'CreateCompany' : 'ClientCompanyId',
                      params: companyParams,
                    }"  
                    v-slot="{ href, navigate, isActive }">
                  <div
                    class="nav-link show"
                    :class="[
                      isActive ? 'active': '',
                    ]"
                    :href="href"
                    @click="navigate">
                    <span class="">{{ t('company.title') }}</span>
                  </div>
                  </router-link>
                </li>
                <li 
                  v-if="companyId"
                  class="nav-item">
                  <div
                    class="nav-link show"
                    @click="openEmployeeModal">
                    <span class="">{{ t('navigation.employees') }}</span>
                  </div>
                </li>
                <li 
                  v-if="companyId"
                  class="nav-item">
                  <div
                    class="nav-link show"
                    @click="openCounterpartyModal">
                    <span class="">{{ t('navigation.counterparties') }}</span>
                  </div>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>

      <div class="fix-templ-row">
        <div class="col-lg-12">
          <router-view 
            :key="$route.fullPath"
            v-slot="{ Component }"> 
            <component 
              :updateCompanyId="updateCompanyId"
              ref="childRef" :is="Component" />
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
      companyId: null,
    };
  },
  beforeMount() {
    this.companyId = this.$route.params.companyUUID;
  },
  methods: {
    updateCompanyId(companyId) {
      this.companyId = companyId;
    },
    openEmployeeModal() {
      this.$refs.childRef.toggleEmployeeModal();
    },
    openCounterpartyModal() {
      this.$refs.childRef.toggleCounterpartyModal();
    },
  },
  computed: {
    companyParams() {
      if(!this.companyId) return {};
      return {
        companyUUID: this.companyId,
      };
    },
  },
  props: {},
};
</script>