<template>
  <div>
    <table style="width: calc(100% - 30px); margin-right: 35px;"
      class="w-full text-sm text-left rtl:text-right text-gray-500">
      <thead class="text-xs text-gray-700 uppercase">
        <tr>
          <th class="inl-10 text-left py-3">#</th>
          <th class="inl-20 text-left py-3">{{ t('form.commission') }}</th>
          <th class="inl-70 text-left py-3">{{ t('form.clarification') }}</th>
        </tr>
      </thead>
      <tbody >
        <tr
          v-for="(el, ind) in comissions"
          :key="ind"
          class="bg-white dark:bg-gray-800 relative"
        >
          <td class="inl-10 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          ><b>{{ ind + 1 }}</b></td>
          <td
            class="inl-20 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <input
              style="width: 50px"
              v-model="el.comission"
              class="text-center text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-8 flex items-center text-sm border-gray-300 rounded border"
              @input="() => {
                handlePercentValue(ind);
              }"
            />
          </td>
          <td
            class="inl-70 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <textarea
              @input="updateDataText"
              v-model="el.clarification"
              class="pl-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-8 flex items-center text-sm border-gray-300 rounded border"
            ></textarea>
          </td>
          <div 
            v-if="ind > 0"
            style="right: -30px;"
            @click="() => {
              removeData(ind);
            }"
            class="center-v-block ez-red-fill-hover pointer">
            <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
            </svg>
          </div>
        </tr>
      </tbody>
    </table>
    
    <div class="flex margin-l-5 margin-t-15">
      <div @click="addEmptyData" class="relative add-button indigo sm rounded-button bg-indigo-700 ">
        <svg
          class="center-block w-6 h-6 text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            class="text-white"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 1v16M1 9h16"
          ></path>
        </svg>
      </div>
    </div>

  </div>
</template>

<script>

import { checkValue0100, isPositiveInteger } from '@/utils/base';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
      comissions: [],
    };
  },
  mounted() {
    if(this.$props.prevData && this.$props.prevData.length > 0) {
      this.comissions = [...this.$props.prevData];
    } else {
      this.comissions  = [
        {
          comission: null,
          clarification: null,
        }
      ];
    }
  },
  watch: { 
    prevData(to) {
      if(to && to.length > 0) {
        this.comissions = [];
        to.forEach((el) => {
          this.comissions.push({
            comission: el.comission_rate,
            clarification: el.clarification,
          });
        });
      } else {
        this.comissions  = [
          {
            comission: null,
            clarification: null,
          }
        ];
      }
    },
  },
  methods: {
    removeData(ind) {
      this.comissions.splice(ind, 1);
    },
    handlePositiveInteger(ind) {
      const value  = this.comissions[ind].clarification;
      const isSuccess= this.isPositiveInteger(value);
      if(isSuccess) {
        this.$props.updateData(
          this.$props.updateKey, 
          this.comissions,
        );
        return value;
      }
      this.comissions[ind].clarification = null;
      return null;
    },
    updateDataText() {
      this.$props.updateData(
        this.$props.updateKey, 
        this.comissions,
      );
    },
    handlePercentValue(ind) {
      const value  = this.comissions[ind].comission;
      const isSuccess= this.checkValue0100(value);
      if(isSuccess) {
        this.$props.updateData(
          this.$props.updateKey, 
          this.comissions,
        );
        this.comissions[ind].comission = value;
        return true;
      }
      this.comissions[ind].comission = null;
      return null;
    },
    checkValue0100, isPositiveInteger,
    addEmptyData() {
      this.comissions.push({
        comission: null,
        clarification: null, 
      });
    }, 
  },
  props: {
    prevData: {
      default: null,
    },
    updateKey: {
      default: null,
    },
    updateData: {
      default: () => {},
    }, 
  },
};
</script>