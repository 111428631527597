<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div>
      <div 
        style="z-index: 99999; left: 60%;"
        class="h-100-p top-0 inl-40 transition duration-150 ease-in-out absolute right-0 left-0 rounded-lg">
        <form
          class="h-100-p flex"
          @submit.prevent="saveData"
          action="">
          <div class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            <div class="text-gray-800 f-20 font-bold tracking-normal leading-tight mb-4">
              {{ t('actions.addUser') }}
            </div>
            <button
              @click="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <div
              class="justify-center h-100-p inl-100 flex flex-col text-left relative">
              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.fio') }} <b>*</b></label>
                </div>
                <input
                  v-model="modalData.fio"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.fioHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.userPhone') }}<b>*</b></label>
                </div>
                <input
                  v-model="modalData.phone"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.userPhoneHelp')"
                />
              </div>

              <div class="margin-bt-10">
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.gender') }} <b>*</b></label>
                </div>
                <select
                  v-model="modalData.gender"
                  class="rounded bg-gray-50 border pr-5 bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                >
                  <option disabled :value="null">{{ t('form.genderHelp') }}</option>
                  <option
                    v-for="(el, key) in genders" :key="key" 
                    :value="key">{{ el }}</option>
                </select>
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.email') }}</label>
                </div>
                <input
                  v-model="modalData.email"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.emailHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.iin') }}<b>*</b></label>
                </div>
                <input
                  v-model="modalData.iin"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.iinHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.position') }}</label>
                </div>
                <input
                  v-model="modalData.job_title"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.positionHelp')"
                />
              </div>

              <div class="margin-bt-10">
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.signer')}} <b>*</b></label>
                </div>
                <input 
                  name="is_signer" 
                  v-model="modalData.is_signer"
                  type="checkbox" 
                  class="pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                >
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.password')}} <b>*</b></label>
                </div>
                <input
                  v-model="modalData.password"
                  type="password"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.passwordHelp')"
                />
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                >
                  {{ t('actions.save') }} 
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>

    

  </div>
</template>

<script>
import axios from 'axios';
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
import { formShowError, fromErrorToHtml } from "@/utils/erros.js";
import { getObjDataNotNull } from '@/utils/base';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
  ],
  data() {
    return {
      genders: [],
      modalData: {
        gender: null,
        fio: null,
        phone: null,
        email: null,
        iin: null,
        job_title: null,
        is_signer: null,
        password: null,
      }, 
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    fromErrorToHtml, getObjDataNotNull,
    async getFormData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        data: this.modalData,
        params: {
          user_uuid: this.$props.userUUID,
        },
        url: API_FACTOR.consts.gender,
      })
        .then((e) => {
          this.genders = e.data.data.vals;
        })
        .catch((e) => {
          console.log(e);
          formShowError.bind(this)(e);
        });
    },
    async saveData() {
      await axios({
        method: "POST",
        headers: HEADERS_TOKEN_JSON(),
        data: this.getObjDataNotNull(this.modalData),
        url: API_FACTOR.factor.createCompanyUser,
      }).then((e) => {
        const userUUID = e.data.data.factor_user.uuid;
        const user = e.data.data.user;
        this.updateData(
          this.$props.fieldKey,
          userUUID,
          `${user.fio} (${user.phone})`,
        );
        this.toggleModal();
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                ...this.$props.fields, 
                {
                  field_name: 'iin', 
                  label: this.t('form.iin'),
                },
                {
                  field_name: 'password', 
                  label: this.t('form.password'),
                },
                {
                  field_name: 'email', 
                  label: this.t('form.email'),
                },
              ],
            ), 
          });
        }
      });
    }
  },
  props: {
    fieldKey: {},
    fields: {},
    toggleModal: {},
    updateData: {
      default: () => {},
    }
  },
};
</script>