<template>
  <div style="z-index: 99999" class="blur-parent-bg rounded-lg">
    <div
      style="z-index: 999999"
      class="h-100-p center-block inl-40 transition duration-150 ease-in-out absolute rounded-lg"
    >
      <form @submit.prevent="saveData" class="h-100-p flex" action="">
        <div
          class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
        >
          <div
            class="text-gray-800 f-20 text-center font-bold tracking-normal leading-tight mb-4"
          >
            <span v-if="modalUUID">{{
              t("navigation.changeCounterparty")
            }}</span>
            <span v-else>{{ t("navigation.addCounterparty") }}</span>
          </div>

          <button
            @click.prevent="toggleModal"
            class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="icon icon-tabler icon-tabler-x"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="18" y1="6" x2="6" y2="18" />
              <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
          </button>

          <div
            style="height: 90%; overflow-y: scroll"
            class="scroll-styling inl-100 padding-r-15"
          >
            <div
              class=""
            >
              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyFio') }} <b>*</b></label
                  >
                </div>
                <input
                  name="fio"
                  v-model="modalData.fio"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.fioHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyPhone') }} <b>*</b></label
                  >
                </div>
                <input
                  name="phone"
                  v-model="modalData.phone"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.phoneHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyEmail') }} <b>*</b></label
                  >
                </div>
                <input
                  name="email"
                  v-model="modalData.email"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.emailHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyAddressActual') }} <b>*</b></label
                  >
                </div>
                <input
                  name="address_actual"
                  v-model="modalData.address_actual"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.enterAddressActual')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyPosition') }} <b>*</b></label
                  >
                </div>
                <input
                  name="position"
                  v-model="modalData.position"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.positionHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyBin') }} <b>*</b></label
                  >
                </div>
                <input
                  name="bin"
                  v-model="modalData.bin"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.binHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyBic') }} <b>*</b></label
                  >
                </div>
                <input
                  name="bik"
                  v-model="modalData.bik"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.bikHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.counterpartyIik') }} <b>*</b></label
                  >
                </div>
                <input
                  name="iik"
                  v-model="modalData.iik"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.iikHelp')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.companyType') }} <b>*</b></label
                  >
                </div>
                <model-select
                  v-if="companyTypes"
                  :options="getSelectOptionsObj(companyTypes)"
                  v-model="modalData.company_type"
                  :placeholder="t('form.selectCompanyType')"
                >
                </model-select>
              </div>

              <div>
                <div class="flex flex-col mt-2">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.gender') }} <b>*</b></label
                  >
                </div>
                <model-select
                  v-if="genders"
                  :options="getSelectOptionsObj(genders.vals)"
                  v-model="modalData.user_gender"
                  :placeholder="t('form.genderHelp')"
                >
                </model-select>
              </div>

              <div>
                <div class="flex flex-col mt-2">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.bank') }} <b>*</b></label
                  >
                </div>
                <model-select
                  v-if="banks"
                  :options="getSelectOptionsBase(banks, 'uuid', 'name')"
                  v-model="modalData.requisites_bank"
                  :placeholder="t('form.bankHelp')"
                >
                </model-select>
              </div>

              <div class="font-semibold f-17 margin-t-15 margin-bt-15">
                {{ t('form.countrepartyCompanyNames') }}:
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.ru') }} <b>*</b></label
                  >
                </div>
                <input
                  name="name_ru"
                  v-model="modalData.company_name.ru"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.enterCompanyName')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.en') }} <b>*</b></label
                  >
                </div>
                <input
                  name="name_en"
                  v-model="modalData.company_name.en"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.enterCompanyName')"
                />
              </div>

              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.kz') }} <b>*</b></label
                  >
                </div>
                <input
                  name="name_kz"
                  v-model="modalData.company_name.kz"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.enterCompanyName')"
                />
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                >
                  {{ t('actions.save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  API_FACTOR,
  HEADERS_TOKEN_JSON,
  HEADERS_TOKEN_JSON_FILE,
} from "@/const/uri";
import axios from "axios";
import { formShowError, fromErrorToHtml } from "@/utils/erros.js";
import { ModelSelect } from "vue-search-select";
import { getSelectOptionsObj, getSelectOptionsBase } from "@/utils/base";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  inject: ["openErrorModal"],
  components: {
    ModelSelect,
  },
  data() {
    return {
      modalData: {
        fio: null,
        bin: null,
        bik: null,
        iik: null,
        address_actual: null,
        requisites_bank: null,
        user_gender: null,
        position: null,
        phone: null,
        company_name: {
          ru: null,
          kz: null,
          en: null,
        },
        company_type: null,
      },
    };
  },
  mounted() {
    if (this.$props.modalUUID) {
      this.getData();
    }
  },
  methods: {
    fromErrorToHtml, getSelectOptionsObj,
    getSelectOptionsBase,
    onFileChanged(key, file, isDelete = false) {
      if (!isDelete) this.modalData[key] = file;
      else this.modalData[key] = "";
    },
    async getData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        data: this.modalData,
        params: {
          counterparty: this.$props.modalUUID,
        },
        url:
          API_FACTOR.factor.company +
          this.$props.companyUUID +
          "/counterparty/",
      })
        .then((e) => {
          const data = e.data.data;
          this.modalData = data;
          this.modalData.company_type = parseInt(data.company_type.key);
        })
        .catch((e) => {
          console.log(e);
          formShowError.bind(this)(e);
        });
    },
    async saveData() {
      const method = !this.$props.modalUUID ? "POST" : "PUT";
      await axios({
        method,
        headers: HEADERS_TOKEN_JSON_FILE(),
        data: {
          ...this.modalData,
          company_name: JSON.stringify(this.modalData.company_name),
        },
        params: {
          counterparty: this.$props.modalUUID,
        },
        url:
          API_FACTOR.factor.company +
          this.$props.companyUUID +
          "/counterparty/",
      })
        .then((e) => {
          if (!this.$props.modalUUID) {
            this.$props.addData(e.data.data);
          } else {
            this.$props.changeData(e.data.data, this.$props.modalIndex);
          }
          this.toggleModal();
        })
        .catch((e) => {
          console.log(e);
          formShowError.bind(this)(e);
        });
    },
  },
  props: {
    addData: {},
    changeData: {},
    toggleModal: {},
    companyUUID: {},
    modalUUID: {},
    modalIndex: {},
    companyTypes: {},
    genders: {},
    banks: {},
  },
};
</script>