<template>
  <div class="rounded-lg border overflow-hidden margin-bt-15">
    <div class="p-4">
      <h3 class="font-semibold text-lg">{{ product.name }}</h3>
      <h4 class="font-semibold f-14">{{ t('form.financeFeeRate') }}</h4>
      
      <div class="flex flex-wrap justify-start items-baseline margin-bt-15">
        <div class="f-12 inl-90 text-sm margin-bt-1 text-gray-500 flex">
          {{ t('form.maximumFinancingPeriod') }}: {{ product.max_day_amount }}
          {{ getCorrectEndingDay(product.max_day_amount) }}
        </div>
      </div>

      <div class="flex flex-wrap justify-start items-baseline">
        <div class="inl-48 text-sm flex flex-col margin-bt-1">
          <div class="margin-bt-5">{{ t('form.forSupplier') }}</div>
          <div
            class="flex flex-row flex-wrap f-12 items-center"
            v-for="(el, cInd) in supplierFilterData"
            :key="cInd"
          >
            <div class="text-gray-500 inl-48 f-12">
              {{ t('form.commission') }}: {{ el.comission_rate }}%
            </div>
            <div v-if="el.clarification" 
              style="line-height: 13px"
              class="text-gray-500 inl-48 f-12">
              {{ el.clarification }}
            </div>
          </div>
          <div class="flex flex-row">
            <div class="f-12 text-gray-500">{{ t('form.comissionType') }}: {{ product.supplier_comission_rule.val }}</div>
          </div>
        </div>
        <div
          class="inl-48 text-sm flex flex-col margin-bt-1 text-gray-500 justify-end"
        >
          <div class="margin-bt-5">{{ t('form.forBuyer') }}</div>
          <div
            class="flex flex-row flex-wrap f-12 items-center"
            v-for="(el, cInd) in disturbutorFilterData"
            :key="cInd"
          >
            <div class="text-gray-500 inl-48 f-12">
              {{ t('form.commission') }}: {{ el.comission_rate }}%
            </div>
            <div v-if="el.clarification" 
              style="line-height: 13px"
              class="text-gray-500 inl-48 f-12">
              {{ el.clarification }}
            </div>
          </div>
          <div class="flex flex-row">
            <div class="f-12 text-gray-500">{{ t('form.comissionType') }}: {{ product.supplier_comission_rule.val }}</div>
          </div>
        </div>
      </div>

      <div
        class="margin-t-15"
        v-if="
          product.day_comission_supplier && product.day_comission_buyer
        "
      >
        <h4 class="font-semibold f-14">{{ t('form.amountOfDailyCommissionIncrease') }}</h4>
        <div class="flex flex-wrap justify-start items-baseline">
          <div class="inl-48 text-sm flex flex-col margin-bt-1">
            <div class="margin-bt-5">{{ t('form.forSupplier') }}</div>
            <div
              v-if="product.day_comission_supplier"
              class="text-gray-500 inl-48 f-12"
            >
              {{ product.day_comission_supplier }}%
            </div>
            <div v-else class="text-gray-500 inl-48 f-12">-</div>
          </div>
          <div
            class="inl-48 text-sm flex flex-col margin-bt-1 text-gray-500 justify-end"
          >
            <div class="margin-bt-5">{{ t('form.forBuyer') }}</div>
            <div
              v-if="product.day_comission_buyer"
              class="text-gray-500 inl-48 f-12"
            >
              {{ product.day_comission_buyer }}%
            </div>
            <div v-else class="text-gray-500 inl-48 f-12">-</div>
          </div>
        </div>
      </div>

      <h4 class="font-semibold f-14 margin-t-15">
        {{ t('form.procedureForPaymentOfTranches') }}
      </h4>

      <div class="flex flex-wrap justify-start items-baseline">
        <div class="f-12 inl-90 text-sm margin-bt-1 text-gray-500 flex">
          {{ t('form.firstTranche') }}: {{ product.first_tranche }}%
        </div>
        <div
          v-for="(el, ind) in product.tranchess"
          :key="ind"
          class="f-12 inl-90 text-sm margin-bt-1 text-gray-500 flex"
        >
          {{ t(getNumberName(ind + 2)) }} {{ t('form.tranche') }}: {{ el.percent }}%;
          <span class="f-12 text-gray-500 padding-l-3" v-if="el.day_limit"
            >{{ t('form.untilTheDay') }}: {{ el.day_limit }}</span
          >
        </div>
      </div>

      <h4 class="font-semibold f-14 margin-t-15">{{ t('form.contractTemplates') }}</h4>
      <div class="flex flex-wrap justify-start items-baseline">
        
        <div class="inl-48 text-sm margin-bt-1 text-gray-500 flex">
          <div class="flex flex-row items-center pointer mt-1" 
            v-if="product.supplier_agreement"
            @click="() => {
              toggleProductDocView(product.uuid, 'supplier', product.status.key,);
            }">
            <img 
              :class="[
                product.status.key == 0 ? 'blue-icon-600' :  '',
              ]"
              class="w-5 h-5 mr-1" src="/assets/images/icons/docx.png" >
            <span 
              :class="[
                product.status.key == 0 ? 'text-blue-600' :  '',
              ]"
              class="margin-r-3">{{ t('form.debtor') }}</span>
          </div>
          <div v-else>
            <span class="">{{ t('form.debtor') }}</span>
            ({{ t('actions.notLoaded') }})
          </div>
        </div>

        <div class="inl-48 text-sm margin-bt-1 text-gray-500 flex justify-end">
          <div class="flex flex-row items-center pointer mt-1" 
            v-if="product.buyer_agreement"
            @click="() => {
              toggleProductDocView(product.uuid, 'supplier', product.status.key,);
            }">
            <img 
              :class="[
                product.status.key == 0 ? 'blue-icon-600' :  '',
              ]"
              class="w-5 h-5 mr-1" src="/assets/images/icons/docx.png" >
            <span 
              :class="[
                product.status.key == 0 ? 'text-blue-600' :  '',
              ]"
              class="margin-r-3">{{ t('form.creditor') }}</span>
          </div>
          <div v-else>
            <span class="">{{ t('form.creditor') }}</span>
            ({{ t('actions.notLoaded') }})
          </div>
        </div>

      </div>
    </div>

    <div class="border-t p-4 flex items-center justify-between">
      <span class="text-sm font-bold">{{ t('form.status') }}: {{ product.status.val }}</span>
      <div class="flex flex-row" v-if="product.status.key == 0">
        <button
          @click="
            () => {
              toggleProductModal(product.uuid);
            }
          "
          class="margin-r-10 btn btn-primary"
        >
          <span class="text-white font-bold"> {{ t('actions.change') }}</span>
        </button>
        <button
          @click="
            () => {
              updateStatus(1);
            }
          "
          class="btn btn-success"
        >
          <span class="text-white font-bold"> {{ t('actions.approve') }} </span>
        </button>
      </div>
      <span v-else-if="product.status.key == 1" class="">
        <button
          @click="
            () => {
              updateStatus(2);
            }
          "
          class="btn btn-primary"
        >
          <span class="text-white font-bold"> {{ t('actions.archive') }} </span>
        </button>
      </span>
      <span v-else-if="product.status.key == 2" class="">
        <button
          @click="
            () => {
              updateStatus(-2);
            }
          "
          class="btn btn-primary"
        >
          <span class="text-white font-bold">{{ t('actions.unArchive') }}</span>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { getCorrectEndingDay, getNumberName } from "@/utils/base";
import { fromErrorToHtml } from "@/utils/erros";
import axios from "axios";
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: ["openErrorModal", "openSubmitModal", "closeSubmitModal"],
  data() {
    return {};
  },
  computed: {
    supplierFilterData() {
      if (!this.$props.product.comissions) return [];
      return this.$props.product.comissions.filter((el) => {
        return el.commission_type.key == 0;
      });
    },
    disturbutorFilterData() {
      if (!this.$props.product.comissions) return [];
      return this.$props.product.comissions.filter((el) => {
        return el.commission_type.key == 1;
      });
    },
  },
  methods: {
    fromErrorToHtml,
    getCorrectEndingDay,
    getNumberName,
    updateStatus(status) {
      let message;
      if (status == 1) {
        message = this.t('modalMessages.activateProduct');
      } else if (status == 2) {
        message = this.t('modalMessages.archivateProduct');
      } else if (status == -2) {
        message = this.t('modalMessages.activateProductDouble');
      }
      this.openSubmitModal(
        {
          title: this.t('actions.submitAction'),
          description: message,
          btnFirst: this.t('actions.сonfirm'),
          btnSecond: this.t('actions.сancel'),
          btnFirstColor: "green",
        },
        () => {
          this.saveData(status);
          this.closeSubmitModal();
        }
      );
    },
    async saveData(status) {
      const method = this.$props.currentProductId ? "PUT" : "POST";
      let url;
      if (status == 1) {
        url = API_FACTOR.products.list + this.product.uuid + "/submit/";
      } else if (status == 2) {
        url = API_FACTOR.products.list + this.product.uuid + "/archive/";
      } else if (status == -2) {
        url = API_FACTOR.products.list + this.product.uuid + "/un_archive/";
      }
      await axios({
        method,
        url,
        headers: HEADERS_TOKEN_JSON(),
        data: {},
        params: {
          segment: this.$props.segmentUUID,
        },
      })
        .then((e) => {
          this.$props.updateProductStatus(e.data.data, this.$props.index);
        })
        .catch((e) => {
          console.log(e);
          const errorData = e.response.data;
          this.isDataUpdate = false;
          if ([400, 404].indexOf(e.response.status) != -1) {
            this.openErrorModal({
              title: "400",
              description: this.fromErrorToHtml(errorData, []),
            });
          }
        });
    },
  },
  props: {
    segmentUUID: {
      default: null,
    },
    index: {},
    toggleProductModal: {
      default: () => {},
    },
    updateProductStatus: {
      default: () => {},
    },
    product: {},
    toggleProductDocView: {
      default: () => {},
    },
  },
};
</script>