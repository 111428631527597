<template>
  <div class="">

    <div class="app-page-title flex items-center z-100">
      <div class="flex items-center justify-between inl-100">
        <div class="flex items-center">
          <div class="page-title-wrapper margin-r-30">
            <div class="page-title-heading">
              <div class="page-title-icon">
                <img src="/assets/images/icons/credit-card.png" alt="" />
              </div>
              <div>
                {{ t('cards.title') }}
                <div class="page-title-subheading">{{ t('actions.makeRequest') }}</div>
              </div>
            </div>
          </div>

          <div class="nav-buttons margin-l-10"></div>
        </div>

        <!-- right block -->
        <div class="flex items-center"></div>
      </div>
    </div>

    <div class="fix-templ-row">
      <div class="col-lg-12">
        <div class="card fix-table-row">
          <div class="card-body relative">

            <p class="mb-2 text-2xl font-semibold">{{ t('cards.banks') }}</p>
            
            <div class="flex items-center card-items flex-start">
              <div 
                v-for="(el, ind) in bankImages" :key="ind"
                @click="() => {
                  if(el[1] == 'bck.svg') {
                    toggleModal();
                  }
                }"
                class="pointer item shadow-xl rounded-lg relative flex items-center justify-center margin-r-20">
                <img
                  :src="'/assets/images/icons/cards/banks/' + el[1]" alt="">
                <div class="card-hover flex items-center justify-between flex-col">
                  <div class="text-black text-lg font-bold margin-t-15 bg-white px-2 rounded">{{ el[0] }}</div>
                  <button 
                    class="btn btn-primary margin-bt-15">
                    <span class="f-10 text-white font-bold">{{ t('actions.submitYourApplication') }}</span>
                  </button>
                </div>
              </div>
            </div>

            <p class="mb-2 text-2xl font-semibold margin-t-15">{{ t('cards.title') }}</p>

            <div class="flex items-center card-items flex-start">
              <div 
                v-for="(el, ind) in cardImages" :key="ind"
                class="pointer item shadow-xl rounded-lg relative flex items-center justify-center margin-r-20">
                <img
                  :src="'/assets/images/icons/cards/' + el[1]" alt="">
                <div class="card-hover flex items-center justify-between flex-col">
                  <div class="text-black text-lg font-bold margin-t-15 bg-white px-2 rounded">{{ el[0] }}</div>
                  <button 
                    class="btn btn-primary margin-bt-15">
                    <span class="f-10 text-white font-bold">{{ t('actions.submitYourApplication') }}</span>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <CardModal
      v-if="modalOpen"
      :closeSubmitModal="toggleModal"
    />

  </div>
</template>

<script>
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import axios from "axios";
import CardModal from '@/components/Modals/CardModal.vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    "openErrorModal",
  ],
  components: {
    CardModal,
  }, 
  data() {
    return {
      modalOpen: false,
      bankImages: [
        ['Центр Кредит', 'bck.svg', ],
        ['Forte', 'forte.png', ],
        ['Jusan', 'jusan_small.png', ],
        ['RBK', 'rbk.svg', ],
        ['Kaspi', 'kaspi_small.png', ],
        ['Freedom', 'freedom.svg', ],
      ],
      cardImages: [
        ['Visa', 'visa.png'],
        ['Mastercard', 'mastercard.png'],
      ], 
    };
  },
  methods: {
    toggleModal() {
      this.modalOpen = !this.modalOpen;
    },
    async loadData() {
      await axios({
        method: "GET",
        params: {},
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.limit.base,
      })
        .then((e) => {
          console.log(e);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>