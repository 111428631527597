import { createI18n } from "vue-i18n";

const messages = {
    en: {
        base: {
            appName: 'Digital factoring platform',
        },
        auth: {
            login: {
                condition: 'Enter your phone number to log in',
                btn: 'Sign in',
            },
            logout: {
                btn: 'Sign out',
            },
        },
        form: {
            comissionFeeTaxFinance: 'Financing fee rate (fixed)',
            binIIN: 'BIN/IIN',
            formOfActivity: 'Form of activity',
            legalAddress: 'Legal address',
            name: 'Name',
            description: 'Description',
            meaning: 'Meaning',
            variable: 'Variable',
            phone: 'Phone number',
            phoneHelp: 'Enter your phone number',
            password: 'Password',
            passwordHelp: 'Enter password',
            submitPassword: 'Confirm the password',
            ru: 'In Russian',
            en: 'In English',
            kz: 'In Kazakh',
            fio: 'Full name',
            iik: 'IBAN',
            iikHelp: 'Enter IBAN',
            bik: 'BIC',
            bikHelp: 'Enter BIC',
            fioHelp: 'Enter your full name',
            userPhone: 'User phone number',
            userPhoneHelp: 'Enter User phone number',
            email: 'Email',
            emailHelp: 'Enter Email',
            iin: 'IIN',
            bin: 'BIN',
            binHelp: 'Enter BIN',
            iinHelp: 'Enter IIN',
            position: 'Position',
            positionHelp: 'Enter Position title',
            signer: 'Signer',
            enterTitle: 'Enter the title',
            title: 'Name',
            addEmployee: 'Adding employees',
            selectUser: 'Select user',
            identityDocument: 'Identity document',
            address: 'Address',
            addressHelp: "Enter user's address",
            enterAddressActual: 'Enter the actual address',
            companyName: 'Company name',
            shortCompanyName: 'Short company name',
            counterpartyFio: 'Full name of the counterparty',
            counterpartyPhone: "Counterparty's phone number",
            counterpartyBin: 'BIN of the counterparty',
            counterpartyBic: 'BIC of the counterparty',
            counterpartyIik: 'IBAN of the counterparty',
            counterpartyEmail: 'Email of the counterparty',
            counterpartyPosition: 'Position of the counterparty',
            counterpartyAddressActual: 'Actual address of the counterparty',
            companyType: 'Company type',
            selectCompanyType: 'Select company type',
            countrepartyCompanyNames: 'Counterparty company name',
            enterCompanyName: 'Enter company name',
            productName: "The product's name",
            enterProductName: "Enter Product Name",
            maxDays: 'Maximum period (in days)',
            maxDaysAmount: 'Enter number of days',
            firstTranchePercent: 'First tranche (percentage of the amount)',
            tranchePercent: 'Enter percentage value (0-100)',
            stagesOfTranches: 'Stages of the next tranches',
            financeFeeTax: 'Financing fee rate (fixed)',
            supplierRate: "Supplier's rate",
            supplierComissionType: 'Supplier commission type',
            selectComissionType: 'Select commission type',
            holdOutside: 'Hold outside',
            holdInside: 'Holding inside',
            buyersRate: "Buyer's Rate",
            buyerComissionType: "Buyer commission type",
            amountOfDailyFeeSurchage: 'Amount of daily funding fee surcharge',
            contractTemplates: 'Contract Templates',
            debtorAgreement: 'Debtor agreement',
            creditorAgreement: "Creditor's agreement",
            commission: 'Commission',
            clarification: 'Сlarification',
            enterClarification: 'Enter your clarification',
            financeFeeRate: 'Financing fee rate',
            maximumFinancingPeriod: 'Maximum financing period',
            forSupplier: 'For supplier',
            comissionType: 'Сomission Type',
            amountOfDailyCommissionIncrease: 'Amount of daily commission increase',
            forBuyer: 'For buyer',
            procedureForPaymentOfTranches: 'Procedure for payment of tranches (based on the contract amount)',
            firstTranche: 'First tranche',
            untilTheDay: 'Until the day',
            tranche: 'tranche',
            percentOfSum: 'Percentage of the amount',
            untilWhatDay: 'Until what day',
            debtor: 'Debtor',
            creditor: 'Creditor',
            status: 'Status',
            enterSegmentName: 'Enter Segment Name',
            shortName: 'Short Title', 
            selectCompany: 'Select a company',
            limitStatus: 'Limit status',
            changedDate: 'Date of change',
            createdDate: 'Date of creation',
            startDate: 'Start of action',
            client: 'Client',
            limitDateStart: 'Start date and time of the limit',
            enterTotalLimit: 'Enter Total Limit',
            dateOfOrderCreated: 'Date of order/resolution',
            enterDealLimit: 'Enter deal limit',
            gender: 'Gender',
            genderHelp: 'Select gender',
            bank: "The beneficiary's bank",
            bankHelp: 'Select bank',
            factorSum: 'Factoring amount',
        },
        navigation: {
            profileData: 'Profile data',
            accesses: 'Accesses',
            profile: 'Profile',
            companySettings: 'Company Settings',
            title: 'Management',
            clients: 'Clients',
            personDirectories: 'Person directories',
            clientsAdd: 'Creating the client',
            listOfClients: 'List of clients',
            products: 'Products',
            product: 'Product',
            limits: 'Limits',
            cards: 'Cards',
            deals: 'Deals',
            accounting: 'Accounting',
            out: 'out of',
            quantity: 'Quantity',
            employees: 'Employees',
            counterparties: 'Counterparties',
            counterparty: 'Counterparty',
            addCounterparties: 'Adding counterparties',
            addCounterparty: 'Add a counterparty',
            changeCounterparty: 'Change counterparty',
            productList: 'List of products',
            factoringConditions: 'Factoring conditions',
            changeSement: 'Segment change - ',
            addingCompanies: 'Adding companies.',
            limtis: 'Limits',
            limtiList: 'List of limits',
            list: 'List',
            orders: 'Orders',
            all: 'All',
            orderList: 'List of orders',
            active: 'Active',
            applications: 'Applications',
            logOut: 'Log out',
            debtManagement: 'Debt Management',
            eSigning: 'e-Signing',
            smartContractsSoon: 'SmartContracts (soon)',
            reporting: 'Reporting',
            analytics: 'Analytics',
            integrations: 'Integrations',
            support: 'Support',
            editProductTemplate: 'Change template',
            scoring: 'Scoring',
            riskMonitoring: 'Risk-Monitoring',
        },
        cards: {
            title: 'Cards',
            banks: 'Banks',
        },
        actions: {
            dontClosePage: 'Не закрывайте страницу',
            sign: 'Sign',
            add: 'Add',
            systemChanged: 'Changed by the system',
            template: 'Template',
            file: 'File',
            close: 'Close',
            updating: 'Updating',
            enterClientName: "Enter client's full name",
            enterVariablesName: 'Enter variable name',
            search: 'Search',
            save: 'Save',
            create: 'Create',
            update: 'Update',
            addUser: 'Add user',
            edit: 'Edit',
            editUser: 'Edit user',
            approve: 'Approve',
            archive: 'Archive',
            unArchive: 'Unarchive',
            reject: 'Reject',
            approved: 'Approved',
            rejected: 'Rejected',
            view: 'View',
            download: 'Download',
            downloading: 'Downloading',
            generate: 'Generate',
            notLoaded: 'Not loaded',
            createProduct: 'Create a product',
            updateProduct: 'Update product information',
            change: 'Change',
            submitAction: 'Confirm the action please',
            submit: 'Confirm',
            register: 'Register',
            cancel: 'Cancel',
            submitData: 'Data has been updated',
            select: 'Select',
            selectSegment: 'Select segment',
            productStatus: 'Product status',
            attachCompanies: 'Attach companies', 
            createSegment: 'Create segment',
            updateSegment: 'Update segment',
            сonfirm: 'Confirm',
            сancel: 'Cancel',
            companyAdded: 'Companies have been added',
            stay: 'Stay here',
            actions: 'Actions',
            block: 'Block',
            activate: 'Activate',
            updated: 'Changed',
            createOrder: 'Create an order',
            choose: 'Choose',
            makeRequest: 'Make a request',
            submitYourApplication: 'Submit your application',
            leaveRequestBCC: 'Leave a request for a BCC debit card.',
            shareIIN: 'Share IIN',
            sharePhoneNumber: 'Share phone number',
            sendingRequest: 'Sending an application',
            variables: 'Variables',
        },
        errors: {
            percentageOfSum: 'The percentage of sum of all tranches cannot be more than 100',
            dataEmpty: 'No data available',
        },
        modalMessages: {
            valueCopyError: 'Error when copying',
            valueWasCopied: 'The value has been copied',
            chooseTemplateUser: 'The client was not selected',
            templateNotFound: 'The template was not found',
            activateProduct: 'Attention please, You are going to make the product visible to users', 
            archivateProduct: 'Attention please, You are going to archive a product',
            activateProductDouble: 'Attention please, You are going to reactivate the product',
            submitDeleteSegment: 'Confirm deleting the segment - ',
            deleteSegmentMessage: 'By deleting a segment you will block access to the created products in the segment',
            waitLastOperation: 'Wait for the last operation please',
            submitActivationLimit: 'Confirm activation of the limit',
            submitRegisterDeal: 'Confirm the registration of the deal', 
            submitApproveDeal: 'Confirm deal verification', 
            submitCancelDeal: 'Confirm the cancellation of the transaction',
            submitBlockingnLimit: 'Confirm limit blocking ', 
            submitOrderLimit: 'Confirm approval of the order to change the limit please',
            cancelOrderLimit: 'Confirm cancellation of the order to change the limit please',
            limitTrasancion: 'The limit per deal cannot be greater than the total limit',
            submitSendingRequest: 'You need to confirm sending data',
            submitDeleteCountreparty: 'Confirm deletion of the counterparty',
            usersWasAdded: 'Users have been added',
            userNotFound: 'User is not found',
            userProfileUpdate: 'Profile has been updated',
            companyProfileUpdated: 'Company profile has been successfully changed',
            userPasswordUpdated: 'Password has been changed',
        },
        limit: {
            company: 'Limit per company',
            status: 'Статус лимита',
        },
        smartContract: {
            title: 'Smart contracts',
            tradeTransactionFor: 'for trade transactions',
            financeTransaction: 'for financing transactions',
        },
        deal: {
            signDoc: 'Signing the document',
            signDocBuyer: 'Sign an agreement with the buyer',
            signDocSupplier: 'Sign an agreement with the supplier',
            actsAs: 'Acts as',
            title: 'Deal',
            info: 'Deal information',
            status: 'Deal Status',
            contractPeriod: 'Duration of the contract (in days)',
            monetaryClaim: 'Monetary claim',
            factoringAmount: '(factoring amount)',
            factoringFeeForTheBuyer: 'Factoring fee for the buyer',
            factoringFeeForTheSupplier: 'Factoring fee for the supplier',
            guarantee: 'Guarantee', 
        },
        company: {
            title: 'Company',
            name: 'Company name',
            totalLimit: 'Total limit',
            dealLimit: 'Limit per deal',
            number: 'Number',
            сlarification: 'Clarification',
            orderDate: 'Order date',
            type: 'Тип компании',
        },
        textNumbers: {
            first: 'First',
            second: 'Second',
            third: 'Third',
            fourth: 'Fourth',
            fifth: 'Fifth',
            sixth: 'Sixth',
            seventh: 'Seventh',
            eighth: 'Eighth',
            ninth: 'Ninth',
            tenth: 'Tenth',
        },
        docx: {
            buyerFile: 'Agreement with the buyer',
            supplierFile: 'Contract with the supplier',
            cantCancelDocx: 'A signed document cannot be canceled',
            wasSigned: 'The document was signed',
        },
        sign: {
            signContractWith: "Sign a contract with",
            buyerContract: "Buyer's contract",
            supplierContract: "Supplier's contract",
            actionWasCancelled: 'Action canceled by user',
            ncalyerConnectCancelled: 'Failed to connect to NCALayer',
        },
    },
    ru: {
        base: {
            appName: 'Цифровая факторинговая платформа',
        },
        auth: {
            login: {
                condition: 'Для того, чтобы войти введите свой номер телефона',
                btn: 'Войти',
            },
            logout: {
                btn: 'Выйти',
            },
        },
        form: {
            binIIN: 'БИН/ИИН',
            formOfActivity: 'Форма деятельности',
            legalAddress: 'Адрес юридический',
            name: 'Наименование',
            description: 'Описание',
            meaning: 'Значение',
            variable: 'Переменная',
            phone: 'Номер Телефона', 
            phoneHelp: 'Введите номер Телефона',
            password: 'Пароль',
            passwordHelp: 'Введите пароль',
            submitPassword: 'Подтвердите пароль',
            ru: 'На казахском',
            en: 'На русском',
            kz: 'На английском',
            fio: 'ФИО',
            iik: 'ИИК',
            iikHelp: 'Введите ИИК',
            bik: 'БИК',
            bikHelp: 'Введите БИК',
            fioHelp: 'Введите ФИО',
            userPhone: 'Номер телефона пользователя',
            userPhoneHelp: 'Введите Номер телефона пользователя',
            email: 'Электронный адрес',
            emailHelp: 'Введите Электронный адрес',
            iin: 'ИИН',
            bin: 'БИН',
            binHelp: 'Введите БИН',
            iinHelp: 'Введите ИИН',
            position: 'Должность',
            positionHelp: 'Введите Должность',
            signer: 'Подписант',
            title: 'Название',
            enterTitle: 'Введите название',
            addEmployee: 'Добавление сотрудников',
            selectUser: 'Выберите пользователя',
            identityDocument: 'Документ подтверждающий личность',
            address: 'Адрес',
            addressHelp: 'Введите адрес пользователя',
            enterAddressActual: 'Введите фактический адрес',
            companyName: 'Название комании',
            shortCompanyName: 'Короткое название компании',
            counterpartyFio: 'ФИО контрагента',
            counterpartyPhone: 'Номер телефона контрагента',
            counterpartyBin: 'БИН контрагента',
            counterpartyBic: 'БИК контрагента',
            counterpartyIik: 'ИИК контрагента',
            counterpartyEmail: 'Email контрагента',
            counterpartyPosition: 'Должность контрагента',
            counterpartyAddressActual: 'Адрес фактический контрагента',
            companyType: 'Тип компании',
            selectCompanyType: 'Выберите тип компании',
            countrepartyCompanyNames: 'Наименование компании контрагента',
            enterCompanyName: 'Введите наименование компании',
            productName: 'Название Продукта',
            enterProductName: 'Введите Название Продукта',
            maxDays: 'Максимальный срок (в днях)',
            maxDaysAmount: 'Введите кол-во дней',
            firstTranchePercent: 'Первый транш (процент от суммы)',
            tranchePercent: 'Введите значение в процентах (0-100)',
            stagesOfTranches: 'Этапы следующих траншей',
            comissionFeeTaxFinance: 'Ставка комиссии за финансирование (фиксированная)',
            supplierRate: 'Ставка Поставщика', 
            supplierComissionType: 'Тип комиссии Поставщика',
            selectComissionType: 'Выбрите тип комиссии',
            holdOutside: 'Удержание cнаружи',
            holdInside: 'Удержание внутри',
            buyersRate: "Ставка Покупаетля",
            buyerComissionType: "Тип комиссии Покупателя",
            amountOfDailyFeeSurchage: 'Размер ежедневной надбавки комиссии за финансирование',
            contractTemplates: 'Шаблон Договоров',
            debtorAgreement: 'Договор дебитора',
            creditorAgreement: 'Договор кредитора',
            commission: 'Комиссия',
            clarification: 'Обоснование',
            enterClarification: 'Введите обоснование',
            financeFeeRate: 'Ставка комиссии за финансирование',
            maximumFinancingPeriod: 'Максимальный срок выдачи', 
            forSupplier: 'Для поставщика',
            comissionType: 'Тип комиссии',
            amountOfDailyCommissionIncrease: 'Размер ежедневной надбавки комиссии',
            forBuyer: 'Для покупателя', 
            procedureForPaymentOfTranches: 'Порядок выплат траншей (от суммы договора)',
            firstTranche: 'Первый транш',
            untilTheDay: 'До дня',
            tranche: 'Транш',
            percentOfSum: 'Процент от суммы',
            untilWhatDay: 'До какого дня',
            debtor: 'Дебитора',
            creditor: 'Кредитора',
            status: 'Статус',
            enterSegmentName: 'Введите Название сегмента',
            shortName: 'Короткое Название', 
            selectCompany: 'Выберите компанию',
            limitStatus: 'Статус лимита',
            changedDate: 'Дата изменения',
            createdDate: 'Дата создания',
            startDate: 'Начало действия',
            client: 'Клиент',
            limitDateStart: 'Дата и время начала действия лимита',
            enterTotalLimit: 'Введите Общий лимит',
            dateOfOrderCreated: 'Дата приказа/постановления',
            enterDealLimit: 'Введите Лимит на сделку',
            gender: 'Гендер',
            genderHelp: 'Выберите Гендер',
            bank: "Банк бенефициара",
            bankHelp: 'Выберите Банк',
            factorSum: 'Факторинговая сумма',
        },
        navigation: {
            profileData: 'Профильные данные',
            accesses: 'Доступы',
            profile: 'Профиль',
            companySettings: 'Настройки компании',
            title: 'Управление',
            personDirectories: 'Справочники лиц',
            clients: 'Клиенты',
            clientsAdd: 'Создание клиента',
            listOfClients: 'Cписок клиентов',
            products: 'Продукты',
            product: 'Продукт',
            limits: 'Лимиты',
            cards: 'Карты',
            deals: 'Сделки',
            accounting: 'Учет',
            out: 'из',
            quantity: 'Количество',
            employees: 'Сотрудники',
            counterparties: 'Контрагенты',
            counterparty: 'Контрагент',
            addCounterparties: 'Добавление контрагентов',
            addCounterparty: 'Добавить контрагента',
            changeCounterparty: 'Изменить контрагента',
            productList: 'Список продуктов',
            factoringConditions: 'Факторинговые условия',
            changeSement: 'Изменение сегмента - ',
            addingCompanies: 'Добавление компаний.',
            limtis: 'Лимиты',
            limtiList: 'Список лимитов',
            list: 'Список',
            orders: 'Приказы',
            all: 'Все',
            orderList: 'Список приказов',
            active: 'Активные',
            applications: 'Заявки',
            logOut: 'Выйти',
            debtManagement: 'Управление портфелем',
            eSigning: 'Электронное подписание',
            smartContractsSoon: 'Смарт-Контракты (скоро)',
            reporting: 'Отчётность',
            analytics: 'Аналитика',
            integrations: 'Интеграции',
            support: 'Поддержка',
            editProductTemplate: 'Изменить шаблон',
            scoring: 'Скоринг',
            riskMonitoring: 'Мониторинг рисков',
        },
        limit: {
            company: 'Лимит на компанию',
            status: 'Статус лимита',
        },
        smartContract: {
            title: 'Smart-контракты', 
            tradeTransactionFor: 'для торговых сделок',
            financeTransaction: 'для сделок по финансированию',
        },
        deal: {
            signDoc: 'Подписание документа',
            signDocBuyer: 'Подпишите договор с покупателем',
            signDocSupplier: 'Подпишите договор с поставщиком',
            actsAs: 'Выступает в роли',
            title: 'Сделка',
            info: 'Информация по сделке',
            status: 'Статус сделки',
            contractPeriod: 'Срок действия договора (в днях)',
            monetaryClaim: 'Денежное требование',
            factoringAmount: '(сумма факторинга)',
            factoringFeeForTheBuyer: 'Сумма комиссии покупателя',
            factoringFeeForTheSupplier: 'Сумма комиссии поставщика', 
            guarantee: 'Гарант', 
        },
        company: {
            title: 'Компания',
            name: 'Название Компании',
            totalLimit: 'Общий лимит',
            dealLimit: 'Лимит на сделку',
            number: 'Номер',
            сlarification: 'Обоснование',
            orderDate: 'Дата приказа',
            type: 'Тип компании',
        },
        cards: {
            title: 'Карты',
            banks: 'Банки',
        },
        actions: {
            dontClosePage: "Don't close the page",
            sign: 'Подписать',
            systemChanged: 'Изменено системой',
            template: 'Шаблон',
            file: 'Файл',
            enterClientName: 'Введите ФИО клиента',
            enterVariablesName: 'Введите название переменных',
            search: 'Поиск',
            save: 'Сохранить',
            updating: 'Обновляем',
            create: 'Создать',
            update: 'Обновить',
            addUser: 'Добавить пользователя',
            edit: 'Редактировать',
            editUser: 'Редактировать пользователя',
            approve: 'Утвердить',
            reject: 'Отклонить',
            approved: 'Утвержден',
            rejected: 'Отклонен',
            view: 'Просмотреть',
            add: 'Добавить',
            archive: 'Архивировать',
            unArchive: 'Разархивировать',
            download: 'Скачать',
            downloading: 'Скачивается',
            generate: 'Сгенерировать',
            notLoaded: 'Не загружен',
            createProduct: 'Создать продукт',
            updateProduct: 'Обновить продукт',
            change: 'Изменить',
            submitAction: 'Подтвердите действие',
            submit: 'Подтвердить',
            register: 'Зарегистрировать',
            cancel: 'Отменить',
            submitData: 'Данные были обновлены',
            select: 'Выберите',
            selectSegment: 'Выберите сегмент',
            productStatus: 'Статус продукта',
            attachCompanies: 'Прикрепить компании', 
            createSegment: 'Создать сегмент',
            updateSegment: 'Обновить сегмент',
            сonfirm: 'Подтвердить',
            сancel: 'Отменить',
            companyAdded: 'Компании были добавлены',
            close: 'Закрыть',
            stay: 'Остаться',
            actions: 'Действия',
            block: 'Заблокировать',
            activate: 'Активировать',
            updated: 'Изменено',
            createOrder: 'Создать приказ',
            choose: 'Выбрать',
            makeRequest: 'Оформить заявку',
            submitYourApplication: 'Оставить заявку',
            leaveRequestBCC: 'Оставить заявку для дебитовой карты БЦК.',
            shareIIN: 'Поделиться ИИН',
            sharePhoneNumber: 'Поделиться Номером телефоном',
            sendingRequest: 'Отправляем заявку',
            variables: 'Переменные',
        },
        errors: {
            percentageOfSum: 'Сумма всех траншей, не может быть больше 100',
            dataEmpty: 'Данных нету',
        },
        modalMessages: {
            valueCopyError: 'Ошибка при копирование',
            valueWasCopied: 'Значение было скопировано',
            chooseTemplateUser: 'Клиент не был выбран',
            templateNotFound: 'Шаблон не был найден',
            activateProduct: 'Вы собираетесь сделать продукт видимым, для пользователей',
            archivateProduct: 'Вы собираетесь архивировать продукт', 
            activateProductDouble: 'Вы собираетесь активировать продукт повторно',
            submitDeleteSegment: 'Подтвердите удаление сегмента - ',
            deleteSegmentMessage: 'Удаляя сегмент, вы закроете доступ к созданным продуктам в сегменте.',
            waitLastOperation: 'Дождитесь прошлой операции',
            submitActivationLimit: 'Подтвердите активацию лимита', 
            submitRegisterDeal: 'Подтвердите регистрацию сделки', 
            submitApproveDeal: 'Подтвердите проверку сделки', 
            submitCancelDeal: 'Подтвердите отмену сделки',
            submitBlockingnLimit: 'Подтвердите блокировку лимита',
            submitOrderLimit: 'Подтвердите одобрение приказа, на изменение лимита',
            cancelOrderLimit: 'Подтвердите отмену приказа, на изменение лимита',
            limitTrasancion: 'Лимит на сделку не может больше, чем общий лимит',
            submitSendingRequest: 'Нужно подтвердить отправку данных',
            submitDeleteCountreparty: 'Подтвердите удаление контрагента',
            usersWasAdded: 'Пользователи были добавлены',
            userNotFound: 'Пользователь не найден',
            userProfileUpdate: 'Профиль был обновлен',
            companyProfileUpdated: 'Профиль компании успешно изменен',
            userPasswordUpdated: 'Пароль был изменен',
        },
        textNumbers: {
            first: 'Первый',
            second: 'Второй',
            third: 'Третий',
            fourth: 'Четвертый',
            fifth: 'Пятый',
            sixth: 'Шестой',
            seventh: 'Седьмой',
            eighth: 'Восьмой',
            ninth: 'Девятый',
            tenth: 'Десятый',
        },
        docx: {
            buyerFile: 'Договор с покупателем',
            supplierFile: 'Договор с поставщиком',
            cantCancelDocx: 'Подписанный документ нельзя будет отменить',
            wasSigned: 'Документ был подписан',
        },
        sign: {
            signContractWith: "Подпишите договор с",
            buyerContract: "Договор покупателя",
            supplierContract: "Договор поставщика",
            actionWasCancelled: 'Действие отменено пользователем',
            ncalyerConnectCancelled: 'Не удалось подключиться к NCALayer',
        },
    },
}

function getLng() {
    let lng = localStorage.getItem('acceptLanguage');
    if (['en', 'ru',].indexOf(lng) == -1) lng = 'en';
    return lng;
}

export default createI18n({
    locale: getLng(),
    fallbackLocale: 'en', // 'ru'
    legacy: false,
    reloadOnLanguageChange: true,
    messages
})
