<template>
  <div class="">

    <div class="app-page-title flex items-center">
      <div class="flex items-center justify-between inl-100">
        <div class="flex items-center">
          <div class="page-title-wrapper margin-r-30">
            <div class="page-title-heading">
              <div class="page-title-icon">
                <img src="/assets/images/icons/business_building.svg" alt="" />
              </div>
              <div>
                {{ t('navigation.clients' )}}
                <div class="page-title-subheading">{{ t('navigation.listOfClients' )}}</div>
              </div>
            </div>
          </div>
          <Search :startVal="searchVal" />
        </div>

        <div>
          <router-link
            :to="{
              name: 'CreateCompany',
            }"  
            v-slot="{ href, navigate}">
            <div 
              :href="href"
              @click="navigate"
              class="relative add-button rounded-button pointer">
              <svg class="center-block w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path class="text-white" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"></path>
              </svg>
            </div>
          </router-link>
        </div>

      </div>
    </div>

    <div class="fix-templ-row">
      <div class="col-lg-12">
        <div class="card fix-table-row">
          <div class="card-body relative">
            <AdminTable
              :navData="{
                navField: 'name_short',
                urlName: 'ClientCompanyId',
                urlKey: 'companyUUID',
                key: 'uuid',
              }"
              :currentPage="currentPage"
              :postPerPage="postPerPage"
              :keysOrder="keysOrder"
              :fieldWidth="fieldWidth"
              :headerKeys="headerKeys"
              :bodyData="bodyData"
            />
            <TableNav
              :currentPage="currentPage"
              :navItems="navItems"
              :allCount="allCount"
              style="width: 330px;"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import AdminTable from '@/components/Admin/Table.vue';
import TableNav from '@/components/Admin/TableNav.vue';
import Search from '@/components/Admin/Search.vue';
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
import axios from 'axios';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
  ],
  components: {
    AdminTable,
    TableNav,
    Search,
  },
  data() {
    return {
      keysOrder: [
        'name_short',
        'bin',
        'created_at',
      ],
      fieldWidth: {
        name_short: 50,
        bin: 20,
        created_at: 25,
      }, 
      currentPage: 1,
      searchVal: null,
      headerKeys: {},
      bodyData: [],
      navItems: [],
      allCount: 1,
      postPerPage: 50,
    };
  },
  mounted() {
    this.loadData();
  }, 
  watch: {
    $route(to, from) {
      if (to.name != from.name) {
        this.currentPage = 1;
      }
      this.currentPage = this.$route.query.page;
      this.searchVal = this.$route.query.q;
      this.loadData();
    },
  },
  beforeMount() {
    this.currentPage = this.$route.query.page || 1;
    this.searchVal = this.$route.query.q || null;
  }, 
  methods: {
    updateNavItems(count) {
      const countData = count;
      const pages = Math.ceil(countData / this.postPerPage);
      this.navItems = [];
      for (let index = 0; index < pages; index++) {
        this.navItems.push(index + 1);
      }
      if (pages == 0) {
        this.navItems.push(1);
      }
    },
    setSearch(val) {
      this.searchVal = val; 
      this.loadData();
    }, 
    async loadData() {
      await axios({
        method: "GET",
        params: {
          p: this.currentPage,
          q: this.searchVal,
        },
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.factor.company,
      }).then((e) => {
        const data = e.data.data
        this.headerKeys = data.results.keys;
        this.bodyData = data.results.page;
        this.allCount = data.count;
        this.updateNavItems(data.count);
      }).catch((e) => {
        console.log(e);
        const data = e.response.data;
        if(e.response.status == 404) {
          this.openErrorModal({
            title: '404',
            description: data.errors.detail,
          });
          this.bodyData = [];
          this.allCount = 0;
          this.updateNavItems(0);
        }
      });
    },
  }
};
</script>