<template>
  <div>

    <table v-if="bodyData.length > 0" class="mb-0 table table-striped">
      <thead>
        <tr>
          <th class="inl-5">#</th>
          <th 
            :class="[
              getPageWidth(headerKeys[key].name)
            ]"
            v-for="(key, ind) in keysOrder" :key="ind">
            {{ headerKeys[key] ? headerKeys[key].title : "" }}
          </th>
          <th class="inl-10 text-center">{{ t('actions.actions') }}</th>
        </tr>
      </thead>
    </table>

    <div class="table-scroll scroll-styling">
      <table v-if="bodyData.length > 0" 
        class="mb-0 table table-striped">
        <tbody>
          <tr
            class="align-middle relative"
            v-for="(data, ind) in bodyData" :key="ind">
            <th 
            class="inl-5" 
            scope="row">
              {{ ind+1+ ((currentPage-1) * postPerPage) }}
            </th>
            <td 
              :class="[
                getPageWidth(headerKeys[key].name)
              ]"
              v-for="(key, keyInd) in keysOrder" :key="keyInd">
              <div v-if="navData && navData.navField == headerKeys[key].name">
                <router-link
                  :to="getNavData(data,)"  
                  v-slot="{ href, navigate}">
                  <div 
                    :href="href"
                    @click="navigate"
                    class="font-bold">
                      {{ getVal(headerKeys[key].name, headerKeys[key].type, data) }} <br>
                  </div>
                </router-link>
              </div>
              <div v-else>
                {{ getVal(headerKeys[key].name, headerKeys[key].type, data) }} <br>
              </div>
            </td>
            <td 
              class="inl-10">
              <div 
                class="flex flex-col justify-center">
                <button
                  v-if="data.is_submit == null"
                  @click="() => {
                    submitModalData(ind, data, true);
                  }"
                  type="button"
                  class="margin-bt-10 btn btn-success"
                  >{{ t('actions.approve') }}</button>
                <button
                  v-if="data.is_submit == null"
                  @click="() => {
                    submitModalData(ind, data, false);
                  }"
                  type="button"
                  class="btn btn-danger"
                  >{{ t('actions.reject') }}</button>
                <span class="text-center" v-else-if="data.is_submit">
                  {{ t('actions.approved') }}
                </span>
                <span class="text-center" v-else-if="!data.is_submit">
                  {{ t('actions.rejected') }}
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import { getValueFromObject, beautifyInteger, } from '@/utils/base';
import { formShowError } from '@/utils/erros';
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
import axios from 'axios';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
    'openSubmitModal',
    'closeSubmitModal',
  ],
  data() {
    return {
      formKeys: [],
      showData: false,
    };
  },
  methods: {
    getNavData(data,) {
      const params = {};
      params[this.$props.navData.urlKey] = data[this.$props.navData.key];
      return {
        name: this.$props.navData.urlName,
        params,
      };
    },
    getPageWidth(key) {
      let val = this.fieldWidth[key];
      if (!val) return "";
      return "inl-" + val;
    },
    getVal(key, type, data,) {
      const val = getValueFromObject(data, key, type,)
      if (!val) return "-"
      if(type == 'int' || type == 'float') return beautifyInteger(val);
      return val;
    },
    submitModalData(ind, data, isSbumit) {
      if(this.isUpdate) {
        this.toggleSmModal({
          description: this.t('modalMessages.waitLastOperation'),
          isAlert: true,
        });
        return false;
      }
      this.openSubmitModal({
        title: isSbumit ? 
          this.t('modalMessages.submitOrderLimit') :
          this.t('modalMessages.cancelOrderLimit'),
        description: ``,
        btnFirst: this.t('actions.submit'),
        btnSecond: this.t('actions.cancel'),
        btnFirstColor: isSbumit ? 'green': 'red',
      }, () => {
        this.submitData(ind, data, isSbumit)
        this.closeSubmitModal();
      });
    },
    async submitData(ind, data, isSubmit) {
      let url = API_FACTOR.limit.order;
      if(isSubmit) {
        url += data.uuid + '/submit/';
      } else {
        url += data.uuid + '/un_submit/';
      }
      this.isUpdate = true;
      await axios({
        url,
        method: "POST",
        headers: HEADERS_TOKEN_JSON(),
      }).then(() => {     
        this.isUpdate = false;   
        this.$props.updateData(ind, {
          is_submit: isSubmit,
        });
      }).catch((e) => {
        this.isUpdate = false;
        formShowError.bind(this)(e);
      });
    },
  },
  props: {
    updateData: {
      default: () => {},
    },
    keysOrder: {
      default: [],
    },
    fieldWidth: {
      default: {},
    },
    headerKeys: {
      default: {},
    },
    addIndex: {
      default: 0,
    },
    currentPage: {
      default: 1,
    },
    postPerPage: {
      default: 0,
    },
    bodyData: {
      default: [],
    },
    navData: {
      default: {},
    },
  },
};
</script>