import { API_URL } from './uri.dev'

const API_AUTH = {
  login: `${API_URL}/api/auth/login/`,
  checkToken: `${API_URL}/api/auth/check_token/`,
  activate: `${API_URL}/api/register/company/activate/`,
}

const API_FACTOR = {
  client: `${API_URL}/api/client/`,
  clientCompany: `${API_URL}/api/client/company/`,
  user: `${API_URL}/api/factor/profile/user/`,
  company: `${API_URL}/api/factor/profile/company/`,
  factor: {
    company: `${API_URL}/api/factor/company/`,
    createCompanyUser: `${API_URL}/api/factor/company/user/`,
    companyForm: `${API_URL}/api/factor/company/forms/`,
  },
  consts: {
    gender: `${API_URL}/api/factor/consts/gender/`,
  },
  limit: {
    base: `${API_URL}/api/factor/company/limits/`,
    order: `${API_URL}/api/factor/company/limits/orders/`,
  }, 
  products: {
    list: `${API_URL}/api/factor/product/`,
    form: `${API_URL}/api/factor/product/forms/`,
    template: `${API_URL}/api/factor/product/docx/template/`,
    templateFile: `${API_URL}/api/factor/product/docx/file/`,
    signerTemplateUsers: `${API_URL}/api/factor/product/docx/variables/users/`,
    variables: `${API_URL}/api/factor/product/docx/variables/data/`,
  },
  segment: {
    base: `${API_URL}/api/factor/segment/`,
  }, 
  segmentCompany: {
    base: `${API_URL}/api/factor/segment/company/`,
  },
  deals: {
    base: `${API_URL}/api/factor/deals/`,
    sign: `${API_URL}/api/factor/deals/sign/`,
    clientSign: `${API_URL}/api/client/deals/sign/`,
  }, 
}

function getLng() {
  let lng = localStorage.getItem('acceptLanguage');
  if (['en', 'ru',].indexOf(lng) == -1) lng = 'en';
  return lng;
}

const HEADERS = () => {
  return {
    'Accept-Language': getLng(), 
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  };
}

const HEADERS_TOKEN = () => {
  return {
    'Accept-Language': getLng(), 
    'Authorization': 'Token ' + localStorage.getItem('user_token'),
    'Accept': 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded'
  };
}

const HEADERS_TOKEN_JSON = () => {
  return {
    'Accept-Language': getLng(), 
    'Authorization': 'Token ' + localStorage.getItem('user_token'),
    'Accept': 'application/json',
  };
}

const HEADERS_JSON = () => {
  return {
    'Accept-Language': getLng(), 
    'Accept': 'application/json',
  };
}

const HEADERS_TOKEN_JSON_FILE = () => {
  return {
    'Accept-Language': getLng(), 
    'Authorization': 'Token ' + localStorage.getItem('user_token'),
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  };
}

export { 
  API_AUTH, HEADERS, API_FACTOR,
  HEADERS_TOKEN, HEADERS_TOKEN_JSON,
  HEADERS_TOKEN_JSON_FILE, HEADERS_JSON,
}
