<template>
  <div>
    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
      <thead class="text-xs text-gray-700 uppercase">
        <tr>
          <th style="width: 30px" class="text-left py-3">#</th>
          <th class="text-left py-3">{{ t('form.percentOfSum') }}</th>
          <th class="text-left py-3">{{ t('form.untilWhatDay') }}</th>
        </tr>
      </thead>
      
      <tbody>
        <tr
          v-for="(el, ind) in tranchess"
          :key="ind"
          class="bg-white dark:bg-gray-800 relative"
        >
          <td
            style="width: 30px"
            class="py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white"
          ><b>{{ ind + 1 }}</b></td>
          <td
            class="py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <input
              style="width: 50px"
              v-model="el.percent"
              class="text-center text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-8 flex items-center text-sm border-gray-300 rounded border"
              @input="() => {
                handlePercentValue(ind);
                filterPercentVal(ind);
              }"
            />
          </td>
          <td
            class="py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <input
              v-model="el.day_limit"
              style="width: 50px"
              class="text-center text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-8 flex items-center text-sm border-gray-300 rounded border"
              @input="() => {
                handlePositiveInteger(ind);
              }"
            />
          </td>
          <div 
            v-if="ind > 0"
            style="right: 30px;"
            @click="() => {
              removeData(ind);
            }"
            class="center-v-block ez-red-fill-hover pointer">
            <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"/>
            </svg>
          </div>
        </tr>
      </tbody>
    </table>
    
    <div class="flex margin-l-5 margin-t-15">
      <div @click="addEmptyData" class="relative add-button indigo sm rounded-button bg-indigo-700 ">
        <svg
          class="center-block w-6 h-6 text-white"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 18 18"
        >
          <path
            class="text-white"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 1v16M1 9h16"
          ></path>
        </svg>
      </div>
    </div>

  </div>
</template>

<script>

import { checkValue0100, isPositiveInteger } from '@/utils/base';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'toggleSmModal',
  ],
  data() {
    return {
      tranchess: [
        {
          percent: null,
          day_limit: null,
        }
      ],
    };
  },
  mounted() {
    const prevData = this.$props.prevData;
    if(prevData && prevData.length > 0) {
      this.tranchess = [...prevData];
    }
  }, 
  watch: {
    prevData(to) {
      if(to && to.length >  0)
       this.tranchess = [...to];
    }, 
  }, 
  methods: {
    removeData(ind) {
      this.tranchess.splice(ind, 1);
    },
    handlePositiveInteger(ind) {
      const value  = this.tranchess[ind].day_limit;
      const isSuccess= this.isPositiveInteger(value);
      if(isSuccess) {
        this.tranchess[ind].day_limit = parseInt(this.tranchess[ind].day_limit);
        this.$props.updateData(
          this.$props.updateKey,
          this.tranchess,
        );
        return value;
      }
      this.tranchess[ind].day_limit = null;
      return null;
    },
    filterPercentVal(ind) {
      const maxVal = 100 - this.$props.transcheVal;
      for (let index = 0; index < this.tranchess.length; index++) {
        const el = this.tranchess[index];
        const nVal = maxVal - parseFloat(el.percent) || 0;
        if(nVal < 0) {
          this.toggleSmModal({
            description: this.t('errors.percentageOfSum'),
            isAlert: true,
          });
          this.tranchess[ind].percent = 0;
          return false;
        } 
      }
      return true;
    },
    handlePercentValue(ind) {
      const value  = this.tranchess[ind].percent;
      const isSuccess= this.checkValue0100(value);
      if(isSuccess) {
        this.$props.updateData(
          this.$props.updateKey, 
          this.tranchess,
        );
        this.tranchess[ind].percent = parseFloat(this.tranchess[ind].percent);
        return value;
      }
      this.tranchess[ind].percent = null;
      return null;
    },
    checkValue0100, isPositiveInteger,
    addEmptyData() {
      this.tranchess.push({
        percent: null,
        day_limit: null, 
      });
    }, 
  },
  props: {
    transcheVal: {},
    prevData: {
      default: null,
    },
    updateKey: {
      default: null,
    },
    updateData: {
      default: () => {},
    }, 
  },
};
</script>