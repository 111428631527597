<template>
  <div class="">
    <div class="app-page-title flex items-center z-100">
      <div class="flex items-center justify-between inl-100">
        <div class="flex items-center">
          <div class="page-title-wrapper margin-r-30">
            <div class="page-title-heading">
              <div class="page-title-icon">
                <img src="/assets/images/icons/package.png" alt="" />
              </div>
              <div>
                {{ t('navigation.products') }}
                <div class="page-title-subheading">{{ t('navigation.productList') }}</div>
              </div>
            </div>
          </div>
          
          <div class="margin-r-10">
            <SelectAddSearch
              v-if="currentSegmentUUID"
              placeholder="actions.selectSegment"
              nameValue="name"
              nameKey="uuid"
              canDeleteKey="isFirst"
              :deleteData="deleteDataModal"
              :updateData="segmentModal"
              :addData="segmentModal"
              :listData="filters.segments"
              :onChoosed="onSegmentChoosed"
              :defaultValue="currentSegmentUUID"
            />
          </div>
          <div>
            <SelectAddSearch
              v-if="currentSegmentUUID"
              placeholder="actions.productStatus"
              nameValue="val"
              nameKey="key"
              :canAdd="false"
              :canEdit="false"
              canDeleteKey="isFirst"
              :listData="filters.statuses"
              :onChoosed="onProductStatusChoosed"
              :defaultValue="productStatus"
            />
          </div>
        </div>

        <!-- right block -->

        <div class="flex items-center">
          <button 
            v-if="filters.segments && currentSegmentUUID != filters.segments[0].uuid"
            @click="toggleModalSegmentModal"
            class="mr-2 btn btn-primary rounded-lg">{{ t('actions.attachCompanies') }}</button>

          <button
            @click="() => {toggleProductModal();}">
            <div 
              class="relative add-button rounded-button pointer">
              <svg class="center-block w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path class="text-white" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"></path>
              </svg>
            </div>
          </button>
        </div>

      </div>
    </div>

    <div class="fix-templ-row">
      <div class="col-lg-12">
        <div class="card h-100-p scroll-styling overflow-y-scroll">
          <div class="card-body relative">
            <div class="grid lg:grid-cols-2 xl:grid-cols-3 gap-2">
                <FactorProduct 
                  v-for="(el, ind) in products" :key="ind"
                  :index="ind"
                  :toggleProductModal="toggleProductModal"
                  :updateProductStatus="updateProductStatus"
                  :product="el"
                  :toggleProductDocView="toggleProductDocView"
                />
            </div>
          </div>
        </div>
      </div>
    </div>

    <slot name="rightModal">
      <AddDeleteCompanySegment
        v-if="openSegmentCompanyModal" 
        :segmentUUID="currentSegmentUUID"
        :segmentName="getSegmentName"
        :toggleModal="toggleModalSegmentModal"
      />
      <AddUpdateSegment
        v-if="openSegmentModal"
        :changeData="changeSegmentData"
        :updateData="updateDataSegment"
        :moddalSegment="moddalSegment"
        :toggleModal="() => {
          openSegmentModal = !openSegmentModal;
        }"
      />
      <AddProductModal
        v-if="openProductModal"
        :toggleModal="toggleProductModal"
        :updateData="updateDataProduct"
        :changeData="changeDataProduct"
        :currentProductId="currentProductId"
        :segmentUUID="currentSegmentUUID"
      />
      <ProductViewDoc
        v-if="showDocViewModal"
        :docType="docType"
        :productUUID="docProductUUID"
        :toggleModal="toggleProductDocView"
        :docProductStatus="docProductStatus"
      />
    </slot>

  </div>
</template>

<script>
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import axios from "axios";
import SelectAddSearch from "@/components/Forms/SelectAddSearch.vue";
import AddUpdateSegment from '@/components/Modals/AddUpdateSegment.vue'
import AddDeleteCompanySegment from '@/components/Modals/AddDeleteCompanySegment.vue';
import AddProductModal from '@/components/Modals/Product/AddProductModal.vue';
import FactorProduct from '@/components/Elemets/Product.vue';
import { MEDIA_HOST } from '@/const/uri.dev';
import { useI18n } from 'vue-i18n';
import ProductViewDoc from '@/components/Modals/Product/ProductViewDoc.vue';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
    'openSubmitModal',
    'closeSubmitModal',
  ],
  components: {
    ProductViewDoc,
    FactorProduct,
    SelectAddSearch,
    AddUpdateSegment,
    AddProductModal,
    AddDeleteCompanySegment,
  },
  data() {
    return {
      showDocViewModal: false,
      // showDocViewModal: true,
      docProductUUID: null,
      // docProductUUID: 'ad54001f-0607-11ef-bde7-13a095b034d9',
      docType: null,
      docProductStatus: null,
      // docType: 'supplier',
      products: [],
      productStatuses: [],
      filters: [],
      productStatus: null,
      currentProductId: null,
      openProductModal: false,
      currentSegmentUUID: null,
      moddalSegment: null,
      openSegmentCompanyModal: false,
      openSegmentModal: false,
      isLoad: false,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route() {
      this.loadData();
    },
  },
  beforeMount() {
    this.currentSegmentUUID = this.$route.query.segmentUUID; 
    const val = parseInt(this.$route.query.status);
    if(isNaN(val)) {
      this.productStatus = null
    } else {
      this.productStatus = val;
    }
  },
  methods: {
    toggleProductDocView(uuid, docType, productStatus,) {
      this.docProductUUID = uuid;
      this.docType = docType;
      this.showDocViewModal = !this.showDocViewModal;
      this.docProductStatus = productStatus;
    },
    updateProductStatus(data, ind) {
      const status = data.status.key;
      let isDelete = (status != this.productStatus) && (
        this.productStatus != -1 && this.productStatus  !== null
      );
      if(isDelete) {
        this.products.splice(ind, 1);
      } else {
        this.products[ind] = {
          ...this.products[ind],
          status: data.status,
        }
      }
    }, 
    toggleProductModal(uuid) {
      if(uuid) {
        this.currentProductId = uuid; 
      }
      else {
        this.currentProductId = null; 
      }
      this.openProductModal = !this.openProductModal;
    },
    updateDataProduct(data) {
      const obj = {...data};
      const supplier_agreement = obj.supplier_agreement;
      const buyer_agreement = obj.buyer_agreement;
      if(supplier_agreement) obj.supplier_agreement = MEDIA_HOST + obj.supplier_agreement;
      if(buyer_agreement) obj.buyer_agreement = MEDIA_HOST + obj.buyer_agreement;
      this.products.push(obj);
    },
    changeDataProduct(data) {
      for (let index = 0; index < this.products.length; index++) {
        const element = this.products[index];
        if(element.uuid == data.uuid) {
          this.products[index] = {
            ...data,
            supplier_agreement: MEDIA_HOST + data.supplier_agreement,
            buyer_agreement: MEDIA_HOST + data.buyer_agreement,
          };
          break;
        }
      }
    }, 
    getSegmentName() {
      for (let index = 0; index < this.filters.segments.length; index++) {
        const element = this.filters.segments[index]; 
        if(element.uuid == this.currentSegmentUUID) return element.name;
      }
    },
    toggleModalSegmentModal() {
      this.openSegmentCompanyModal = !this.openSegmentCompanyModal
    },
    changeSegmentData(name) {
      const segments = this.filters.segments;
      for (let index = 0; index < segments.length; index++) {
        if(segments[index].uuid == this.moddalSegment) {
          segments[index].name = name;
          break;
        } 
      }
    },
    deleteDataModal(uuid, name) {
      this.openSubmitModal({
        title: this.t('modalMessages.submitDeleteSegment') + name,
        description: this.t('modalMessages.deleteSegmentMessage'),
      }, () => {
        this.deleteDataRequest(uuid)
      });
    }, 
    async deleteDataRequest(uuid) {
      await axios({
        method: "DELETE",
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.segment.base + uuid + '/',
      })
      .then((e) => {
        this.filters.segments = this.filters.segments.filter(
          (el) => {
            return el.uuid != e.data.data.uuid;
          }
        );
        this.closeSubmitModal();
        const segment = this.filters.segments[0];
        this.onSegmentChoosed(segment.uuid);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    updateDataSegment(data) {
      this.filters.segments.push(data);
      this.onSegmentChoosed(data.uuid);
    },
    segmentModal(uuid){ 
      if(typeof uuid == 'undefined') {
        this.openSegmentModal = true;
        this.moddalSegment = null;
        return;
      }
      this.moddalSegment = uuid;
      this.openSegmentModal = true;
    },
    onSegmentChoosed(val) {
      this.currentSegmentUUID = val;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          segmentUUID: this.currentSegmentUUID,
        },
      });
      this.loadData();
    },
    onProductStatusChoosed(val) {
      this.productStatus = val;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          status: this.productStatus,
        },
      });
      this.loadData();
    },
    async loadData() {
      const params = {};
      if(this.currentSegmentUUID) {
        params.segment = this.currentSegmentUUID;
      } 
      if(!this.isLoad){ 
        params['get_filter'] = 1;
      }
      if(this.productStatus !== null && typeof this.productStatus != 'undefined') {
        params['status'] = this.productStatus;
      }
      await axios({
        method: "GET",
        params,
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.products.list,
      })
        .then((e) => {
          const filters = e.data.data.filters;
          this.isLoad = true;
          if(filters) {
            this.filters = filters;
            this.currentSegmentUUID = this.filters.segment_uuid;
            this.productStatuses = this.filters.statuses;
          }
          this.products = e.data.data.products;
        })
        .catch((e) => {
          if([404].indexOf(e.response.status) != -1)  {
            this.openErrorModal({ 
              title: '404',
              description: this.t('errors.dataEmpty'),  
            });
          }
        });
    },
  },
};
</script>