<template>
  <div>
    <div class="margin-bt-10">
        <label class="">{{ t('form.ru') }}</label>
        <input 
          :name="nameKey + 'kz'"
          @change="dataChanged"
          v-model="obj.kz"
          placeholder="" 
          class="form-control">
    </div>
    <div class="margin-bt-10">
        <label class="">{{ t('form.en') }}</label>
        <input 
          :name="nameKey + 'ru'"
          @change="dataChanged"
          v-model="obj.ru"
          placeholder="" 
          class="form-control">
    </div>
    <div class="margin-bt-10">
        <label class="">{{ t('form.kz') }}</label>
        <input 
          v-model="obj.en"
          :name="nameKey + 'en'"
          @change="dataChanged"
          placeholder="" 
          class="form-control">
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
      obj: {
        ru: null,
        en: null,
        kz: null,
      },
    };   
  },
  mounted() {
    this.obj = {...this.$props.formData};
  },
  watch: {
    formData(to) {
      this.obj = {...to};
    }, 
  }, 
  methods: {
    dataChanged() {
      this.$props.setData(this.$props.nameKey, this.obj);
    },
  },
  props: {
    formData: {},
    setData: {},
    nameKey: {
      default: null,
    },
  },
}
</script>