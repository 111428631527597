<template>
  <div>
    <div class="page-header min-vh-75">
      <div class="auth-container">
        <div class="flex auth-row">
          <div
            class="col-xl-3 col-lg-4 col-md-5 d-flex flex-column mx-auto relative"
          >
            <div 
            class="card-plain mt-8 center-block">
              <div 
              style="width: 435px;"
              class="card-header pb-0 text-left bg-transparent">
                <div class="flex flex-col inl-100">
                  <div class="flex flex-row justify-end margin-bt-10">
                    <select
                        @change="setLng"
                        style="width: 65px; padding-left: 8px!important;"
                        class="f-14 rounded-lg bg-gray-50 border bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 pl-2" >
                        <option :selected="acceptLanguage == 'ru'"  value="ru">RU</option>
                        <option :selected="acceptLanguage == 'en'"  value="en">EN</option>
                    </select>
                  </div>
                  <div class="margin-bt-30 text-center">
                    <h3 class="font-bold text-info text-gradient inl-100">
                      {{ t('base.appName')}}
                    </h3>
                    <div class="font-normal f-14 s-title margin-bt-45">
                      {{ t('auth.login.condition')}}
                    </div>
                  </div>
                </div>
              </div>

              <div 
                style="width: 435px; position: relative; z-index: 10;"
                class="card-body">
                <form 
                  @submit.prevent="formSubmit" role="form">
                  <label class="font-bold">{{ t('form.phoneHelp') }}</label>
                  <div class="mb-3">
                    <input
                      v-model="phone"
                      type="text"
                      class="form-control"
                      :placeholder="t('form.phoneHelp')"
                    />
                  </div>
                  <label class="font-bold">{{ t('form.password') }}</label>
                  <div class="mb-3">
                    <input
                      v-model="password"
                      type="password"
                      class="form-control"
                      :placeholder="t('form.passwordHelp')"
                    />
                  </div>

                  <div class="text-center">
                    <button
                      type="submit"
                      class="btn bg-gradient-info f-20 w-100 mt-4 mb-0"
                    >
                      {{ t('auth.login.btn') }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="auth-bg relative inl-60">
            <div class="oblique top-0 h-100 d-md-block d-none me-n8">
              <div
                class="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                style="background-image: url('/assets/images/curved6.png')"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { useI18n } from 'vue-i18n'
import { API_AUTH } from '@/const/uri';
import axios from 'axios';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  setup() {
    const { t } = useI18n()
    return { t }
  },
  inject: [
    'toggleErrorModal',
  ],
  data() {
    return {
      phone: null, 
      password: null,
    };
  },
  computed: {
    ...mapState(['acceptLanguage',]),
  },
  methods: {
    ...mapMutations(['tokenChecked', 'setUserData', 'setFactorUserData', ]),
    ...mapActions(['changeLanguage',]),
    getLng() {
      return localStorage.getItem('acceptLanguage') || 'en' + ' ' + this.$i18n.global.locale;
    },
    setLng(e) {
      const value = e.target.value;
      this.changeLanguage(value);
      this.$i18n.locale = value;
    },
    async formSubmit() {
      await axios({
        method: "POST",
        data: {
          phone: this.phone,
          password: this.password,
        },
        url: API_AUTH.login,
      })
        .then((e) => {
          const data = e.data.data;
          localStorage.setItem("user_token", data.token);
          localStorage.setItem("token_expired", data.token_expired);
          this.setUserData(data.user);
          this.setFactorUserData(data.factor_user);
          this.tokenChecked();
          this.$router.push({
            name: "ClientIndex",
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.response.status == 404)  {
            this.toggleErrorModal({ 
              title: '404',
              description: this.t('modalMessages.userNotFound'),
            });
          }
        });
    },
  },
  props: {},
};
</script>
