function getZeroInt(val) {
    if (val < 10) return '0' + val;
    return val + '';
}

function getSelectOptions(object, type) {
    let options = [];
    for (const key in object) {
        var nKey = null;
        if(type != 'fk') {
            if (isNaN(parseInt(key))) {
                nKey = key;
            } else {
                nKey = parseInt(key);
            }
        }
        if (Object.hasOwnProperty.call(object, key)) {
            const element = object[key];
            options.push({
                value: nKey,
                text: element,
            }); 
        }
    }
    return options;
}

function getSelectOptionsBase(listObj, key, title, isMulTi=false,) {
    let options = [];
    listObj.forEach(element => {
        options.push({
            text: !isMulTi ? element[title] : element[title].ru,
            value: element[key],
        });
    });   
    return options; 
}

function getSelectOptionsObj(obj) {
    let options = [];
    for (const key in obj) {
        options.push({
            text: obj[key],
            value: parseInt(key),
        });
    }
    return options; 
}

function getCurrentYear() {
    const date = new Date();
    const cMonth = date.getMonth() + 1;
    if (cMonth < 8) return date.getFullYear() + 1;
    return date.getFullYear();
}

function numberWithSpaces(x) {
    return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function formatDate(date, format) {
    if (!date) return;
    if (format == 'date' || format == 'datetime') {
        return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();
    }
}

function dateFormatPicker(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    return `${formattedDay}.${formattedMonth}.${year}`;
}

function normalDivision(value, precision) {
    let res = Math.round(value / precision * 10);
    res = parseInt(res) / 10;
    if(res == parseInt(res)) return res;
    return res;
}

function normalRound(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

function isProxyForObject(data) {
    return !!data && typeof data === 'object' && !!(Proxy.revocable(data, {}).proxy) && !(data instanceof File);
}

function getObjDataNotNull(objData) {
    const obj = {};
    for (const key in objData) {
        const val = objData[key];
        console.log('val:', val, key,); 
        if (typeof val != 'undefined' && val !== null) {
            obj[key] = val;
            if (isProxyForObject(val)) {
                obj[key] = JSON.stringify(val);
            } else {
                obj[key] = val;
            }
        }
    }
    return obj
}

function checkValue0100(value) {
    const positiveNumberRegex = /^(?:\d*\.?\d+|\d+\.?)$/;
    if (!positiveNumberRegex.test(value)) return false;
    const num = parseFloat(value);
    if (num < 0) return false;
    if (num <= 100) return true;
    return false;
}

function isPositiveInteger(str) {
    const positiveNumberRegex = /^(?:\d*\.?\d+|\d+\.?)$/;
    if (positiveNumberRegex.test(str)) {
        const num = parseFloat(str);
        return num >= 0;
    }
    return false;
}

function getCorrectEndingDay(number) {
    if (number > 10 && [11, 12, 13, 14].includes(number % 100)) return 'дней';
    const last_num = number % 10;
    if (last_num == 1) return 'день';
    if ([2, 3, 4].includes(last_num)) return 'дня';
    if ([5, 6, 7, 8, 9, 0].includes(last_num)) return 'дней';
}

function getNumberName(number) {
    let numberNames = [
        'textNumbers.first',
        'textNumbers.second',
        'textNumbers.third',
        'textNumbers.fourth',
        'textNumbers.fifth',
        'textNumbers.sixth',
        'textNumbers.seventh',
        'textNumbers.eighth',
        'textNumbers.ninth',
        'textNumbers.tenth',
    ];
    return numberNames[number - 1];
}

function beautifyInteger(num) {
    if(!num) return '';
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function formatDateTime(dateStr) {
    const date = new Date(dateStr);
    if(isNaN(date)) return dateStr;
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const val = `${formattedDay}.${formattedMonth}.${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    return val;
}

function getBoolVal(val) {
    if (typeof val == 'undefined' || val === null) return '-';
    if (val) return 'Да';
    return 'Нет';
}

function getValueFromObject(obj, keys, type) {
    let keyArr; 
    if(typeof keys == 'string') {
        keyArr = keys.split('.');
    } else {
        keyArr = keys;
    }
    if (keyArr.length === 1) {
        if (type == "str" || type == "text" || type == "image") return obj[keyArr[0]];
        if (type == "choice") return obj[keyArr[0]].val;
        if (type == 'json') return obj[keyArr[0]].ru;
        if (type == 'datetime') {
            return formatDateTime(obj[keyArr[0]]);
        }
        if (type == 'bool') return getBoolVal(obj[keyArr[0]]);
        return obj[keyArr[0]];
    }
    const currentKey = keyArr.shift();
    return getValueFromObject(obj[currentKey], keyArr, type);
}

function dateFormatPickerM(dates) {
    const currentDate = dates[0];
    const nDate = dates[1];
    if (!currentDate) return '';
    const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
    let res = currentDate.toLocaleDateString('ru-RU', options);
    if (!nDate) return res;
    res += (' - ' + nDate.toLocaleDateString('ru-RU', options));
    return res;
}

function getCurrentLng() {
    let lng = localStorage.getItem("acceptLanguage");
    if (["en", "ru"].indexOf(lng) == -1) lng = "en";
    return lng;
}

export { 
    beautifyInteger, dateFormatPickerM,
    getCorrectEndingDay, getNumberName,
    checkValue0100, isPositiveInteger,
    normalDivision, normalRound,
    dateFormatPicker, formatDate,
    getZeroInt, getSelectOptions, 
    getCurrentYear, getSelectOptionsBase, 
    numberWithSpaces, getSelectOptionsObj,
    getObjDataNotNull, getValueFromObject,
    formatDateTime, getCurrentLng,
};