<template>
  <div 
    style="overflow: hidden;"
    class="docx-template-block margin-t-10">
    <div class="flex items-center max-w-sm mx-auto">
      <label for="simple-search" class="sr-only">{{
        t("actions.search")
      }}</label>
      <div class="relative w-full">
        <input
          v-model="searchValue"
          type="text"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          :placeholder="t('actions.enterVariablesName')"
          required
        />
      </div>
      <button
        type="submit"
        class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        <svg
          class="w-4 h-4"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 20"
        >
          <path
            class="text-white"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
          />
        </svg>
        <span class="sr-only"></span>
      </button>
    </div>

    <div 
      class="relative h-100-p variable-list margin-t-30">
      <div
        v-if="isLoading"
        class="h-100-p flex justify-center items-center"
      >
        <img
          src="/assets/images/loading/loader-black-rounded.svg"
          alt=""
        />
      </div>

      <div 
        v-else
        class="relative table-templ-height-w shadow-md sm:rounded-lg">

        <table
          class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
        >
          <thead
            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
          >
            <tr>
              <th class="word-break inl-5 text-left"><div class="px-2 py-3">#</div></th>
              <th class="word-break inl-25 text-left"><div class="px-2 py-3">{{ t('form.variable') }}</div></th>
              <th class="word-break inl-35 text-left"><div class="px-2 py-3">{{ t('form.description') }}</div></th>
              <th class="word-break inl-35 text-left"><div class="px-2 py-3">{{ t('form.meaning') }}</div></th>
            </tr>
          </thead>
        </table>
        
        <div class="table-templ-height scroll-styling overflow-y-scroll">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <tbody>
              <tr
                @click="() => {
                  copyVal('{' + el.key + '}');
                }"
                v-for="(el, ind) in getVariables" :key="ind"
                class="pointer odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
              >
                <th class="word-break inl-5 font-medium">
                  <div class="px-2 py-3">{{ ind+1 }}</div>
                </th>
                <td class="word-break inl-25">
                  <div class="px-2 py-3"><b>{{ '{' + el.key + '}' }}</b></div>
                </td>
                <td class="word-break inl-35">
                  <div class="px-2 py-3">{{ el.description  }}</div>
                </td>
                <td class="word-break inl-35">
                  <div class="px-2 py-3">{{ el.value }}</div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
import { useI18n } from "vue-i18n";
import axios from 'axios';

export default {
  inject: [
    'toggleSmModal',
    'openErrorModal',
    'openSubmitModal',
    'closeSubmitModal',
  ],
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      isLoading: true,
      variables: [],
      searchValue: null,
    };
  },
  computed: {
    getVariables() {
      if(this.searchValue == null) return this.variables;
      const searchVal = this.searchValue.trim().toLocaleLowerCase();
      return this.variables.filter((el) => {
        // const value = el.value
        // const pattern = new RegExp();
        // const pattern1 = new RegExp(el.description.toLocaleLowerCase());
        // const pattern2 = new RegExp((el.value  + '').toLocaleLowerCase());
        return (
          el.key.toLocaleLowerCase().includes(searchVal)
          || el.description.toLocaleLowerCase().includes(searchVal)
          || (el.value  + '').toLocaleLowerCase().includes(searchVal)
          // pattern.test(searchVal.toLocaleLowerCase()) 
          // || pattern1.test(searchVal.toLocaleLowerCase())
          // || pattern2.test(searchVal.toLocaleLowerCase())
        );
      });
    },
  }, 
  mounted() {
    this.loadData();
  },
  methods: {
    async copyVal(val) {
      console.log(val);
      try {
        await navigator.clipboard.writeText(val);
        this.toggleSmModal({
          description: this.t('modalMessages.valueWasCopied'),
          isAlert: false,
        });
      } catch (err) {
        this.toggleSmModal({
          description: this.t('modalMessages.valueCopyError'),
          isAlert: true,
        });
      }
    },
    async loadData() {
      await axios({
        method: "GET",
        params: {
          client_uuid: this.$props.clientUUID
        },
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.products.variables,
      })
        .then((e) => {
          this.variables = e.data.data;
          this.isLoading = false;
        })
        .catch((e) => {
          if([404].indexOf(e.response.status) != -1)  {
            this.openErrorModal({ 
              title: '404',
              description: this.t('errors.dataEmpty'),  
            });
          }
        });
    }
  },
  props: {
    clientUUID: {
      default: null,
    },
  },
};
</script>
