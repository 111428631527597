<template>
  <div class="flex flex-col">
    <div class="flex items-center">
      <div
        class="margin-r-10"
        v-if="imageData" >
        <a
          class="flex"
          target="_blank"
          @click.prevent="openBase64Image"
          href="javascript:void(0)">
          <img
            :style="{
              maxHeight: '100px',
            }"
            :src="imageData">
        </a>
      </div>
      <div
        class="margin-r-10"
        v-else-if="prevFile && showFileLink" >
        <a
        
          class="flex"
          target="_blank"
          :href="MEDIA_HOST + fileLink">
          <img
            :style="{
              maxHeight: '100px',
            }"
            :src="MEDIA_HOST + fileLink">
        </a>
      </div>
      <div 
        :class="[
          !imageData ? 'inl-100' : 'inl-80',
        ]"
        class="inl-100 relative flex flex-row">
        <input 
          @change="onFileChanged"
          type="file"
          ref="inputFile"
          :name="name"
          :placeholder="placeholder" 
          class="form-control">
        <div 
          @click="removeFile"
          class="margin-l-5 pointer" 
          v-if="prevFile && showFileLink">
          <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd"/>
          </svg>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { MEDIA_HOST } from '@/const/uri.dev';
export default {
  data() {
    return {
      MEDIA_HOST,
      currentFile: null,
      fileChanged: false,
      showFileLink: false,
      fileLink: null,
      imageData: null,
    };
  },
  watch: {
    prevFile(to) {
      if(typeof to == 'string') {
        this.showFileLink = true;
        this.fileLink = to;
      }       
    },
  },
  mounted() {
    if(typeof this.$props.prevFile == 'string') {
      this.showFileLink = true;
      this.fileLink = this.$props.prevFile;
      this.currentFile = this.$props.prevFile;
    } 
  },
  methods: {
    openBase64Image() {
      const newWindow = window.open();
      newWindow.document.write(`<img src="${this.imageData}" alt="Image" />`);
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      if (file) {
        this.$props.setData(this.$props.nameKey, file);
        const reader = new FileReader();
        reader.onload = () => {
          this.currentFile = file;
          this.imageData = reader.result;
          console.log('file is set:', file,);
          this.setData(this.$props.nameKey, file);
        };
        reader.readAsDataURL(file);
      }
    },
    removeFile() {
      this.currentFile = null;
      if(this.$refs.fileInput && this.$refs.fileInput.value) {
        this.$refs.fileInput.value = null;
      }
      this.fileLink = null;
      this.currentFile = null;
      this.showFileLink = false;
      this.imageData = false;
      this.$props.setData(this.$props.nameKey, '');
    },
  }, 
  props: {
    prevFile: {
      default: null,
    },
    name: {},
    placeholder: {},
    setData: {},
    nameKey: {
      default: null,
    },
  }
};
</script>