<template>
  <div>
    <AdminComponent>
      <template #rightModal></template>
    </AdminComponent>
  </div>
</template>
<script>

import AdminComponent from "@/layouts/Admin.vue";

export default {
  components: {
    AdminComponent,
  },
  data() {
    return {};
  },
  props: {},
}
</script>