<template lang="">
  <div>
    <div class="app-sidebar sidebar-shadow">
      <Logo /> 
      <div class="scrollbar-sidebar app-sidebar__wrapper">
        <div class="app-sidebar__inner">
          <ul class="vertical-nav-menu">
            <li class="app-sidebar__heading">{{ t('navigation.title') }}</li>
            
            <li v-for="(el, ind) in navData" :key="ind"> 
              <router-link
                :to="{
                  name: el.routeName,
                }"  
                v-slot="{ href, navigate, isActive }">
                <div 
                  :href="href"
                  @click.prevent="() => {
                    if(el.routeName != 'TestIndex') navigate($event);
                  }"
                  :class="[
                    isActive ? 'mm-active' : '',
                  ]"
                  class="link flex items-center inl-100 pointer">
                  <div class="nav-icon">
                    <img 
                      :src="el.iconSrc"
                      :class="[
                        el.cClass ? el.cClass : 'w-6 h-6',
                      ]"
                      class="text-gray-800 dark:text-white" />
                  </div>
                  <span class="margin-l-10">{{ el.text }}</span>
                </div>
              </router-link>
            </li>

            <li 
              @click="logOut"
              class="margin-t-30">
              <div 
                class="link flex items-center inl-100 pointer">
                  <img 
                    src="/assets/images/icons/system-integration.png"
                    class="w-6 h-6 text-gray-800 dark:text-white" />
                  <span class="margin-l-10">{{ t('navigation.integrations') }}</span>
              </div>
            </li>
            <li 
              class="margin-t-5">
              <div 
                class="link flex items-center inl-100 pointer">
                  <img 
                    src="/assets/images/icons/logout.png"
                    class="w-6 h-6 text-gray-800 dark:text-white" />
                  <span class="margin-l-10">{{ t('navigation.logOut') }}</span>
              </div>
            </li>
            
            <li 
              v-if="false" class="">
              <a href="#">
                <i class="metismenu-icon pe-7s-diamond"></i> 
                Elements 
                <i class="metismenu-state-icon pe-7s-angle-down caret-left"></i>
              </a>
              <ul class="">
                <li>
                  <a href="elements-buttons-standard.html" class="mm-active" >
                    <i class="metismenu-icon"></i>
                    Buttons
                  </a>
                </li>
                <li>
                  <a href="elements-dropdowns.html">
                    <i class="metismenu-icon"></i>
                    Dropdowns
                  </a>
                </li>
                <li>
                  <a href="elements-icons.html">
                    <i class="metismenu-icon"></i>
                    Icons
                  </a>
                </li>
                <li>
                  <a href="elements-badges-labels.html">
                    <i class="metismenu-icon"></i>
                    Badges
                  </a>
                </li>
                <li>
                  <a href="elements-cards.html">
                    <i class="metismenu-icon"></i>
                    Cards
                  </a>
                </li>
                <li>
                  <a href="elements-list-group.html">
                    <i class="metismenu-icon"></i>
                    List Groups
                  </a>
                </li>
                <li>
                  <a href="elements-navigation.html">
                    <i class="metismenu-icon"></i>
                    Navigation Menus
                  </a>
                </li>
                <li>
                  <a href="elements-utilities.html">
                    <i class="metismenu-icon"></i>
                    Utilities
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>      
  </div>
</template>

<script>
import Logo from "@/components/AppHeader/Components/logo.vue";
import { mapState } from "vuex";
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  components: {
    Logo,
  },
  data() {
    return {
      navData: [
        { 
          text: this.t('navigation.clients'),
          iconSrc: '/assets/images/icons/clients.svg',
          routeName: 'ClientIndex',
        },
        { 
          text: this.t('navigation.products'),
          iconSrc: '/assets/images/icons/package.png',
          routeName: 'ProductsIndex',
        },
        {
          text: this.t('navigation.limits'),
          iconSrc: '/assets/images/icons/credit-score.png',
          routeName: 'LimitsList',
        },
        {
          text: this.t('navigation.cards'),
          iconSrc: '/assets/images/icons/credit-card.png',
          routeName: 'CardsIndex',
        },
        {
          text: this.t('navigation.deals'),
          iconSrc: '/assets/images/icons/deal.png',
          routeName: 'DealsActive',
        },
        {
          text: this.t('navigation.accounting'),
          iconSrc: '/assets/images/icons/profit.png',
          routeName: 'TestIndex',
        },

        {
          text: this.t('navigation.debtManagement'),
          iconSrc: '/assets/images/icons/debt.png',
          routeName: 'TestIndex',
        },
        {
          text: this.t('navigation.eSigning'),
          iconSrc: '/assets/images/icons/contract_1.png',
          routeName: 'TestIndex',
        },
        {
          text: this.t('navigation.smartContractsSoon'),
          iconSrc: '/assets/images/icons/smart-contracts.png',
          routeName: 'SmartContractsIndex',
        },

        {
          text: this.t('navigation.reporting'),
          iconSrc: '/assets/images/icons/report.png',
          routeName: 'TestIndex',
        },
        {
          text: this.t('navigation.analytics'),
          iconSrc: '/assets/images/icons/data-analytics.png',
          routeName: 'TestIndex',
        },

        {
          text: this.t('navigation.scoring'),
          iconSrc: '/assets/images/icons/speedometer.png',
          routeName: 'TestIndex',
        },
        {
          text: this.t('navigation.riskMonitoring'),
          iconSrc: '/assets/images/icons/important.png',
          routeName: 'TestIndex',
        },

      ],
    };
  },
  computed: {
    ...mapState(["isNavOpen"]),
  },
  methods: {
    logOut() {
      localStorage.removeItem('user_token');
      this.$router.push({ name: 'AuthLogin' });
    },
  },
};
</script>