<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div class="">
      <div
        style="z-index: 99999; left: 25%"
        class="ez-modal-height ez-modal-top inl-75 transition duration-150 ease-in-out absolute right-0 left-0 rounded-lg"
      >
        <form class="h-100-p flex" action="">
          <div
            class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
          >
            <div
              class="text-gray-800 f-20 font-bold tracking-normal leading-tight mb-4"
            >
              <div>
                <span>{{ t("navigation.editProductTemplate") }}</span>
              </div>
            </div>
            <button
              @click.prevent="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <div
              class="pr-3 justify-center h-100-p inl-100 flex flex-col text-left relative"
            >
              <div class="h-100-p" style="width: 100%">
                <div class="nav-buttons flex justfify-cenetr">
                  <div
                    class="w-full body-tabs body-tabs-layout tabs-animated body-tabs-animated nav"
                  >
                    <div class="flex justify-between items-center w-full">
                      <div class="flex flex-row">
                        <div class="flex items-center">
                          <div style="width: 250px" class="margin-r-30">
                            <model-select
                              :options="
                                getSelectOptionsBase(clients, 'uuid', 'fio')
                              "
                              v-model="clientUUID"
                              :placeholder="t('actions.enterClientName')"
                            >
                            </model-select>
                          </div>
                        </div>
                        <div class="nav-item modal-doc-btn-width">
                          <div
                            :class="[action == 'showDocx' ? 'active' : '']"
                            @click="
                              () => {
                                changeAction('showDocx');
                              }
                            "
                            class="nav-link nav-btn show"
                          >
                            <span class="">{{ t("actions.view") }}</span>
                          </div>
                        </div>
                        <div 
                          v-if="!isFileLoading"
                          class="nav-item modal-doc-btn-width">
                          <div
                            :class="[action == 'showVariables' ? 'active' : '']"
                            @click="
                              () => {
                                changeAction('showVariables');
                              }
                            "
                            class="nav-link nav-btn show"
                          >
                            <span class="">{{ t("actions.variables") }}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="!isFileLoading"
                        class="flex flex-row justify-end flex-wrap">

                        <div class="relative nav-item nav-item-dropdown modal-doc-btn-width">                          
                          
                          <div
                            @click="() => {showDropdown = !showDropdown;}"
                            style="width: 130px;"
                            class="active nav-link nav-btn show">
                            <span class="flex flex-row items-center">
                              <svg 
                                v-if="isPdfLoading"
                                aria-hidden="true" role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor"></path>
                              </svg>
                              <span v-if="!isPdfLoading">{{ t("actions.download") }}</span>
                              <span v-else>{{ t("actions.downloading") }}</span>
                            </span>
                          </div>

                          <div 
                            v-if="showDropdown"
                            class="z-10 dropdown bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 dark:divide-gray-600">
                            <div
                              @click="() => {
                                showDropdown = false;
                                downloadTemplate();
                              }"
                              class="pointer px-1 py-1 pb-1"
                              >{{ t("actions.template")}}</div>
                            <div
                              @click="() => {
                                showDropdown = false;
                                downloadFile(blobData);
                              }"
                              class="pointer px-1 py-1 pb-1">{{ t("actions.file") }} (DOCX)</div>
                            <div
                              @click="() => {
                                showDropdown = false;
                                downloadPDF();
                              }"
                              class="pointer px-1 py-1 pb-1">{{ t("actions.file") }} (PDF)</div>
                          </div>

                        </div>

                        <!-- v-if="docProductStatus == 0" -->
                        <div 
                          v-if="!isFileLoading"
                          class="nav-item modal-doc-btn-width">
                          <div 
                            @click="openFile"
                            class="active nav-link nav-btn show nav-success">
                            <span class="">{{ t("actions.update") }}</span>
                            <input
                              @change="onFileChanged"
                              ref="inputFile"
                              style="position: absolute; left: -10000%;"
                              type="file">
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  :style="{
                    display: action == 'showDocx' ? 'block' : 'none',
                  }"
                  class="docx-template docx-template-block scroll-styling overflow-y-scroll"
                >
                  <div
                    :class="[
                      isFileLoading
                        ? 'h-100-p flex justify-center items-center'
                        : '',
                    ]"
                    class="document-block"
                  >
                    <img
                      src="/assets/images/loading/loader-black-rounded.svg"
                      alt=""
                    />
                  </div>
                </div>

                <ProductTemplateVariables
                  v-if="action == 'showVariables'"
                  :clientUUID="clientUUID"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { renderAsync } from "docx-preview";
import axios from "axios";
import { API_FACTOR, HEADERS_TOKEN_JSON, HEADERS_TOKEN_JSON_FILE } from "@/const/uri";
import { getSelectOptionsBase } from "@/utils/base.js";
import { ModelSelect } from "vue-search-select";
import ProductTemplateVariables from "@/components/Modals/Product/ProductTemplateVariables.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  inject: ["openErrorModal", "toggleSmModal"],
  components: {
    ModelSelect,
    ProductTemplateVariables,
  },
  data() {
    return {
      action: null,
      // action: "showVariables",
      isPdfLoading: false,
      showDropdown: false,
      docData: null,
      isFileLoading: true,
      // clientUUID: "-1",
      clientUUID: null,
      clients: [],
      blobData: null,
      updatedFile: null,
    };
  },
  mounted() {
    this.getSignerUsers();
  },
  watch: {
    clientUUID() {
      this.changeAction("showDocx");
    },
  },
  methods: {
    clearFileInput() {
      this.$refs.inputFile.value = '';
      // OptionallenputFile.dispatchEvent(new Event('change'));
    },
    getSelectOptionsBase,
    downloadPDFBlob(blobPDF) {
      var blob = new Blob([blobPDF], {type: 'application/pdf'});
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    },
    downloadFile(blob, isTemplate = false) {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      let fileName = `${this.$props.docType}`;
      if (isTemplate) {
        fileName += "-template";
      }
      fileName += ".docx";
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    },
    async downloadTemplate() {
      const url = `${API_FACTOR.products.templateFile}${this.$props.productUUID}/${this.$props.docType}/`;
      await axios({
        method: "GET",
        url,
        headers: HEADERS_TOKEN_JSON(),
        responseType: "arraybuffer",
      })
        .then((e) => {
          const blobData = new Blob([e.data], {
            type: "application/octet-stream",
          });
          this.downloadFile(blobData, true);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async downloadPDF() {
      this.isPdfLoading = true;
      const url = `${API_FACTOR.products.template}${this.$props.productUUID}/${this.$props.docType}/`;
      await axios({
        method: "GET",
        url,
        params: {
          is_pdf: 1,
          client_uuid: this.clientUUID,
        },
        headers: HEADERS_TOKEN_JSON(),
        responseType: "arraybuffer",
      })
        .then((e) => {
          const blobData = new Blob([e.data], {
            type: "application/octet-stream",
          });
          this.downloadPDFBlob(blobData,);
          this.isPdfLoading = false;
        })
        .catch((e) => {
          this.isPdfLoading = false;
          console.log(e);
        });
    },
    changeAction(action) {
      if (this.clientUUID == null) {
        this.toggleSmModal({
          description: this.t("modalMessages.chooseTemplateUser"),
          isAlert: true,
        });
        return;
      }
      this.isFileLoading = true;
      if (action == "showVariables") {
        this.docData = null;
      }
      if (action == "showDocx") {
        this.getFile();
      }
      this.action = action;
    },
    renderAsync,
    async getSignerUsers() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.products.signerTemplateUsers,
      })
        .then((e) => {
          this.clients = e.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getFile() {
      const url =
        API_FACTOR.products.template +
        this.$props.productUUID +
        "/" +
        this.$props.docType +
        "/";
      await axios({
        url,
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        responseType: "arraybuffer",
        params: {
          client_uuid: this.clientUUID,
        },
      })
        .then((e) => {
          this.isFileLoading = false;
          const blobData = new Blob([e.data], {
            type: "application/octet-stream",
          });
          this.blobData = blobData;
          this.renderAsync(
            blobData,
            document.querySelector(".document-block")
          ).then(() => {});
        })
        .catch((e) => {
          this.toggleSmModal({
            description: this.t("modalMessages.templateNotFound"),
            isAlert: true,
          });
          console.log(e);
        });
    },
    openFile() {
      this.$refs.inputFile.click();
    },
    onFileChanged(event) {
      const file = event.target.files[0]; 
      if (file) {
        this.updatedFile = file;
        this.updateFile();
      }
    },
    async updateFile() {
      const url =
        API_FACTOR.products.template +
        this.$props.productUUID +
        "/" +
        this.$props.docType +
        "/";
      const data = {}; 
      if(this.$props.docType == 'supplier') {
        data['supplier_agreement'] = this.updatedFile;
      } else {
        data['buyer_agreement'] = this.updatedFile;
      }
      this.action = 'showDocx';
      this.isFileLoading = true;
      await axios({
        url,
        method: "POST",
        headers: HEADERS_TOKEN_JSON_FILE(),
        responseType: "arraybuffer",
        params: {
          client_uuid: this.clientUUID,
        },
        data,
      })
        .then((e) => {
          this.updatedFile = null;
          this.isFileLoading = false;
          const blobData = new Blob([e.data], {
            type: "application/octet-stream",
          });
          this.blobData = blobData;
          this.renderAsync(
            blobData,
            document.querySelector(".document-block")
          ).then(() => {});
          this.clearFileInput();
        })
        .catch((e) => {
          this.clearFileInput();
          this.toggleSmModal({
            description: this.t("modalMessages.templateNotFound"),
            isAlert: true,
          });
          console.log(e);
        });
    },
  },
  props: {
    docType: {
      default: null,
    },
    docLink: {
      default: "http://localhost:8000/media/template_1.docx",
    },
    productUUID: {
      default: null,
    },
    docProductStatus: {
      default: null,
    },
    toggleModal: {},
  },
};
</script>
