<template>
  <div 
    style="z-index: 99999;"
    class="fixed z-10 inset-0 overflow-y-auto" id="my-modal">
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="center-block inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
            <svg class="h-6 w-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
              <path 
                class="text-green-600"
                fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z" clip-rule="evenodd"/>
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              {{ submitModalData.title }}
            </h3>
            <div class="mt-2">
              <p v-html="submitModalData.description" class="text-sm text-gray-500">
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <div class="flex justify-between">
            <button
              :class="[
                !submitModalData.btnFirstColor ? 
                  'bg-red-600 hover:bg-red-700 focus:ring-red-500' :
                  `bg-${submitModalData.btnFirstColor}-600 hover:bg-${submitModalData.btnFirstColor}-700 focus:ring-${submitModalData.btnFirstColor}-500`
                  ,
              ]"
              class="inline-flex margin-r-5 justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
              @click="modalSubmiCallback"
            >
              <span 
                class="text-white" 
                v-if="submitModalData.btnFirst" >{{ submitModalData.btnFirst }}</span>
              <span 
                class="text-white" 
                v-else>{{ t('form.submit') }}</span>
            </button>
            <button
              :class="[
                !submitModalData.btnSecondColor ? 
                  'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500' :
                  `bg-${submitModalData.btnSecondColor}-600 hover:bg-${submitModalData.btnSecondColor}-700 focus:ring-${submitModalData.btnFirstColor}-500`
                  ,
              ]"
              class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm"
              @click="closeSubmitModal"
            >
              <span 
                class="text-white" 
                v-if="submitModalData.btnSecond" >{{ submitModalData.btnSecond }}</span>
              <span 
                class="text-white" 
                v-else>{{ t('form.сancel') }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
    };
  },
  props: {
    submitModalData: {
      default: {
        title: null,
        description: null,
      },
    },
    modalSubmiCallback: {
      default: () => {}
    },
    closeSubmitModal: {
      default: () => {},
    }
  },
}

</script>