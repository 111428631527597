<template>
  <div
    :class="[!isNavOpen ? 'ez-modal-width-full' : 'ez-modal-width']"
    class="ez-modal-height-70 ez-modal-top-40 limit-order-list shadow-sm px-2 py-3"
  >
    <div class="inl-100 h-100-p flex flex-col py-4 px-2 relative overflow-hidden">

      <div class="w-full border-b border-gray-200 dark:border-gray-600 padding-bt-15">
        <div class="flex flex-wrap items-center justify-between mx-auto">
        <div
          class="flex text-black items-center f-20 font-bold">
          {{ t('deal.signDoc') }}
        </div>
        <div class="flex md:order-2 items-center">

          <div v-if="isSign"
          class="flex items-center justify-center">
            <span class="f-16 font-bold ">Подписан</span>
          </div>


          <button
            v-if="isSign"
            @click="downloadSignedDocx"
            class="margin-l-15 text-white btn btn-success px-4 py-2 text-center">
            {{ t('actions.download') }}
          </button>

          <button
            v-else
            @click="signPdf"
            class="text-white btn btn-success px-4 py-2 text-center">
            {{ t('actions.sign') }}
          </button>

          <div 
            v-if="!isSign"
            class="relative margin-l-15 text-center text-white flex justify-center items-center">
            <span 
              @click.prevent="() =>  {
                isModalNavOpen = !isModalNavOpen;
              }"
              class="pointer bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-white">{{ t('actions.change') }}</span>
            <div 
              v-if="isModalNavOpen"
              class="modal-dropdown shadow rounded text-left px-2 py-3">
              <ul>
                <li
                  @click.prevent="downloadDocx"
                  class="pointer f-15 hover:font-bold">{{ t('actions.download') }}</li>
                <li
                  @click.prevent="updateDocx"
                  class="pointer f-15 hover:font-bold">{{ t('actions.update') }}</li>
                <li
                  @click.prevent="generateDocx"
                  class="pointer f-15 hover:font-bold">{{ t('actions.generate') }}</li>
              </ul>
            </div>
          </div>

          <button 
            @click.prevent="toggleModal"
            type="button"
            class="flex margin-l-15 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center">
            {{ t('actions.close') }}
          </button>

        </div>

        <div class="items-center justify-between" >
          <ul class="flex flex-row">
            <li class="margin-r-15">
              <a 
                @click="() => {
                  currentClientType = 'buyer';
                }"
                :class="[
                  currentClientType == 'buyer' ? 'font-bold' : '',
                ]"
                class="f-16 pointer block hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700">
                {{ t("deal.signDocBuyer") }}</a>
            </li>
            <li>
              <a
                @click="() => {
                  currentClientType = 'supplier';
                }"
                :class="[
                  currentClientType == 'supplier' ? 'font-bold' : '',
                ]"
                class="f-16 pointer block hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700">
                {{ t("deal.signDocSupplier") }}</a>
            </li>
          </ul>
        </div>
        </div>
      </div>

      <div class="h-100-p relative">
        
        <ViewSignDocx
          ref="docxBuyer" 
          v-if="currentClientType == 'buyer'"
          :clientType="currentClientType"
          :dealUUID="dealUUID"
        />
        
        <ViewSignDocx
          ref="docxSupplier" 
          v-if="currentClientType == 'supplier'"
          :clientType="currentClientType"
          :dealUUID="dealUUID"
        />

      </div>
    </div>

  </div>
</template>

<script>

import { mapState } from "vuex";
import { useI18n } from "vue-i18n";
import ViewSignDocx from '@/components/Admin/DocxSign/ViewSignDocx.vue';
import { formShowError } from '@/utils/erros';
import axios from 'axios';
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  inject: [
    'openErrorModal', 
    'toggleSmModal',
    'openSubmitModal',
    'closeSubmitModal',
  ],
  components: {
    ViewSignDocx,
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapState(["isNavOpen"]),
    docxComponent() {
      if(this.currentClientType == 'buyer') {
        return this.$refs.docxBuyer;
      } else {
        return this.$refs.docxSupplier;
      }
    },
  },
  data() {
    return {
      isLoad: false,
      isSign: false,
      isModalNavOpen: false,
      clientTypes: [
        'buyer', 'supplier',
      ],
      currentClientType: 'buyer',
    };
  },
  watch: {
    currentClientType() {
      this.getDocxSignStatus();
    },
  },
  methods: {
    async init() {
      await this.getDocxSignStatus();
      this.loadData();
    }, 
    async downloadSignedDocx () {
      await axios({
        url: API_FACTOR.deals.sign + this.$props.dealUUID + "/download/",
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        responseType: "arraybuffer",
        params: {
          client_type: this.currentClientType,
        },
      })
      .then((e) => {
        const blob = new Blob([e.data], {
          type: 'application/pdf'
        });
        try {
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('Error downloading the file', error);
        }
      })
      .catch(formShowError.bind(this));
    },
    async getDocxSignStatus() {
      await axios({
        url: API_FACTOR.deals.sign + this.$props.dealUUID + "/client-status/",
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        params: {
          client_type: this.currentClientType,
        },
      })
      .then((e) => {
        this.isSign = e.data.is_sign; 
      })
      .catch(formShowError.bind(this));
    },
    async downloadDocx() {
      if(this.currentClientType == 'buyer') {
        const docName = this.t('docx.buyerFile');
        this.docxComponent.downloadDocx(docName);
      } else {
        const docName = this.t('docx.supplierFile');
        this.docxComponent.downloadDocx(docName);
      }
    },
    async updateDocx() {
      this.docxComponent.updateDocx();
    },
    async generateDocx() {
      this.docxComponent.generateDocx();
    },
    async signActions() {
      const isSign = await this.docxComponent.signPdf();
      if(isSign == 1) {
        this.isSign = isSign;
        this.toggleSmModal({
          description: this.t('docx.wasSigned'),
          isAlert: false,
        });
      }
      this.closeSubmitModal();
    },
    async signPdf() {
      this.openSubmitModal({
        title: this.t('actions.submitAction'), 
        description: this.t('docx.cantCancelDocx'),
        btnFirst: this.t('actions.submit'),
        btnSecond: this.t('actions.cancel'),
        btnFirstColor: 'green',
      }, () => {
        this.signActions();
      });
    },
    async loadData() {
      this.isLoad = false;
    },
  },
  props: {
    dealUUID: {
      default: null,
    },
    toggleModal: {
      default: null,
    },
  },
};
</script>