<template>
  <div>
    <table v-if="bodyData.length > 0" class="mb-0 table table-striped">
      <thead>
        <tr>
          <th class="inl-5">#</th>
          <th 
            :class="[
              getPageWidth(headerKeys[key].name)
            ]"
            v-for="(key, ind) in keysOrder" :key="ind">
            {{ headerKeys[key] ? headerKeys[key].title : "" }}
          </th>
          <th class="inl-10 text-center">{{ t('actions.actions') }}</th>
          <th class="inl-10 text-center"></th>
        </tr>
      </thead>
    </table>

    <div class="table-scroll scroll-styling">
      <table v-if="bodyData.length > 0" 
        class="mb-0 table table-striped">
        <tbody>
          <tr
            class="align-middle"
            v-for="(data, ind) in bodyData" :key="ind">
            <th 
            class="inl-5" 
            scope="row">
              {{ ind+1+ ((currentPage-1) * postPerPage) }}
            </th>
            <td 
              :class="[
                getPageWidth(headerKeys[key].name)
              ]"
              v-for="(key, keyInd) in keysOrder" :key="keyInd">
              <div v-if="navData && navData.navField == headerKeys[key].name">
                <router-link
                  :to="getNavData(data,)"  
                  v-slot="{ href, navigate}">
                  <div 
                    :href="href"
                    @click="navigate"
                    class="font-bold">
                      {{ getVal(headerKeys[key].name, headerKeys[key].type, data) }} <br>
                  </div>
                </router-link>
              </div>
              <div v-else>
                {{ getVal(headerKeys[key].name, headerKeys[key].type, data) }} <br>
              </div>
            </td>

            <td 
              class="inl-10">
              <div 
                v-if="[0, 1, 2].includes(data.deal_status.key)"
                class="flex flex-col justify-center">
                <button
                  v-if="data.deal_status.key == 0"
                  @click="() => {
                    submitRegisterModalData(ind, data, 0,);
                  }"
                  type="button"
                  class="margin-bt-10 btn btn-success"
                  >{{ t('actions.register')}}</button>
                <button
                  v-if="data.deal_status.key == 1"
                  @click="() => {
                    submitRegisterModalData(ind, data, 1,);
                  }"
                  type="button"
                  class="margin-bt-10 btn btn-success"
                  >{{ t('actions.submit')}}</button>
                <button
                  v-if="data.deal_status.key == 2"
                  @click="() => {
                    submitRegisterModalData(ind, data, 2,);
                  }"
                  type="button"
                  class="margin-bt-10 btn btn-success"
                  >{{ t('actions.sign')}}</button>
                <button
                  @click="() => {
                    submitRegisterModalData(ind, data, -1,);
                  }"
                  type="button"
                  class="btn btn-danger"
                  >{{ t('actions.cancel')}}</button>
              </div>
            </td>

            <td class="inl-10">
              <div class="flex flex-row justify-center">
                <div
                  @click="() => {
                    toggleDetailModal(data.uuid, ind,);
                  }"
                  class="icon-action shadow-md margin-r-5">
                  <img 
                    src="/assets/images/icons/list.png" alt="">
                </div>
              </div>
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import { getValueFromObject, beautifyInteger, } from '@/utils/base';
// import { formShowError } from '@/utils/erros';
// import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
// import axios from 'axios';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
    'openSubmitModal',
    'closeSubmitModal',
  ],
  components: {},
  data() {
    return {
      formKeys: [],
      showData: false,
      showIndex: null,
      isUpdate: false,
    };
  },
  mounted() {
    // const data = {
    //   "uuid": "ea208729-19bd-11ef-a63e-05c077ef0181",
    //   "deal_status": {
    //     "key": 2,
    //     "val": "Проверен"
    //   },
    //   "client_company": {
    //     "name_short": {
    //       "en": "CLIENT TEST",
    //       "kz": "CLIENT TEST",
    //       "ru": "CLIENT TEST"
    //     },
    //     "bin": "990102300841"
    //   },
    //   "client_type": {
    //     "key": 0,
    //     "val": "Поставщик"
    //   },
    //   "product": {
    //     "uuid": "ad54001f-0607-11ef-bde7-13a095b034d9",
    //     "name": "test2"
    //   },
    //   "created_at": "24.05.2024 16:08:13",
    //   "factor_sum": 1000000
    // };
    // this.submitRegisterModalData(0, data, 2);
  },
  methods: {
    toggelHistorySmall(uuid, ind) {
      this.showData = !this.showData;
      if(typeof ind != 'undefined' && ind !== null) {
        this.showIndex = ind; 
      } else {
        this.showIndex = null;
      }
      this.$props.openHistory(uuid, ind);
    },
    getNavData(data,) {
      const params = {};
      params[this.$props.navData.urlKey] = data[this.$props.navData.key];
      return {
        name: this.$props.navData.urlName,
        params,
      };
    },
    getPageWidth(key) {
      let val = this.fieldWidth[key];
      if (!val) return "";
      return "inl-" + val;
    },
    getVal(key, type, data,) {
      const val = getValueFromObject(data, key, type,)
      if (!val) return "-"
      if(type == 'int' || type == 'float') return beautifyInteger(val);
      return val;
    },
  },
  props: {
    toggleDetailModal: {
      default: () => {},
    },
    submitRegisterModalData: {
      default: () => {},
    },
    updateData: {
      default: () => {},
    },
    keysOrder: {
      default: [],
    },
    fieldWidth: {
      default: {},
    },
    headerKeys: {
      default: {},
    },
    addIndex: {
      default: 0,
    },
    currentPage: {
      default: 1,
    },
    postPerPage: {
      default: 0,
    },
    bodyData: {
      default: [],
    },
    navData: {
      default: {},
    },
  },
};
</script>