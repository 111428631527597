<template>
  <div 
    :class="[
      isActive ? 'active': '',
    ]"
    class="search-wrapper">
    <div class="input-holder">
      <input 
        v-on:keyup.enter="() => {
          setSearch(searchVal);
        }"
        v-model="searchVal"
        type="text" 
        class="search-input"
        :placeholder="t('form.enterTitle')" />
      <button 
        @click="setActive()"
        class="search-icon"><span></span></button>
    </div>
    <button 
      @click.prevent="setUnActive"
      class="btn-close"></button>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
      searchVal: null,
      isActive: false,
    };
  }, 
  beforeMount() {
    this.searchVal = this.$props.startVal;
    if(this.searchVal) this.isActive = true;
  }, 
  methods: {
    setActive() {
      if(this.isActive) {
        this.setSearch();
        return;
      }
      this.isActive = true;
    },
    inputSearch(e) {
      this.searchVal = e.target.value;
      this.setSearch();
    },
    setUnActive() {
      this.isActive = false;
      this.searchVal = null;
      this.setSearch();
    }, 
    setSearch()  {
      this.$router.replace({
        path: this.$route.path,
        query: {
          page: 1,
          q: this.searchVal,
        }
      });      
    }
  }, 
  props: {
    startVal: {},
  },
}
</script>
