<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div class="">
      <div
        style="z-index: 99999; left: 60%"
        class="h-100-p top-0 inl-40 transition duration-150 ease-in-out absolute right-0 left-0 rounded-lg"
      >
        <form class="h-100-p flex" action="">
          <div
            class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
          >
            <div
              class="text-gray-800 f-20 text-center font-bold tracking-normal leading-tight mb-4"
            >
              {{ t('form.addEmployee') }}
            </div>

            <button
              @click.prevent="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <div
              class="justify-center h-100-p inl-100 flex flex-col text-left relative"
            >
              <div class="flex flex-row">
                <model-select
                  v-if="emptyUsers && emptyUsers.length > 0"
                  :options="getSelectOptionsBase(emptyUsers, 'uuid', 'fio')"
                  v-model="activeUserUUID"
                  :placeholder="t('form.selectUser')"
                >
                </model-select>

                <div class="margin-l-15"></div>

                <button @click.prevent="() => {
                  toggleModalUser();
                }" class="">
                  <div class="relative add-button rounded-button pointer">
                    <svg
                      class="center-block w-6 h-6 text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        class="text-white"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 1v16M1 9h16"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>

              <div v-if="addedCompanies" class="margin-t-30 margin-bt-15">
                <div
                  class="scroll-styling overflow-y-scroll h-300 max-w-md divide-y divide-gray-200 dark:divide-gray-700 pr-3"
                >
                  <div
                    v-for="(el, ind) in addedCompanies"
                    :key="ind"
                    class="px-1 py-1 sm:pb-4 border-1 margin-bt-10 rounded-sm"
                  >
                    <div
                      class="flex items-center space-x-4 rtl:space-x-reverse"
                    >
                      <div class="flex-1 min-w-0">
                        <div
                          class="text-sm font-medium text-gray-900 truncate dark:text-white"
                        >
                          {{ t('form.fio') }}: {{ el.fio }}
                        </div>
                        <div
                          class="text-sm text-gray-500 truncate dark:text-gray-400"
                        >
                          {{ t('form.phone') }}: {{ el.phone }}
                        </div>
                      </div>

                      <div
                        @click="
                          () => {
                            toggleModalUser(
                              el.uuid, ind
                            );
                          }
                        "
                        class="pointer inline-flex items-center text-base font-semibold text-gray-900 dark:text-white"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                          />
                        </svg>
                      </div>
                      <div
                        @click="
                          () => {
                            deleteData(el.index);
                          }
                        "
                        class="pointer inline-flex items-center text-base font-semibold text-gray-900 dark:text-white"
                      >
                        <svg
                          class="w-6 h-6 cursor text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  @click.prevent="saveData"
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                >
                  {{ t('actions.save') }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <CenterModalEditUser
      v-if="openUserModal"
      :changeUserData="changeUserData"
      :addUserData="addUserData"
      :companyUUID="companyUUID"
      :userUUID="modalUserUUID"
      :userIndex="modalUserIndex"
      :toggleModal="toggleModalUser"
    />

  </div>
</template>

<script>
import axios from "axios";
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import { fromErrorToHtml } from "@/utils/erros.js";
import { ModelSelect } from "vue-search-select";
import { useI18n } from 'vue-i18n';
import { getSelectOptionsBase } from "@/utils/base.js";
import CenterModalEditUser from "@/components/Modals/CenterModalEditUser.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: ["openErrorModal", "openSubmitModal", "closeSubmitModal"],
  components: {
    ModelSelect,
    CenterModalEditUser,
  },
  beforeMount() {
    this.getData();
  },
  data() {
    return {
      modalUserUUID: null,
      modalUserIndex: null,
      openUserModal: false,
      activeIds: [],
      activeUserUUID: null,
      modalData: [],
    };
  },
  computed: {
    addedCompanies() {
      const data = this.modalData.filter((el) => el.company);
      return data;
    },
    emptyUsers() {
      const data = this.modalData.filter((el) => !el.company);
      return data;
    },
  },
  watch: {
    activeUserUUID(to) {
      if (to === null) return;
      this.onChangeData(to);
    },
  },
  methods: {
    getSelectOptionsBase,
    fromErrorToHtml,
    toggleModalUser(uuid, index) {
      this.modalUserUUID = uuid ? uuid : null;
      this.modalUserIndex = typeof index != 'undefined' ? index : null;
      this.openUserModal = !this.openUserModal;
    },
    changeUserData(data, index,) { 
      this.modalData[index] = {
        ...this.modalData[index],
        ...data,
      };
      this.modalData = [...this.modalData]
    },
    addUserData(data) {
      data.is_new = true;
      data.company = data.uuid;
      this.modalData = [
        ...this.modalData,
        {
          ...data,
          index: this.modalData.length,
        },
      ];
    },
    removeItem(array, item) {
      for (var i in array) {
        if (array[i] == item) {
          array.splice(i, 1);
          break;
        }
      }
    },
    deleteData(ind) {
      this.modalData[ind].company = null;
      this.modalData[ind].is_new = !this.modalData[ind].is_new;
    },
    onChangeData(to) {
      for (let index = 0; index < this.modalData.length; index++) {
        if (this.modalData[index].uuid == to) {
          this.modalData[index].company = to;
          this.modalData[index].is_new = !this.modalData[index].is_new;
          break;
        }
      }
    },
    async saveData() {
      await axios({
        method: "POST",
        headers: HEADERS_TOKEN_JSON(),
        data: {
          part_data: this.modalData.filter((el) => el.is_new),
        },
        url: API_FACTOR.factor.company + this.$props.companyUUID + "/employee/",
      })
        .then(() => {
          this.openSubmitModal(
            {
              title: this.t('modalMessages.usersWasAdded'),
              description: ``,
              btnFirst: this.t('actions.close'),
              btnSecond: this.t('actions.stay'),
              btnFirstColor: "green",
            },
            () => {
              this.$props.toggleModal();
              this.closeSubmitModal();
            }
          );
        })
        .catch((e) => {
          const errorData = e.response.data;
          if ([400, 404].indexOf(e.response.status) != -1) {
            this.openErrorModal({
              title: "400",
              description: this.fromErrorToHtml(errorData, [
                {
                  field_name: "name",
                  label: this.t('form.title'),
                },
              ]),
            });
          }
        });
    },
    async getData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.factor.company + this.$props.companyUUID + "/employee/",
      })
        .then((e) => {
          const data = e.data.data;
          data.forEach((el, index) => {
            this.modalData.push({
              ...el,
              index,
            });
          });
        })
        .catch((e) => {
          console.log(e);
          const errorData = e.response.data;
          if ([400, 404].indexOf(e.response.status) != -1) {
            this.openErrorModal({
              title: "400",
              description: this.fromErrorToHtml(errorData),
            });
          }
        });
    },
  },
  props: {
    toggleModal: {},
    companyUUID: {},
  },
};
</script>
