<template>
  <div class="">
    <div class="app-page-title flex items-center z-100">
      <div class="flex items-center justify-between inl-100">

        <div class="flex items-center">
          <div class="page-title-wrapper margin-r-30">
            <div class="page-title-heading">
              <div class="page-title-icon">
                <img src="/assets/images/icons/deal.png" alt="" />
              </div>
              <div>
                {{ t('navigation.deals') }}
                <div class="page-title-subheading">{{ t('navigation.active') }}</div>
              </div>
            </div>
          </div>

          <Search :startVal="searchVal" />

          <div class="margin-l-10 margin-r-20">

          </div>

          <div class="nav-buttons margin-l-10">
            <ul
              class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav"
            >
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'DealsActive',
                  }"  
                  v-slot="{ href, navigate }">
                <div
                  class="nav-link nav-btn show active"
                  :href="href"
                  @click="navigate">
                  <span class="">{{ t('navigation.active') }}</span>
                </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'DealsBid',
                  }"  
                  v-slot="{ href, navigate }">
                <div
                  class="nav-link nav-btn show"
                  :href="href"
                  @click="navigate">
                  <span class="">{{ t('navigation.applications') }}</span>
                </div>
                </router-link>
              </li>
            </ul>
          </div>

        </div>

        <!-- right block -->
        <div class="flex items-center">

        </div>

      </div>
    </div>

    <div class="fix-templ-row">
      <div class="col-lg-12">
        <div class="card fix-table-row">
          <div class="card-body relative">
              <AdminTable
                :updateData="rowUpdateData"
                :currentPage="currentPage"
                :postPerPage="postPerPage"
                :keysOrder="keysOrder"
                :fieldWidth="fieldWidth"
                :headerKeys="headerKeys"
                :bodyData="bodyData"
                :toggleDetailModal="toggleDetailModal"
              />
              <TableNav
                :currentPage="currentPage"
                :navItems="navItems"
                :allCount="allCount"
                style="width: 330px;"
              />
          </div>
        </div>
      </div>
    </div>

    <slot name="rightModal">
      <DetailModal
        v-if="showDetailModal"
        :toggleModal="toggleDetailModal"
        :currentDealUUID="currentDealUUID"
      />
    </slot>

  </div>
</template>

<script>
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import axios from "axios";
import Search from '@/components/Admin/Search.vue';
import { useI18n } from 'vue-i18n';
import AdminTable from '@/components/Admin/TableDeals.vue';
import TableNav from '@/components/Admin/TableNav.vue';
import DetailModal from '@/components/Admin/DealModalView.vue';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
  ],
  components: {
    Search, AdminTable,
    TableNav, DetailModal
  },
  computed: {},
  data() {
    return {
      currentDealUUID: null,
      // currentDealUUID: '521cd241-175d-11ef-b622-ed60b5fa6e0f',
      showDetailModal: false,
      // showDetailModal: true,
      isLoad: false,
      keysOrder: [
        'client_company', 'client_type',
        'factor_sum',
        'product', 'created_at',
      ],
      fieldWidth: {
        'client_company.name_short': 25,
        client_type: 10,
        factor_sum: 20,
        'product.name': 15,
        created_at: 15,
      }, 
      currentPage: 1,
      searchVal: null,
      headerKeys:  {
        client_company: {title: this.t('company.title'), type: 'json', name: 'client_company.name_short'},
        client_type: {
          title: this.t('company.type'), 
          type: 'choice', 
          name: 'client_type',
          vals: [],
        },
        factor_sum: {title: this.t('form.factorSum'), type: 'int', name: 'factor_sum'},
        product: {title: this.t('form.productName'), type: 'str', name: 'product.name'},
        created_at: {title: this.t('form.createdDate'), type: 'str', name: 'created_at'},
      },
      bodyData: [],
      navItems: [],
      allCount: 1,
      postPerPage: 50,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route(to, from,) {
      if (to.name != from.name) {
        this.currentPage = 1;
      }
      this.currentPage = this.$route.query.page;
      this.searchVal = this.$route.query.q;
      this.loadData();
    },
  },
  beforeMount() {
    this.currentPage = this.$route.query.page || 1;
    this.searchVal = this.$route.query.q || null;
    const val = parseInt(this.$route.query.status);
    if(isNaN(val)) {
      this.currentStatus = null
    } else {
      this.currentStatus = val;
    }
  },
  methods: {
    toggleDetailModal(uuid) {
      this.showDetailModal = !this.showDetailModal;
      this.currentDealUUID = uuid;
    },
    rowUpdateData(ind, data,) {
      this.bodyData[ind] = {
        ...this.bodyData[ind], 
        ...data,
      };
    },
    onStatusChoosed(val) {
      this.currentStatus = val;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          status: this.currentStatus,
        },
      });
      this.loadData();
    },
    updateNavItems(count) {
      const countData = count;
      const pages = Math.ceil(countData / this.postPerPage);
      this.navItems = [];
      for (let index = 0; index < pages; index++) {
        this.navItems.push(index + 1);
      }
      if (pages == 0) {
        this.navItems.push(1);
      }
    },
    setSearch(val) {
      this.searchVal = val; 
      this.loadData();
    },
    async loadData() {
      await axios({
        method: "GET",
        params: {
          p: this.currentPage,
          q: this.searchVal,
          status: this.currentStatus,
          is_active: 1,
        },
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.deals.base,
      }).then((e) => {
        const data = e.data.data
        this.bodyData = data.results;
        this.allCount = data.count;
        this.updateNavItems(data.count);
      }).catch((e) => {
        console.log(e);
        const data = e.response.data;
        if(e.response.status == 404) {
          this.openErrorModal({
            title: '404',
            description: data.errors.detail,
          });
          this.bodyData = [];
          this.allCount = 0;
          this.updateNavItems(0);
        }
      });
    },
  },
};
</script>
