<template>
  <div>
    <div class="app-header__logo">
      
      <div class="inl-100 flex justify-start items-center">
        <div class="logo-main flex items-center">
          <a 
            href="/">
            <img  
              class="logo-standard"
              v-if="!factorUser.factor_company.logo"
              src="/assets/images/icons/logo_placeholder.png" alt="">
            <img
              :class="[
                logoClass,
              ]"
              v-else
              :src="MEDIA_HOST + factorUser.factor_company.logo" alt="">
          </a>
        </div>
        <div 
          v-if="logoClass != 'logo-rectangle'"
          class="margin-l-15 logo-text f-16 font-bold margin-r-15">
            EZ Factor
        </div>
      </div>

      <div class="header__pane ms-auto">
        <div>
          <button
            @click="toggleisNavOpen"
            type="button"
            :class="[
              !isNavOpen ? 'is-active' : ''
            ]"
            class="hamburger hamburger--elastic"
            data-class="closed-sidebar"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button
          type="button"
          class="hamburger hamburger--elastic mobile-toggle-nav"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <span>
        <button
          type="button"
          class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
        >
          <span class="btn-icon-wrapper">
            <i class="fa fa-ellipsis-v fa-w-6"></i>
          </span>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { MEDIA_HOST } from '@/const/uri.dev';
import { mapMutations, mapState } from 'vuex';


export default {
  data() {
    return {
      MEDIA_HOST,
      someText: `<script>alert(123)<%0a/script>`,
    };
  },
  computed: {
    ...mapState(['isNavOpen', 'factorUser',]),
    logoClass() {
      if(!this.factorUser.factor_company) return '';
      if(!this.factorUser.factor_company.logo_size) return 'logo-standard';
      const {width, height} = this.factorUser.factor_company.logo_size;
      let diff, isrectangle = false;
      if(width > height) {
        diff = height * 100 / width;
      }
      if(diff < 80) {
        isrectangle = true;
      }
      if(isrectangle) return 'logo-rectangle';
      return 'logo-standard';
    },
  }, 
  methods: {
    ...mapMutations(['toggleisNavOpen', ])
  },
  props: {},
}
</script>
