<template>
  <div>
    <ErrorModalCenter
      v-if="errorModalCenterActive"
      :errorModalData="errorModalData"
      :closeErrorModal="closeErrorModal"
    />
    <router-view></router-view>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import ErrorModalCenter from "@/components/Modals/ErrorModalCenter.vue";

export default {
  components: {
    ErrorModalCenter,
  }, 
  data() {
    return {
      errorModalCenterActive: false,
      currentLng: 'ru',
    };
  },
  computed: {
    ...mapState(['isTokenChecked']),
  },
  methods: {
    openErrorModal(obj){
      this.errorModalCenterActive = true;
      this.errorModalData = obj;
    }, 
    closeErrorModal() {
      this.errorModalCenterActive = false; 
      this.errorModalData = {
        title: null,
        description: null,
      };
    },
    toggleErrorModal(obj) {
      this.errorModalCenterActive = true;
      this.errorModalData = obj;
      setTimeout(() => {
        this.closeErrorModal();  
      }, 2100);
    },
  },
  provide() {
    return {
      closeErrorModal: this.closeErrorModal,
      openErrorModal: this.openErrorModal,
      toggleErrorModal: this.toggleErrorModal,
    };
  },
}
</script>