<template>
  <div class="h-100-p w-100">
    <input
      @change="onFileChanged"
      type="file"
      ref="fileInput"
      class="abs-input"
    >
    <div class="h-100-p w-100 flex justify-center items-center relative overflow-y-scroll scroll-styling">
      <div class="docx-template docx-template-block">

        <div v-if="isLoading" class="">
          <img src="/assets/images/loading/loader-black-rounded.svg" alt="" />
        </div>

        <div class="padding-bt-100">
          <div class="document-block modal-document-block"></div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { formShowError } from '@/utils/erros';
import { API_FACTOR, HEADERS_TOKEN_JSON, HEADERS_TOKEN_JSON_FILE } from '@/const/uri';
import { useI18n } from "vue-i18n";
import axios from 'axios';
import { renderAsync } from "docx-preview";
import { NCALayerClient } from '@/lib/ncalayer-client.js';

export default {
  inject: [
    'openErrorModal',
  ],
  setup() {
    const { t } = useI18n();
    return { t };
  },
  data() {
    return {
      isLoading: true, 
      blobData: null,
      signature: null,
      waiting: false,
    };
  },
  mounted() {
    this.getFile()
  },
  methods: {
    onFileChanged(event) {
      const file = event.target.files[0]; 
      if (file) {
        this.updateDocxRequest(file);
      }
    },
    async getBlobFileName(response) {
      let fileName = "";
      console.log(response.headers);
      const contentDisposition = response.headers['content-disposition'];
      if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
          if (fileNameMatch.length === 2) {
              fileName = fileNameMatch[1];
          }
      }
      return fileName;
    },
    async getPdf() {
      try {
        const response = await axios({
          url: API_FACTOR.deals.sign + this.$props.dealUUID + "/register-pdf/",
          method: "GET",
          headers: HEADERS_TOKEN_JSON_FILE(),
          responseType: 'blob', // Important to specify the response type as blob
          params: {
            client_type: this.$props.clientType,
          },
        });
        const blobData = response.data;
        const fileName = await this.getBlobFileName(response);
        return [blobData, fileName];
      } catch (e) {
        formShowError.bind(this)(e);
      }
    },    
    async signPdf() {
      this.signature = null;
      this.waiting = true;
      let blobData, fileName;
      try {
        [blobData, fileName] = await this.getPdf()
      } catch (error) {
        formShowError.bind(this)(error);
        return;
      }
      const ncalayerClient = new NCALayerClient();
      try {
        await ncalayerClient.connect();
      } catch (error) {
        alert(`${this.t('sign.ncalyerConnectCancelled')}: ${error.toString()}`);
        return;
      }
      const file = new File([blobData,], fileName, { type: blobData.type });
      try {
        this.signature = await ncalayerClient.basicsSignCMS(
          NCALayerClient.basicsStorageAll,
          file,
          NCALayerClient.basicsCMSParamsDetached,
          NCALayerClient.basicsSignerSignAny,
        );
        this.waiting = false;
        try {
          await this.signDocxRequest(this.signature);
          return 1;
        } catch(e) {
          formShowError.bind(this)(e);
          return 0;
        }
      } catch (error) {
        if (error.canceledByUser) {
          this.openErrorModal({
              title: "400",
              description: this.t('sign.actionWasCancelled'),
          });
        } else {
          this.openErrorModal({
              title: "400",
              description: error.toString(),
          });
        }
        return;
      }
    },
    async signDocxRequest(sign) {
      return await axios({
        url: API_FACTOR.deals.sign + this.$props.dealUUID + "/sign-pdf/",
        method: "post",
        headers: HEADERS_TOKEN_JSON(),
        params: {
          client_type: this.$props.clientType,
        },
        data: {
          sign,
        }, 
      });
    },
    async downloadDocx(docName) {
      try {

        const blob = this.blobData;
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        link.href = url;
        link.setAttribute('download', `${docName}.docx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      } catch (error) {
        console.error('Error downloading the file', error);
      }
    },
    updateDocx() {
      this.$refs.fileInput.click();
    },
    async updateDocxRequest(file) {
      this.isLoading = true;
      document.querySelector(".document-block").innerHTML = '';
      const data = {};
      const key = this.$props.clientType == 'buyer' ? 
        'buyer_contract' : 'supplier_contract';
      data[key] = file;
      await axios({
        url: API_FACTOR.deals.sign + this.$props.dealUUID + "/docx/",
        method: "PUT",
        headers: HEADERS_TOKEN_JSON_FILE(),
        responseType: "arraybuffer",
        params: {
          client_type: this.$props.clientType,
        },
        data: data, 
      })
      .then((e) => {
        const blobData = new Blob([e.data], {
          type: "application/octet-stream",
        });
        this.blobData = blobData;
        renderAsync(
          blobData,
          document.querySelector(".document-block")
        ).then(() => {
          this.isLoading = false;
        });
      })
      .catch((e) => {
        formShowError.bind(this)(e);
      });
    },
    async generateDocx() {
      this.getFile(true); 
    },
    async getFile(isGenerate=false,) {
      this.isLoading = true;
      document.querySelector(".document-block").innerHTML = '';
      const params = {
        client_type: this.$props.clientType,
      };
      if(isGenerate)
        params['generate'] = 1;
      await axios({
        url: API_FACTOR.deals.sign + this.$props.dealUUID + "/docx/",
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        responseType: "arraybuffer",
        params,
      })
      .then((e) => {
        const blobData = new Blob([e.data], {
          type: "application/octet-stream",
        });
        this.blobData = blobData;
        renderAsync(
          blobData,
          document.querySelector(".document-block")
        ).then(() => {
          this.isLoading = false;
        });
        
      })
      .catch((e) => {
        formShowError.bind(this)(e);
      });
    },
  },
  props: {
    dealUUID: {
      default: null,
    },
    clientType: {
      default: "buyer",
    },
  },
};
</script>