<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div class="">
      <div 
        style="z-index: 99999; left: 60%;"
        class="ez-modal-height ez-modal-top inl-40 transition duration-150 ease-in-out absolute right-0 left-0 rounded-lg">
        <form
          class="h-100-p flex"
          action="">
          <div class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            
            <div class="text-gray-800 f-20 text-center font-bold tracking-normal leading-tight mb-4">
              {{ t('navigation.changeSement') }} {{ segmentName() }} <br>
              {{ t('navigation.addingCompanies') }}
            </div>
            
            <button
              @click="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>
            
            <div class="justify-center h-100-p inl-100 flex flex-col text-left relative">
              <div>
                <model-select 
                    v-if="emptyCompanies && emptyCompanies.length > 0"
                    :options="getSelectOptionsBase(
                      emptyCompanies,
                      'uuid',
                      'name_short',
                    )"
                    v-model="activeCompanyId"
                    :placeholder="t('form.selectCompany')">
                </model-select>
              </div>

              <div 
                v-if="addedCompanies"
                class="margin-t-30 margin-bt-15">
                <div class="scroll-styling overflow-y-scroll h-300 max-w-md divide-y divide-gray-200 dark:divide-gray-700 pr-3">

                  <div 
                    v-for="(el, ind) in addedCompanies" :key="ind"
                    class="px-1 py-1 sm:pb-4 border-1 margin-bt-10 rounded-sm">
                    <div 
                      class="flex items-center space-x-4 rtl:space-x-reverse">
                      <div class="flex-1 min-w-0">
                          <div class="text-sm font-medium text-gray-900 truncate dark:text-white">
                            {{ t('form.shortName') }}: {{ el.name_short }}
                          </div>
                          <div class="text-sm text-gray-500 truncate dark:text-gray-400">
                            {{ t('form.bin') }}: {{ el.bin }}
                          </div>
                      </div>

                      <div 
                        @click="() => {
                          deleteData(el.index,);
                        }"
                        class="pointer inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        <svg class="w-6 h-6 cursor text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                      </div>

                    </div>
                  </div>

                </div>
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  @click.prevent="saveData"
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">
                  {{ t('actions.save') }}
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
    
  </div>
</template>

<script>

import axios from 'axios';
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
import { fromErrorToHtml } from "@/utils/erros.js";
import { ModelSelect } from 'vue-search-select';
import { getSelectOptionsBase } from '@/utils/base.js';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal', 
    'openSubmitModal',
    'closeSubmitModal',
  ],
  components: {
    ModelSelect,
  },
  beforeMount() {
    this.getData();
  },
  data() {
    return {
      activeIds: [],
      activeCompanyId: null,
      modalData: [],
    };
  },
  computed: {
    addedCompanies() {
      const data = this.modalData.filter((el) => el.company)
      return data;
    },
    emptyCompanies() {
      const data = this.modalData.filter((el) => !el.company)
      return data;
    },
  },
  watch: {
    activeCompanyId(to) {
      if(to === null) return;
      this.onChangeData(to);
    }, 
  },
  methods: {
    getSelectOptionsBase,
    fromErrorToHtml,
    removeItem(array, item){
        for(var i in array){
            if(array[i]==item){
                array.splice(i,1);
                break;
            }
        }
    },
    deleteData(ind,) {
      this.modalData[ind].company = null; 
      this.modalData[ind].is_new = !this.modalData[ind].is_new; 
    },
    onChangeData(to) {
      for (let index = 0; index < this.modalData.length; index++) {
        if(this.modalData[index].uuid == to) {
          this.modalData[index].company = to;
          this.modalData[index].is_new = !this.modalData[index].is_new; 
          break;
        }
      }
    },
    async saveData() {
      await axios({
        method: "POST",
        headers: HEADERS_TOKEN_JSON(),
        params: {
          segment: this.segmentUUID,
        }, 
        data: {
          part_data: this.modalData.filter((el) => el.is_new ),
        },
        url: API_FACTOR.segmentCompany.base,
      }).then(() => {
        this.openSubmitModal({
          title: this.t('actions.companyAdded'),
          description: ``,
          btnFirst: this.t('actions.close'),
          btnSecond: this.t('actions.stay'),
          btnFirstColor: 'green',
        }, () => {
          this.$props.toggleModal()
          this.closeSubmitModal();
        });
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                {
                  field_name: 'name', 
                  label: this.t('form.title'),
                },
              ],
            ), 
          });
        }
      });
    },
    async getData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        params: {
          segment: this.segmentUUID,
        }, 
        url: API_FACTOR.segmentCompany.base,
      }).then((e) => {
        const data = e.data.data;
        data.forEach((el, index) => {
          this.modalData.push({
            ...el,
            index,
          });
        });
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                {
                  field_name: 'name', 
                  label: this.t('form.title'),
                },
              ],
            ), 
          });
        }
      });
    }, 
  },
  props: {
    segmentUUID: {},
    segmentName: {},
    toggleModal: {},
  },
};
</script>