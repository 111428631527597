<template>
  <div class="">
    <div class="app-page-title flex items-center z-100">
      <div class="flex items-center justify-between inl-100">

        <div class="flex items-center">
          <div class="page-title-wrapper margin-r-30">
            <div class="page-title-heading">
              <div class="page-title-icon">
                <img src="/assets/images/icons/credit-score.png" alt="" />
              </div>
              <div>
                {{ t('navigation.limits') }}
                <div class="page-title-subheading">{{ t('navigation.orderList') }}</div>
              </div>
            </div>
          </div>

          <Search :startVal="searchVal" />

          <div class="nav-buttons margin-l-10">
            <ul
              class="body-tabs body-tabs-layout tabs-animated body-tabs-animated nav"
            >
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'LimitsList',
                  }"  
                  v-slot="{ href, navigate }">
                <div
                  class="nav-link nav-btn show"
                  :href="href"
                  @click="navigate">
                  <span class="">{{ t('navigation.list') }}</span>
                </div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{
                    name: 'LimitsOrder',
                  }"  
                  v-slot="{ href, navigate }">
                <div
                  class="nav-link nav-btn show active"
                  :href="href"
                  @click="navigate">
                  <span class="">{{ t('navigation.orders') }}</span>
                </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>

        <!-- right block -->
        <div class="flex items-center">
          <button @click="toggleLimitModal">
            <div 
              class="relative add-button rounded-button pointer">
              <svg class="center-block w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path class="text-white" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16"></path>
              </svg>
            </div>
          </button>
        </div>

      </div>
    </div>

    <div class="fix-templ-row">
      <div class="col-lg-12">
        <div class="card fix-table-row">
          <div class="card-body relative">
            <AdminTable
              :updateData="updateRowData"
              :currentPage="currentPage"
              :postPerPage="postPerPage"
              :keysOrder="keysOrder"
              :fieldWidth="fieldWidth"
              :headerKeys="headerKeys"
              :bodyData="bodyData"
            />
            <TableNav
              :currentPage="currentPage"
              :navItems="navItems"
              :allCount="allCount"
              style="width: 330px;"
            />
          </div>
        </div>
      </div>
    </div>

    <slot name="rightModal">
      <LimitOrderModal 
        v-if="showLimitModal"
        :updateData="addRow"
        :toggleModal="toggleLimitModal"
      />
    </slot>

  </div>
</template>

<script>
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import axios from "axios";
import AdminTable from '@/components/Admin/TableLimitOrders.vue';
import TableNav from '@/components/Admin/TableNav.vue';
import LimitOrderModal from '@/components/Modals/EditLimitOrderModal.vue';
import Search from '@/components/Admin/Search.vue';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },

  inject: [
    'openErrorModal',
    'toggleSmModal',
  ],
  components: {
    AdminTable, TableNav,
    Search,
    LimitOrderModal,
  },
  computed: {
    filterStatuses() {
      const arr = this.headerKeys.status.vals;
      return [{
        'key': -1,
        'val': this.t('navigation.all'),
      }, ...arr];
    },
  }, 
  data() {
    return {
      showLimitModal: false,
      isLoad: false,
      keysOrder: [
        'order_id', 'company',
        'total_limit', 'transaction_limit',
        'justification',
        'limit_date_start_at', 'date_created',
      ],
      fieldWidth: {
        'company.name_short': 10,
        order_id: 10,
        total_limit: 10,
        transaction_limit: 10,
        justification: 20,
        date_created: 10,
        limit_date_start_at: 15,
      }, 
      currentPage: 1,
      searchVal: null,
      headerKeys:  {
        company: {title: this.t('company.title'), type: 'json', name: 'company.name_short'},
        order_id: {title: this.t('company.number'), type: 'str', name: 'order_id'},
        justification: {title: this.t('company.сlarification'), type: 'str', name: 'justification'},
        total_limit: {title: this.t('company.totalLimit'), type: 'int', name: 'total_limit'},
        transaction_limit: {title: this.t('company.dealLimit'), type: 'int', name: 'transaction_limit'},
        limit_date_start_at: {title: this.t('form.startDate'), type: 'date', name: 'limit_date_start_at'},
        date_created: {title: this.t('company.orderDate'), type: 'date', name: 'date_created'},
      },
      bodyData: [],
      navItems: [],
      allCount: 1,
      postPerPage: 50,
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    $route(to, from,) {
      if (to.name != from.name) {
        this.currentPage = 1;
      }
      this.currentPage = this.$route.query.page;
      this.searchVal = this.$route.query.q;
      this.loadData();
    },
  },
  beforeMount() {
    this.currentPage = this.$route.query.page || 1;
    this.searchVal = this.$route.query.q || null;
  },
  methods: {
    updateRowData(ind, data) {
      this.bodyData[ind] = {
        ...this.bodyData[ind], 
        ...data,
      };
    }, 
    addRow(data) {
      if(this.bodyData.length >= this.postPerPage) {
       this.bodyData.splice(-1, 1,); 
      }
      this.bodyData = [data, ...this.bodyData];
    },
    toggleLimitModal() {
      this.showLimitModal = !this.showLimitModal;
    },
    updateNavItems(count) {
      const countData = count;
      const pages = Math.ceil(countData / this.postPerPage);
      this.navItems = [];
      for (let index = 0; index < pages; index++) {
        this.navItems.push(index + 1);
      }
      if (pages == 0) {
        this.navItems.push(1);
      }
    },
    setSearch(val) {
      this.searchVal = val; 
      this.loadData();
    },
    async loadData() {
      await axios({
        method: "GET",
        params: {
          p: this.currentPage,
          q: this.searchVal,
        },
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.limit.order,
      }).then((e) => {
        const data = e.data.data
        this.bodyData = data.results;
        this.allCount = data.count;
        this.updateNavItems(data.count);
      }).catch((e) => {
        console.log(e);
        const data = e.response.data;
        if(e.response.status == 404) {
          this.openErrorModal({
            title: '404',
            description: data.errors.detail,
          });
          this.bodyData = [];
          this.allCount = 0;
          this.updateNavItems(0);
        }
      });
    },
  },
};
</script>