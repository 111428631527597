<template>
  <div>
    <table v-if="bodyData.length > 0" class="mb-0 table table-striped">
      <thead>
        <tr>
          <th class="inl-5">#</th>
          <th 
            :class="[
              getPageWidth(headerKeys[key].name)
            ]"
            v-for="(key, ind) in keysOrder" :key="ind">
            {{ headerKeys[key] ? headerKeys[key].title : "" }}
          </th>
        </tr>
      </thead>
    </table>

    <div class="table-scroll scroll-styling">
      <table v-if="bodyData.length > 0" 
        class="mb-0 table table-striped">
        <tbody>
          <tr v-for="(data, ind) in bodyData" :key="ind">
            <th 
            class="inl-5" 
            scope="row">
              {{ ind+1+ ((currentPage-1) * postPerPage) }}
            </th>
            <td 
              :class="[
                getPageWidth(headerKeys[key].name)
              ]"
              v-for="(key, keyInd) in keysOrder" :key="keyInd">
              <div v-if="navData && navData.navField == headerKeys[key].name">
                <router-link
                  :to="getNavData(data,)"  
                  v-slot="{ href, navigate}">
                  <div 
                    :href="href"
                    @click="navigate"
                    class="font-bold">
                      {{ getVal(headerKeys[key].name, headerKeys[key].type, data) }} <br>
                  </div>
                </router-link>
              </div>
              <div v-else>
                {{ getVal(headerKeys[key].name, headerKeys[key].type, data) }} <br>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
import { getValueFromObject, beautifyInteger, } from '@/utils/base';

export default {
  data() {
    return {};
  },
  methods: {
    getNavData(data,) {
      const params = {};
      params[this.$props.navData.urlKey] = data[this.$props.navData.key];
      return {
        name: this.$props.navData.urlName,
        params,
      };
    },
    getPageWidth(key) {
      let val = this.fieldWidth[key];
      if (!val) return "";
      return "inl-" + val;
    },
    getVal(key, type, data,) {
      const val = getValueFromObject(data, key, type,)
      if (!val) return "-"
      if(type == 'int' || type == 'float') return beautifyInteger(val);
      return val;
    },
  },
  props: {
    keysOrder: {
      default: [],
    },
    fieldWidth: {
      default: {},
    },
    headerKeys: {
      default: {},
    },
    addIndex: {
      default: 0,
    },
    currentPage: {
      default: 1,
    },
    postPerPage: {
      default: 0,
    },
    bodyData: {
      default: [],
    },
    navData: {
      default: {},
    },
  },
};
</script>