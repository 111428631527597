import App from "@/App.vue";
import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import i18n from "./i18n" 

import "vue-search-select/dist/VueSearchSelect.css";
import '@vuepic/vue-datepicker/dist/main.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import '@/assets/styles/css/index.css';

import Admin from "@/layouts/AdminBase.vue";
import AdminInner from "@/layouts/AdminInner.vue";
import СlientIndex from "@/views/Admin/Clients/Index.vue";
import СlientCreate from "@/views/Admin/Clients/Create.vue";
import CreateCompany from "@/views/Admin/Clients/CreateCompany.vue";
import AuthLogin from '@/views/Admin/Auth/Login.vue';
import Auth from "@/layouts/Auth.vue";

import ProductsIndex from '@/views/Admin/Products/Index.vue';
import CardsIndex from '@/views/Admin/Cards/Index.vue';

import LimitsIndex from '@/views/Admin/Limits/Index.vue';
import LimitsList from '@/views/Admin/Limits/List.vue';
import LimitsOrders from '@/views/Admin/Limits/Orders.vue';

import DealsIndex from '@/views/Admin/Deals/Index.vue';
import DealsActive from '@/views/Admin/Deals/Active.vue';
import DealsBid from '@/views/Admin/Deals/Bid.vue';

import ProfileIndex from '@/views/Admin/Profile/Index.vue';
import ProfileUser from '@/views/Admin/Profile/User.vue';
import ProfileCompany from '@/views/Admin/Profile/Company.vue';
import SmartContractsIndex from '@/views/Admin/SmartContracts/Index.vue';
import SignIndex from '@/views/Client/Sign/Index.vue';


// routes

const routes = [
  {
    path: "/client/sign/:uuid",
    component: AdminInner,
    name: "AdminInner",
    children: [
      {
        path: "/client/sign/:uuid",
        component: SignIndex,
        name: 'SignIndex'
      },
    ]
  },
  {
    path: "",
    redirect: "",
    component: Admin,
    name: "EmptyAdmin",
    children: [
      {
        path: "/client/company",
        component: СlientIndex,
        name: 'ClientIndex'
      },
      {
        path: "/client/company/create",
        component: СlientCreate,
        name: 'СlientCreate',
        children: [
          {
            path: "/client/company/create",
            component: CreateCompany,
            name: 'CreateCompany',
          },
          {
            path: "/client/company/:companyUUID",
            component: CreateCompany,
            name: 'ClientCompanyId',
          },
        ], 
      },
      {
        path: "/products/",
        component: ProductsIndex,
        name: 'ProductsIndex',
      },
      {
        path: "/limits",
        component: LimitsIndex,
        name: 'LimitsLayout',
        children: [
          {
            path: "/limits",
            component: LimitsList,
            name: 'LimitsList',
          },
          {
            path: "/limits/orders",
            component: LimitsOrders,
            name: 'LimitsOrder',
          },
        ],
      },
      {
        path: "/deals/",
        component: DealsIndex,
        name: 'DealsIndex',
        children: [
          {
            path: "/deals/active",
            component: DealsActive,
            name: 'DealsActive',
          },
          {
            path: "/deals/bid",
            component: DealsBid,
            name: 'DealsBid',
          },
        ], 
      },
      {
        path: "/smart-contracts/",
        component: SmartContractsIndex,
        name: 'SmartContractsIndex',
      },
      {
        path: "/profile/",
        component: ProfileIndex,
        name: 'ProfileIndex',
        children: [
          {
            path: "/profile/user",
            component: ProfileUser,
            name: 'ProfilUser',
          },
          {
            path: "/profile/company",
            component: ProfileCompany,
            name: 'ProfilCompany',
          },
        ], 
      },
      {
        path: "/cards",
        component: CardsIndex,
        name: 'CardsIndex',
      },
      {
        path: "/test/",
        component: LimitsIndex,
        name: 'TestIndex',
      },
    ]
  },
  {
    path: "/auth",
    redirect: "/",
    component: Auth,
    children: [
      {
          path: "/auth",
          component: AuthLogin,
          name: 'AuthLogin'
        },
    ]      
  },
  { path: "/:pathMatch(.*)*", redirect: "/client/company" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
}); 

// to, from, next
router.beforeEach(() => {
  // const token = localStorage.getItem('user_token')
  // if (token || [].includes(
  //   'Login', 'AcivateUser',
  // )) {
  //   next()
  // } else {
  //   next({name: 'Login'})
  // }
}); 

import user from '@/store/user.js';

createApp(App)
  .use(user)
  .use(i18n)
  .provide('$router', router)
  .use(router)
  .mount("#app");
