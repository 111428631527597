<template>
  <div class="">
    <div class="app-page-title flex items-center z-100">
      <div class="flex items-center justify-between inl-100">

        <div class="flex items-center">
          <div class="page-title-wrapper margin-r-30">
            <div class="page-title-heading">
              <div class="page-title-icon">
                <img :src="companyLogo" alt="" />
              </div>
              <div>
                {{ t('navigation.companySettings') }}
              </div>
            </div>
          </div>
          <div class="margin-l-10 margin-r-20"></div>
          <!-- nav -->
          <div class="nav-buttons margin-l-10"></div>
        </div>

        <!-- right block -->
        <div class="flex items-center"></div>

      </div>
    </div>

    <div class="fix-templ-row">
      <div class="col-lg-12">
        <div class="card fix-table-row">
          <div class="card-body relative">

            <div class="h-full fix-block-full scroll-styling">

              <div class="w-full md:w-3/5 pt-2 pb-4 pr-4 pl-4 bg-white lg:ml-4 shadow-md">

                <div class="flex">
                  <div class="flex img-update margin-r-30">
                    <div 
                      @click="openPhoto"
                      class="relative user-img-wr">
                      <input  
                        class='abs-block'
                        @change="onFileChanged"
                        ref="inputFile"
                        type="file">
                      <div class="p-img-placeholder">
                        <div v-html="plusIcon(5, 5, 'text-black')" class="icon center-block"></div>
                      </div>
                      <img
                        class="items-center user-image" 
                        :src="companyLogo"
                        alt="">
                    </div>
                  </div>

                  <div class="pb-6 inl-100 pl-6 p-user-form">
                    <form action="">

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.bin') }}</label>
                        <div class="flex">
                          <input
                            v-model="company.bin"
                            name="bin"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.binHelp')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.bik') }}</label>
                        <div class="flex">
                          <input
                            v-model="company.bik"  
                            name="bik"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.bikHelp')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.companyName') }}</label>
                        <div class="flex flex-col">
                          <input
                            v-model="company.name.ru"  
                            name="name.ru"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.enterCompanyName')"
                          />
                          <input
                            v-model="company.name.en"
                            name="name.en"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.enterCompanyName')"
                          />
                          <input
                            v-model="company.name.kz"
                            name="name.kz"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.enterCompanyName')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.shortCompanyName') }}</label>
                        <div class="flex flex-col">
                          <input
                            v-model="company.name_short.ru"
                            name="s_name.ru"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.enterCompanyName')"
                          />
                          <input
                            v-model="company.name_short.en"  
                            name="s_name.en"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.enterCompanyName')"
                          />
                          <input
                            v-model="company.name_short.kz"
                            name="s_name.kz"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.enterCompanyName')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.phone') }}</label>
                        <div class="flex">
                          <input
                            v-model="company.phone"  
                            name="phone"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.phoneHelp')"
                          />
                        </div>
                      </div>
                      
                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.email') }}</label>
                        <div class="flex">
                          <input
                            v-model="company.email"  
                            name="email"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.emailHelp')"
                          />
                        </div>
                      </div>
                      
                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.iik') }}</label>
                        <div class="flex">
                          <input
                            v-model="company.iik"  
                            name="iik"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.iikHelp')"
                          />
                        </div>
                      </div>
                      
                      <div class="margin-t-30">
                        <button
                          @click.prevent="saveData"
                          class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">
                          {{ t('actions.save') }}
                        </button>
                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <slot name="rightModal">
    </slot>

  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { plusIcon } from '@/Icons/base.js';
import { MEDIA_HOST } from '@/const/uri.dev';
import { API_FACTOR, HEADERS_TOKEN_JSON, HEADERS_TOKEN_JSON_FILE } from '@/const/uri';
import { formShowError } from '@/utils/erros';
import axios from 'axios';
import { mapMutations } from 'vuex';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'toggleSmModal',
    'openErrorModal',
  ],
  data() {
    return {
      selectedImage: null,
      company: {
        logo: null, 
        name: {
          en: null,
          ru: null,
          kz: null,
        },
        name_short: {
          en: null,
          ru: null,
          kz: null,
        },
        email: null, 
        phone: null,
        bin: null,
        iik: null,
      }, 
    };
  },
  computed: {
    nameJSON() {
      return JSON.stringify(this.company.name);
    },
    nameShortJSON() {
      return JSON.stringify(this.company.name_short);
    },
    companyLogo() {
      if(this.selectedImage) return this.selectedImage;
      if(!this.company.logo) return '/assets/images/icons/logo_placeholder.png'; 
      return MEDIA_HOST + this.company.logo;
    },
  },
  mounted() {
    this.lodData();
  }, 
  methods: {
    ...mapMutations(['updateFactorLogoImage',]),
    openPhoto() {
      this.$refs.inputFile.click();
    },
    onFileChanged(e) {
      const input = e.target;
      var ext = input.files[0]['name'].substring(input.files[0]['name'].lastIndexOf('.') + 1).toLowerCase();
      if (input.files && input.files[0] && (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg")) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.company.logo = input.files[0];
          this.selectedImage = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    },
    plusIcon,
    async saveData() {
      await axios({
        url: API_FACTOR.company,
        method: "POST",
        data: {
          ...this.company,
          name: this.nameJSON,
          name_short: this.nameShortJSON,
        },
        headers: HEADERS_TOKEN_JSON_FILE(),
      }).then((e) => {
        const data = e.data.data;
        this.updateFactorLogoImage(
          {
              logo: data.logo, 
              name_short: data.name_short,
              name: data.name,
          }
        );
        this.toggleSmModal({
          description: this.t('modalMessages.companyProfileUpdated'),
          isAlert: false,
        });
      }).catch((e) => {
        console.log(e);
        this.isUpdate = false;
        formShowError.bind(this)(e);
      });
    },
    async lodData() {
      await axios({
        url: API_FACTOR.company,
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
      }).then((e) => {     
        this.company = e.data.data.company;
      }).catch((e) => {
        this.isUpdate = false;
        formShowError.bind(this)(e);
      });
    },
  },
};
</script>