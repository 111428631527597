<template>
  <div>
    <div class="-mx-4 flex flex-wrap p-8">
      <div class="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          class="mb-9 rounded-xl py-6 px-4 shadow-md transition-all hover:shadow-lg"
        >
          <div class="mx-auto mb-7 inline-block">
            <img 
              style="height: 65px; width: auto;"
              src="/assets/images/icons/commercial.png" alt="">
          </div>
          <div>
            <h3
              class="mb-4 text-black f-18"
            >
              {{ t('smartContract.title') }} <br>
              <b>{{ t('smartContract.tradeTransactionFor')  }}</b>
            </h3>
            <!-- <p class="text-base font-medium text-body-color">
              FormBold is free to use, we are offering a decent free plan for
              experiments, personal projects and projects.
            </p> -->
          </div>
        </div>
      </div>
      <div class="w-full px-4 md:w-1/2 lg:w-1/3">
        <div
          class="mb-9 rounded-xl py-6 px-4 shadow-md transition-all hover:shadow-lg"
        >
          <div class="mx-auto mb-7 inline-block">
            <img 
              style="height: 65px; width: auto;"
              src="/assets/images/icons/transaction.png" alt="">
          </div>
          <div>
            <h3
              class="mb-4 text-black f-18"
            >
              {{ t('smartContract.title') }} <br>
              <b>{{ t('smartContract.financeTransaction')  }}</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
export default { 
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
      
    };
  },
}
</script>