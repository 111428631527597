<template>
  <div>
    <div class="relative inl-block">
      <button
        @click="() => {isActive = !isActive;}"
        type="button"
        role="combobox"
        aria-controls="radix-:r1sk:"
        aria-expanded="false"
        aria-autocomplete="none"
        dir="ltr"
        data-state="closed"
        data-placeholder=""
        style="width: 200px;"
        class="flex h-10 w-full bg-white items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <span style="pointer-events: none">{{ selectedText }}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="h-4 w-4 opacity-50"
          aria-hidden="true"
        >
          <path d="m6 9 6 6 6-6"></path>
        </svg>
      </button>
      
      <div 
        v-if="isActive"
        class="ez-dropDownSelect pointer flex flex-col w-full bg-white items-center justify-between rounded-md border border-input bg-background py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50">
        
        <div class="ez-select-scrollable scroll-styling flex flex-col items-center justify-start inl-100">
          <div 
            class="px-3 py-1 inl-100 ez-optionValue word-break flex items-center "
            @click="selectValue(index)"
            v-for="(item, index) in listData" :key="index">
              <div class="word-break inl-75">{{ item[nameValue] }}</div>
              
              <div 
                v-if="canEdit"
                class="inl-25 flex justify-end items-center">
                <svg
                  @click.stop="() => {
                    updateData(item[nameKey]);
                  }"
                  class="ez-green-fill-hover margin-r-5 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"/>
                </svg>
                <svg
                  v-if="!item[canDeleteKey]"
                  @click.stop="() => {
                    deleteData(item[nameKey], item[nameValue]);
                  }"
                  class="ez-red-fill-hover w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                  <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd"/>
                </svg>
              </div>
            </div>
        </div>

        <div 
          v-if="canAdd"
          @click="addNew"
          class="px-3 py-1 inl-100 ez-optionValue padding-t-5 flex items-center ez-dropdown__add margin-t-10">
          <span 
            class="margin-r-5">{{ t('actions.add') }}</span>
          <svg class="w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5"/>
          </svg>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
      isActive: false,
      activeValue: null,
    };
  },
  watch: {
    defaultValue(to) {
      this.activeValue = to;
    }, 
  },
  mounted() {
    this.activeValue = this.$props.defaultValue;
  },
  computed: {
    selectedText() {
      if(typeof this.activeValue != 'undefined')
        for (let index = 0; index < this.listData.length; index++) {
          if(this.listData[index][this.$props.nameKey] == this.activeValue) {
            return this.listData[index][this.$props.nameValue];
          }
        }
      return this.t(this.$props.placeholder); 
    },
  },
  methods: {
    selectValue(index) {
      this.activeValue = this.listData[index][this.$props.nameKey];
      this.isActive = false;
      this.onChoosed(this.activeValue);
    },
    addNew() {
      this.isActive = false;
      this.addData();
    }
  },
  props: {
    canDeleteKey: {
      default: null,
    }, 
    deleteData: {
      default: () => {},
    },
    updateData: {
      default: () => {},
    }, 
    defaultValue: {},
    placeholder: {
      default: 'actions.select',
    },
    listData: {
      default: [],
    },
    onChoosed: {
      default: () => {},
    },
    nameValue: {},
    nameKey: {},
    addData: {
      default: () => {},
    },
    canAdd: {
      default: true,
    },
    canEdit: {
      default: true,
    },
  },
}
</script>