<template>
  <div class="nav-block flex-center right px-6 py-3">
    <div class="mr-5 f-14 mobile-hidden">
      {{ t('navigation.quantity') }} 
    </div>
    <div class="mr-5 f-14 desktop-hidden"></div>
    <div class="mr-5">
      <select
        @change="selectPage"
        class="bg-gray-50 border pr-5 bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      >
        <option
          v-for="(item, index) in navItems"
          :key="index + 1"
          :value="index + 1"
          :selected="currentPage == index + 1"
        >
          {{ index + 1 }}
        </option>
      </select>
    </div>
    <div class="count-data f-14 ml-4 mobile-hidden">
      <span v-if="currentPage == 1">1</span>
      <span v-else>{{ (currentPage - 1) * 50 }}</span>
      –
      <span v-if="currentPage * 50 > allCount">{{ allCount }}</span>
      <span v-else>{{ currentPage * 50 }}</span>
      {{ t('navigation.out') }} {{ allCount }}
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default { 
  setup() {
    const { t } = useI18n();
    return { t }
  },
  methods: {
    selectPage(e) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          page: e.target.value,
        }
      });
    },
  },
  props: {
    currentPage: {
      default: 1,
    },
    allCount: {
      default: 1,
    },
    navItems: {
      default: [],
    },
  }
}
</script>
