<template>
  <div class="app-header-right">
    <div class="widget-content-left ms-3 header-user-info flex flex-col justify-center items-center">
      <select
          @change="setLng"
          style="width: 65px; padding-left: 8px!important;"
          class="f-14 rounded-lg bg-gray-50 border bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1 pl-2" >
          <option :selected="acceptLanguage == 'ru'"  value="ru">RU</option>
          <option :selected="acceptLanguage == 'en'"  value="en">EN</option>
      </select>
    </div>

    <div class="header-btn-lg pe-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <div class="btn-group">
              <a
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="p-0 btn"
              >
                <i class="fa fa-angle-down ms-2 opacity-8"></i>
              </a>
              <div
                tabindex="-1"
                role="menu"
                aria-hidden="true"
                class="dropdown-menu dropdown-menu-right"
              >
              <router-link
                :to="{
                  name: 'ProfilUser',
                }"  
                v-slot="{ href, navigate }">
                <button 
                  :href="href"
                  @click="navigate"
                  type="button" tabindex="0" class="dropdown-item">
                  {{ t('navigation.profile') }}
                </button>
              </router-link>
              <router-link
                :to="{
                  name: 'ProfilCompany',
                }"  
                v-slot="{ href, navigate }">
                <button 
                  :href="href"
                  @click="navigate"
                  type="button" tabindex="0" class="dropdown-item">
                  {{ t('navigation.companySettings') }}
                </button>
              </router-link>
              </div>
            </div>
          </div>
          <div class="widget-content-left ms-3 header-user-info">
            <div class="widget-heading">{{ user.fio }}</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentLng } from '@/utils/base';
import { useI18n } from 'vue-i18n'
import { mapActions, mapState } from 'vuex';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  computed: {
    ...mapState(['user', 'acceptLanguage',]),
  },
  methods: {
    ...mapActions(['changeLanguage',]),
    getCurrentLng,
    setLng(e) {
      const value = e.target.value;
      this.changeLanguage(value);
      this.$i18n.locale = value;
      location.reload();
    },
  },
  data() {
    return {

    };
  }, 

}
</script>

