<template>
  <div class="overflow-hidden margin-bt-15">
    <h3 class="font-semibold text-lg">{{ product.name }}</h3>
    <h4 class="font-semibold f-14">{{ t("form.financeFeeRate") }}</h4>

    <div class="flex flex-wrap justify-start items-baseline margin-bt-15">
      <div class="f-12 inl-90 text-sm margin-bt-1 text-gray-500 flex">
        {{ t("form.maximumFinancingPeriod") }}: {{ product.max_day_amount }}
        {{ getCorrectEndingDay(product.max_day_amount) }}
      </div>
    </div>

    <div class="flex flex-wrap justify-start items-baseline">
      <div class="inl-48 text-sm flex flex-col margin-bt-1">
        <div class="margin-bt-5">{{ t("form.forSupplier") }}</div>
        <div
          class="flex flex-row flex-wrap f-12 items-center"
          v-for="(el, cInd) in supplierFilterData"
          :key="cInd"
        >
          <div class="text-gray-500 inl-48 f-12">
            {{ t("form.commission") }}: {{ el.comission_rate }}%
          </div>
          <div
            v-if="el.clarification"
            style="line-height: 13px"
            class="text-gray-500 inl-48 f-12"
          >
            {{ el.clarification }}
          </div>
        </div>
        <div class="flex flex-row">
          <div class="f-12 text-gray-500">
            {{ t("form.comissionType") }}:
            {{ product.supplier_comission_rule.val }}
          </div>
        </div>
      </div>
      <div
        class="inl-48 text-sm flex flex-col margin-bt-1 text-gray-500 justify-end"
      >
        <div class="margin-bt-5">{{ t("form.forBuyer") }}</div>
        <div
          class="flex flex-row flex-wrap f-12 items-center"
          v-for="(el, cInd) in disturbutorFilterData"
          :key="cInd"
        >
          <div class="text-gray-500 inl-48 f-12">
            {{ t("form.commission") }}: {{ el.comission_rate }}%
          </div>
          <div
            v-if="el.clarification"
            style="line-height: 13px"
            class="text-gray-500 inl-48 f-12"
          >
            {{ el.clarification }}
          </div>
        </div>
        <div class="flex flex-row">
          <div class="f-12 text-gray-500">
            {{ t("form.comissionType") }}:
            {{ product.supplier_comission_rule.val }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="margin-t-15"
      v-if="product.day_comission_supplier && product.day_comission_buyer"
    >
      <h4 class="font-semibold f-14">
        {{ t("form.amountOfDailyCommissionIncrease") }}
      </h4>
      <div class="flex flex-wrap justify-start items-baseline">
        <div class="inl-48 text-sm flex flex-col margin-bt-1">
          <div class="margin-bt-5">{{ t("form.forSupplier") }}</div>
          <div
            v-if="product.day_comission_supplier"
            class="text-gray-500 inl-48 f-12"
          >
            {{ product.day_comission_supplier }}%
          </div>
          <div v-else class="text-gray-500 inl-48 f-12">-</div>
        </div>
        <div
          class="inl-48 text-sm flex flex-col margin-bt-1 text-gray-500 justify-end"
        >
          <div class="margin-bt-5">{{ t("form.forBuyer") }}</div>
          <div
            v-if="product.day_comission_buyer"
            class="text-gray-500 inl-48 f-12"
          >
            {{ product.day_comission_buyer }}%
          </div>
          <div v-else class="text-gray-500 inl-48 f-12">-</div>
        </div>
      </div>
    </div>

    <h4 class="font-semibold f-14 margin-t-15">
      {{ t("form.procedureForPaymentOfTranches") }}
    </h4>

    <div class="flex flex-wrap justify-start items-baseline">
      <div class="f-12 inl-90 text-sm margin-bt-1 text-gray-500 flex">
        {{ t("form.firstTranche") }}: {{ product.first_tranche }}%
      </div>
      <div
        v-for="(el, ind) in product.tranchess"
        :key="ind"
        class="f-12 inl-90 text-sm margin-bt-1 text-gray-500 flex"
      >
        {{ t(getNumberName(ind + 2)) }} {{ t("form.tranche") }}:
        {{ el.percent }}%;
        <span class="f-12 text-gray-500 padding-l-3" v-if="el.day_limit"
          >{{ t("form.untilTheDay") }}: {{ el.day_limit }}</span
        >
      </div>
    </div>

    <h4 class="font-semibold f-14 margin-t-15">
      {{ t("form.contractTemplates") }}
    </h4>
    <div class="flex flex-wrap justify-start items-baseline">
      <div class="inl-48 text-sm margin-bt-1 text-gray-500 flex">
        <div
          class="flex flex-row items-center pointer mt-1"
          v-if="product.supplier_agreement"
        >
          <img
            :class="[product.status.key == 0 ? 'blue-icon-600' : '']"
            class="w-5 h-5 mr-1"
            src="/assets/images/icons/docx.png"
          />
          <span
            :class="[product.status.key == 0 ? 'text-blue-600' : '']"
            class="margin-r-3"
            >{{ t("form.debtor") }}</span
          >
        </div>
        <div v-else>
          <span class="">{{ t("form.debtor") }}</span>
          ({{ t("actions.notLoaded") }})
        </div>
      </div>

      <div class="inl-48 text-sm margin-bt-1 text-gray-500 flex justify-end">
        <div
          class="flex flex-row items-center pointer mt-1"
          v-if="product.buyer_agreement"
        >
          <img
            :class="[product.status.key == 0 ? 'blue-icon-600' : '']"
            class="w-5 h-5 mr-1"
            src="/assets/images/icons/docx.png"
          />
          <span
            :class="[product.status.key == 0 ? 'text-blue-600' : '']"
            class="margin-r-3"
            >{{ t("form.creditor") }}</span
          >
        </div>
        <div v-else>
          <span class="">{{ t("form.creditor") }}</span>
          ({{ t("actions.notLoaded") }})
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCorrectEndingDay, getNumberName } from "@/utils/base";
import { useI18n } from "vue-i18n";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  inject: ["openErrorModal", "openSubmitModal", "closeSubmitModal"],
  data() {
    return {};
  },
  computed: {
    supplierFilterData() {
      if (!this.$props.product.comissions) return [];
      return this.$props.product.comissions.filter((el) => {
        return el.commission_type.key == 0;
      });
    },
    disturbutorFilterData() {
      if (!this.$props.product.comissions) return [];
      return this.$props.product.comissions.filter((el) => {
        return el.commission_type.key == 1;
      });
    },
  },
  methods: {
    getCorrectEndingDay,
    getNumberName,
  },
  props: {
    product: {},
  },
};
</script>