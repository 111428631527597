<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div class="">
      <div 
        style="z-index: 99999; left: 60%;"
        class="ez-modal-height ez-modal-top inl-40 transition duration-150 ease-in-out absolute right-0 left-0 rounded-lg">
        <form
          class="h-100-p flex"
          @submit.prevent="saveData"
          action="">
          <div class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
            <div class="text-gray-800 f-20 font-bold tracking-normal leading-tight mb-4">
              <span v-if="!moddalSegment">{{ t('actions.createSegment') }}</span>
              <span v-else>{{ t('actions.updateSegment') }}</span>
            </div>
            <button
              @click="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <div
              class="justify-center h-100-p inl-100 flex flex-col text-left relative">
              <div>
                <div class="flex flex-col">
                  <label
                    class="text-gray-800 text-sm font-bold leading-tight tracking-normal"
                    >{{ t('form.title') }} <b>*</b></label>
                </div>
                <input
                  v-model="modalData.name"
                  class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  :placeholder="t('form.enterSegmentName')"
                />
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <button
                  class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm"
                >
                  {{ t('actions.save') }}
                </button>
              </div>
            </div>
          </div>
        </form>

      </div>
    </div>
    
  </div>
</template>

<script>
import axios from 'axios';
import { API_FACTOR, HEADERS_TOKEN_JSON } from '@/const/uri';
import { fromErrorToHtml } from "@/utils/erros.js";
import { getObjDataNotNull } from '@/utils/base';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: [
    'openErrorModal',
  ],
  data() {
    return {
      uuid: null,
      modalData: {
        name: null,
      }, 
    };
  },
  beforeMount() {
    if(this.$props.moddalSegment) {
      this.getData();
    }
  },
  methods: {
    fromErrorToHtml, getObjDataNotNull,
    async getData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.segment.base + this.moddalSegment + '/',
      }).then((e) => {
        this.modalData.name = e.data.data.name;
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                {
                  field_name: 'name', 
                  label: this.t('form.title'),
                },
              ],
            ), 
          });
        }
      });
    }, 
    async saveData() {
      const method = this.moddalSegment ? "PUT" : "POST";
      const url = this.moddalSegment ? 
        API_FACTOR.segment.base + this.moddalSegment + '/'  
        : API_FACTOR.segment.base;
      await axios({
        method, url,
        headers: HEADERS_TOKEN_JSON(),
        data: this.modalData,
      }).then((e) => {
        if(!this.$props.moddalSegment) {
          this.$props.updateData(e.data.data);
        } else {
          this.$props.changeData(e.data.data.name);
        }
        this.toggleModal();
      }).catch((e) => {
        const errorData = e.response.data;
        if([400, 404].indexOf(e.response.status) != -1)  {
          this.openErrorModal({ 
            title: '400',
            description: this.fromErrorToHtml(
              errorData, [
                {
                  field_name: 'name', 
                  label: this.t('form.title'),
                },
              ],
            ), 
          });
        }
      });
    }
  },
  props: {
    moddalSegment: {},
    toggleModal: {},
    changeData: {
      default: () => {},
    },
    updateData: {
      default: () => {},
    }
  },
};
</script>