<template>
  <div class="app-header-left">

    <!-- <div class="search-wrapper">
      <div class="input-holder">
        <input type="text" class="search-input" placeholder="Введите название" />
        <button class="search-icon"><span></span></button>
      </div>
      <button class="btn-close"></button>
    </div> -->

    <ul class="header-menu nav">
      <!-- 
      <li class="nav-item">
        <a href="javascript:void(0);" class="nav-link">
          <i class="nav-link-icon fa fa-database"> </i>
          Statistics
        </a>
      </li>
      <li class="btn-group nav-item">
        <a href="javascript:void(0);" class="nav-link">
          <i class="nav-link-icon fa fa-edit"></i>
          Projects
        </a>
      </li>
      <li class="dropdown nav-item">
        <a href="javascript:void(0);" class="nav-link">
          <i class="nav-link-icon fa fa-cog"></i>
          Settings
        </a>
      </li> 
      -->
      
    </ul>
  </div>
</template>