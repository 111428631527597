<template lang="">
  <div>
    <div class="app-header header-shadow">
        <HeaderLogo />
        <div class="app-header__content">
            <HeaderLeft  />
            <HeaderRight  />
        </div>
    </div>      
  </div>
</template>

<script>

import HeaderLogo from '@/components/Header/Logo.vue';
import HeaderLeft from '@/components/Header/HeaderLeft.vue';
import HeaderRight from '@/components/Header/HeaderRight.vue';

export default {
  components: {
    HeaderLogo,
    HeaderLeft,
    HeaderRight,
  }, 
}
</script>