
function getFieldName(fields, key,) {
    for (let index = 0; fields < fields.length; index++) {
        const el = fields[index];
        if(el.field_name == key) return el.label;
    }
}

function fromErrorToHtml(data, fields,) {
    let detaelMessage;
    if (data.errors) {
        detaelMessage = data.errors.detail
    } else {
        detaelMessage = data.detail;
    }
    if (
        typeof detaelMessage != 'undefined' 
        && detaelMessage
    ) return detaelMessage;
    const errors = data.errors || data;
    const ul = document.createElement('ul');
    for (const key in errors) {
        let label;
        if (fields && fields.length > 0) 
            label = getFieldName(fields, key);
        else
            label = null;
        const errorMessages = errors[key];
        if (errorMessages.length > 0) {
            var li = document.createElement('li');
            if (label) li.textContent = label + ': ';
            li.textContent +=  errorMessages.join(', ');
            ul.appendChild(li);
        }        
    }
    return ul.innerHTML;
}

function formShowError(e) {
    console.log(e);
    const errorData = e.response.data;
    this.isDataUpdate = false;
    if ([400, 404].indexOf(e.response.status) != -1) {
        this.openErrorModal({
            title: "400",
            description: fromErrorToHtml(errorData, this.formKeys),
        });
    }
}

export {
    fromErrorToHtml,
    formShowError,
}