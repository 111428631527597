<template>
  <div class="">
    <div class="blur-parent-bg rounded-lg"></div>
    <div class="">
      <div
        style="z-index: 99999; left: 60%"
        class="h-100-p top-0 inl-40 transition duration-150 ease-in-out absolute rounded-lg"
      >
        <form class="h-100-p flex" action="">
          <div
            class="h-100-p inl-100 flex flex-col items-center py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400"
          >
            <div
              class="text-gray-800 f-20 text-center font-bold tracking-normal leading-tight mb-4"
            >
              {{ t('navigation.addCounterparties') }}
            </div>

            <button
              @click.prevent="toggleModal"
              class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon icon-tabler icon-tabler-x"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                stroke-width="2.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </button>

            <div
              class="justify-center h-100-p inl-100 flex flex-col text-left relative"
            >
              <div class="margin-t-30 margin-bt-15">
                <div
                  class="scroll-styling overflow-y-scroll h-300 max-w-md divide-y divide-gray-200 dark:divide-gray-700 pr-3"
                >
                  <div
                    v-for="(el, ind,) in modalData"
                    :key="ind"
                    class="px-1 py-1 sm:pb-4 border-1 margin-bt-10 rounded-sm"
                  >
                    <div
                      class="flex items-center space-x-4 rtl:space-x-reverse"
                    >
                      <div class="flex-1 min-w-0">
                        <div
                          class="text-sm font-medium text-gray-900 truncate dark:text-white margin-bt-1"
                        >
                         {{ t('form.companyName') }}: {{ el.company_name.ru }}
                        </div>
                        <div
                          class="text-sm text-gray-500 truncate dark:text-gray-400 margin-bt-1"
                        >
                          {{ t('form.bin') }}: {{ el.bin }}
                        </div>
                        <div
                          class="text-sm text-gray-500 truncate dark:text-gray-400 margin-bt-1"
                        >
                          {{ t('form.phone') }}: {{ el.phone }}
                        </div>
                      </div>

                      <div
                        @click="
                          () => {
                            toggleInModal(el.uuid, ind,);
                          }
                        "
                        class="pointer inline-flex items-center text-base font-semibold text-gray-900 dark:text-white"
                      >
                        <svg
                          class="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m14.304 4.844 2.852 2.852M7 7H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-4.5m2.409-9.91a2.017 2.017 0 0 1 0 2.853l-6.844 6.844L8 14l.713-3.565 6.844-6.844a2.015 2.015 0 0 1 2.852 0Z"
                          />
                        </svg>
                      </div>
                      <div
                        @click="
                          () => {
                            deleteData(el.uuid, index);
                          }
                        "
                        class="pointer inline-flex items-center text-base font-semibold text-gray-900 dark:text-white"
                      >
                        <svg
                          class="w-6 h-6 cursor text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m15 9-6 6m0-6 6 6m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="flex items-center justify-start w-full margin-t-10">
                <div class="pointer flex flex-row items-center">
                  <button @click.prevent="() => {
                    toggleInModal();
                  }" class="">
                    <div class="relative add-button rounded-button pointer">
                      <svg
                        class="center-block w-6 h-6 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          class="text-white"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 1v16M1 9h16"
                        ></path>
                      </svg>
                    </div>
                  </button>
                  <div class="margin-l-15 f-15">{{ t('actions.add') }}</div>
                </div>
              </div>
              
            </div>
          </div>
        </form>
      </div>
    </div>

    <CenterModalEditCountrparty
      v-if="isModalOpen"
      :changeData="changeData"
      :addData="addData"
      :companyUUID="companyUUID"
      :modalUUID="modalUUID"
      :modalIndex="modalIndex"
      :toggleModal="toggleInModal"
      :companyTypes="companyTypes.vals"
      :genders="genders"
      :banks="banks"
    />

  </div>
</template>

<script>
import axios from "axios";
import { API_FACTOR, HEADERS_TOKEN_JSON } from "@/const/uri";
import { formShowError } from "@/utils/erros.js";
import { getSelectOptionsBase } from "@/utils/base.js";
import CenterModalEditCountrparty from "@/components/Modals/CenterModalEditCountrparty.vue";
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  inject: ["openErrorModal", "openSubmitModal", "closeSubmitModal"],
  components: {
    CenterModalEditCountrparty,
  },
  beforeMount() {
    this.getData();
  },
  data() {
    return {
      isModalOpen: false,
      modalUUID: null,
      modalIndex: null,
      genders: [],
      banks: [],
      activeIds: [],
      modalData: [],
      companyTypes: [],
    };
  },
  methods: {
    getSelectOptionsBase,
    toggleInModal(uuid, index) {
      this.modalUUID = uuid ? uuid : null;
      this.modalIndex = typeof index != 'undefined' ? index : null;
      this.isModalOpen = !this.isModalOpen;
    },
    changeData(data, index,) { 
      this.modalData[index] = {
        ...this.modalData[index],
        ...data,
      };
      this.modalData = [...this.modalData]
    },
    addData(data) {
      this.modalData = [
        data,
        ...this.modalData,
      ];
    },
    deleteData(uuid, ind,) {
      this.openSubmitModal({
        title: this.t('modalMessages.submitDeleteCountreparty'),
        description: ``,
        btnFirst: this.t('actions.submit'),
        btnSecond: this.t('actions.cancel'),
        btnFirstColor: 'red',
      }, () => {
        this.deleteDataRequest(uuid, ind,)
        this.closeSubmitModal();
      });
    }, 
    async deleteDataRequest(uuid, ind,) {
      await axios({
        method: "DELETE",
        headers: HEADERS_TOKEN_JSON(),
        params: {
          counterparty: uuid,
        },
        url: API_FACTOR.factor.company + this.$props.companyUUID + "/counterparty/",
      })
        .then(() => {
          this.modalData.splice(ind, 1,);
        })
        .catch(formShowError.bind(this));
    },
    removeItem(array, item) {
      for (var i in array) {
        if (array[i] == item) {
          array.splice(i, 1);
          break;
        }
      }
    },
    async getData() {
      await axios({
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
        url: API_FACTOR.factor.company + this.$props.companyUUID + "/counterparty/",
      })
        .then((e) => {
          const data = e.data.data;
          this.modalData = data.companies;
          this.companyTypes = data.company_types;
          this.genders = data.genders;
          this.banks = data.banks;
        })
        .catch(formShowError.bind(this));
    },
  },
  props: {
    toggleModal: {},
    companyUUID: {},
  },
};
</script>
