<template>
  <div class="flex flex-col">
    <div class="relative flex flex-row">
      <input 
        @change="onFileChanged"
        type="file"
        ref="fileInput"
        :name="name"
        :placeholder="placeholder" 
        class="form-control"
        :class="[
          addedCls,
        ]"
      >
      <div 
        @click="removeFile"
        class="margin-l-5 pointer" 
        v-if="showFileLink">
        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
          <path fill-rule="evenodd" d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z" clip-rule="evenodd"/>
        </svg>
      </div>
    </div>
    <div v-if="prevFile && showFileLink">
      <a 
        target="_blank"
        :href="MEDIA_HOST + fileLink">({{ t('actions.view') }})</a>
    </div>
  </div>
</template>

<script>
import { MEDIA_HOST } from '@/const/uri.dev';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();
    return { t }
  },
  data() {
    return {
      MEDIA_HOST,
      currentFile: null,
      fileChanged: false,
      showFileLink: false,
      fileLink: null,
    };
  },
  watch: {
    prevFile(to) {
      if(typeof to == 'string') {
        this.showFileLink = true;
        this.fileLink = to;
      }       
    },
  },
  mounted() {
    if(typeof this.$props.prevFile == 'string') {
      this.showFileLink = true;
      this.fileLink = this.$props.prevFile;
      this.currentFile = this.$props.prevFile;
    } 
  },
  methods: {
    onFileChanged(event) {
      const file = event.target.files[0]; 
      if (file) {
        this.currentFile = file;
        this.showFileLink = true;
        this.$props.setData(this.$props.nameKey, file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
      }
    },
    removeFile() {
      this.currentFile = null;
      this.$refs.fileInput.value = null;
      this.$props.setData(this.$props.nameKey, '');
      this.showFileLink = false;
    },
  },
  props: {
    prevFile: {
      default: null,
    },
    setData: {},
    nameKey: {
      default: null,
    },
    name: {},
    addedCls: {
      default: [],
    },
    placeholder: {},
  }
};
</script>