<template>
  <div class="">
    <div class="app-page-title flex items-center z-100">
      <div class="flex items-center justify-between inl-100">

        <div class="flex items-center">
          <div class="page-title-wrapper margin-r-30">
            <div class="page-title-heading">
              <div class="page-title-icon">
                <img src="/assets/images/icons/user.png" alt="" />
              </div>
              <div>
                {{ t('navigation.profile') }}
              </div>
            </div>
          </div>
          <div class="margin-l-10 margin-r-20"></div>
          <!-- nav -->
          <div class="nav-buttons margin-l-10"></div>
        </div>

        <!-- right block -->
        <div class="flex items-center"></div>

      </div>
    </div>

    <div class="fix-templ-row">
      <div class="col-lg-12">
        <div class="card fix-table-row">
          <div class="card-body relative">

            <div class="h-full fix-block-full scroll-styling">

              <div class="w-full md:w-3/5 pt-2 pb-4 pr-4 pl-4 bg-white lg:ml-4 shadow-md">
                <div class="text-2xl font-semibold margin-bt-30">{{ t('navigation.profileData') }}</div>

                <div class="flex">
                  <div class="flex img-update margin-r-30">
                    <div 
                      @click="openPhoto"
                      class="relative user-img-wr">
                      <input  
                        class='abs-block'
                        @change="onFileChanged"
                        ref="inputFile"
                        type="file">
                      <div class="p-img-placeholder">
                        <div v-html="plusIcon(5, 5, 'text-black')" class="icon center-block"></div>
                      </div>
                      <img
                        class="items-center user-image" 
                        :src="userImage"
                        alt="">
                    </div>
                  </div>

                  <div class="pb-6 inl-100 pl-6 p-user-form">
                    <form action="">

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.iin') }}</label>
                        <div class="flex">
                          <input  
                            v-model="user.iin"
                            name="iin"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.iinHelp')"
                          />
                        </div>
                      </div>
                      
                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.fio') }}</label>
                        <div class="flex">
                          <input
                            v-model="user.fio"
                            name="fio"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.fioHelp')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.phone') }}</label>
                        <div class="flex">
                          <input
                            v-model="user.phone"
                            name="phone"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.phoneHelp')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.email') }}</label>
                        <div class="flex">
                          <input
                            v-model="user.email"
                            name="email"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.emailHelp')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.gender') }}</label>
                        <div class="flex">
                          <select
                            v-model="user.gender"
                            :placeholder="t('form.genderHelp')"
                            class="rounded bg-gray-50 border pr-5 bg-white border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5"
                          >
                            <option selected disabled :value="null">{{ t('form.genderHelp') }}</option>
                            <option  
                              v-for="(val, key1) in genderTypes" :key="key1" 
                              :value="key1">{{ val }}</option>
                          </select>
                        </div>
                      </div>

                      <div class="margin-t-30">
                        <button
                          @click.prevent="saveData"
                          class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">
                          {{ t('actions.save') }}
                        </button>
                      </div>

                    </form>

                  </div>
                </div>

              </div>

              <div class="margin-t-30 w-full md:w-3/5 pt-2 pb-4 pr-4 pl-4 bg-white lg:ml-4 shadow-md">
                <div class="text-2xl font-semibold margin-bt-30">{{ t('navigation.accesses') }}</div>

                <div class="flex">
                  <div class="pb-6 inl-100 pl-6 p-user-form-w">
                    <form action="">

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.password') }}</label>
                        <div class="flex">
                          <input
                            v-model="password1"
                            name="password1"
                            type="password"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.passwordHelp')"
                          />
                        </div>
                      </div>

                      <div class="margin-bt-10">
                        <label class="font-semibold text-gray-700 block pb-1">{{ t('form.submitPassword') }}</label>
                        <div class="flex">
                          <input
                            v-model="password2"
                            name="password2"
                            type="password"
                            class="mb-2 mt-1 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                            :placeholder="t('form.passwordHelp')"
                          />
                        </div>
                      </div>

                      <div class="margin-t-30">
                        <button
                          @click.prevent="udpatePassword"
                          class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">
                          {{ t('actions.save') }}
                        </button>
                      </div>

                    </form>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>      
    </div>

    <slot name="rightModal"></slot>

  </div>
</template>

<script>
import { API_FACTOR, HEADERS_TOKEN_JSON, HEADERS_TOKEN_JSON_FILE, } from '@/const/uri';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { formShowError } from '@/utils/erros';
import { plusIcon } from '@/Icons/base.js';
import { MEDIA_HOST } from '@/const/uri.dev';

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  inject: [
    'toggleSmModal',
    'openErrorModal',
  ],
  data() {
    return {
      genderTypes: [],
      selectedImage: null,
      password1: null,
      password2: null,
      user: {
        fio: null, 
        phone: null,
        iin: null, 
        email: null,
        gender: null, 
        image: null,
      },
    };
  },
  computed: {
    userImage() {
      if(this.selectedImage) return this.selectedImage;
      if(!this.user.image) return '/assets/images/icons/download.png'; 
      return MEDIA_HOST + this.user.image;
    },
  },
  mounted() {
    this.lodData();
  }, 
  methods: {
    openPhoto() {
      this.$refs.inputFile.click();
    },
    onFileChanged(e) {
      const input = e.target;
      var ext = input.files[0]['name'].substring(input.files[0]['name'].lastIndexOf('.') + 1).toLowerCase();
      if (input.files && input.files[0] && (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg")) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.user.image = input.files[0];
          this.selectedImage = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    },
    plusIcon,
    async saveData() {
      await axios({
        url: API_FACTOR.user,
        method: "POST",
        data: this.user,
        headers: HEADERS_TOKEN_JSON_FILE(),
      }).then(() => {     
        this.toggleSmModal({
          description: this.t('modalMessages.userProfileUpdate'),
          isAlert: false,
        });
      }).catch((e) => {
        this.isUpdate = false;
        formShowError.bind(this)(e);
      });
    },
    async udpatePassword() {
      await axios({
        url: API_FACTOR.user + 'password/',
        method: "POST",
        data: {
          password1: this.password1,
          password2: this.password2,
        },
        headers: HEADERS_TOKEN_JSON_FILE(),
      }).then(() => {     
        this.toggleSmModal({
          description: this.t('modalMessages.userPasswordUpdated'),
          isAlert: false,
        });
      }).catch((e) => {
        this.isUpdate = false;
        formShowError.bind(this)(e);
      });
    },
    async lodData() {
      await axios({
        url: API_FACTOR.user,
        method: "GET",
        headers: HEADERS_TOKEN_JSON(),
      }).then((e) => {     
        this.user = e.data.data.user;
        this.user.gender = this.user.gender.key;
        this.genderTypes = e.data.data.genders;
      }).catch((e) => {
        this.isUpdate = false;
        formShowError.bind(this)(e);
      });
    },

  },
};
</script>